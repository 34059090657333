<div id="tc-packets-view" class="main-container container-fluid with-top-navbar">
	<div class="row reduced-gutters">
		<div class="col-6 col-lg-7 col-xl-7">
			<div class="row reduced-gutters">
				<div class="col-lg-4 col-xl-4" id="sidebar-tm-packets">
					<app-tc-packets-sidebar></app-tc-packets-sidebar>
				</div>
				<div class="col-lg-8 col-xl-8">
					<div id="send-tc" class="card card-grey ">
						<div class="card-header card-grey" id="help-send-tc" data-container="body" data-html="true" data-placement="bottom"
						 data-trigger="manual" data-title="Envoi de télécommande" data-content="Rechercher une télécommande par son <b>nom</b>, sa <b>description</b> ou avec le <b>nom d'un argument</b>">
							<label class="hide-fixed-args custom-control custom-checkbox custom-label">
								<input class="form-control custom-control-input" type="checkbox" [checked]="hideFixedArgument" (change)="hideFixedArgument = !hideFixedArgument">
								Cacher les arguments fixés
								<span class="custom-control-indicator"></span>
							</label>
							<h6>
								<strong>Envoi de télécommande</strong>
							</h6>
						</div>
						<div class="card-block scroll-y scrollbar-style">

							<div class="input-group searchbarTc" id="searchbarTc">
								<input type="text" class="form-control" data-toggle="dropdown" [(ngModel)]="packetSearch" (ngModelChange)="onChangeSearchPacket()"
								 data-target=".show-dropdown-tc" placeholder="Rechercher une télécommande..." />
								<div class="input-group-btn">
									<div class="btn-group" role="group">
										<div class="dropdown dropdown-lg" id="dropdown-tc-list">
											<button id="searchTCButton" class="btn btn-default rounded-0 add-on-button" type="button" (click)="clearSearch()">
												<span [hidden]="!edited" class="icon icon-circle-with-cross noir" aria-hidden="true"></span>
												<span [hidden]="edited" class="icon icon-magnifying-glass noir" aria-hidden="true"></span>
											</button>
											<span class="show-dropdown-tc">

												<button id="dropdownTCButton" type="button" class="btn btn-default dropdown-toggle add-on-button"
												 data-toggle="dropdown" aria-expanded="false">
													<span class="caret"></span>
												</button>

												<div class="dropdown-menu dropdown-menu-right scrollable-menu scroll-y scrollbar-style" id="dropdown-list-tc" role="menu">
													<ng-container *ngFor="let argumentView of displayList">
														<button class="dropdown-item" (click)="setTC(argumentView)">
															{{argumentView.packetName}}<br /> <span class="description-tc">
																{{argumentView.packetDescription}}</span>
														</button>
														<div class="dropdown-divider"></div>
													</ng-container>
												</div>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="this.popoverTourService.SequenceIndex == 200" class="popover bs-tether-element-attached-top help-tour-popover popover-tc-search" role="tooltip">
								<h3 class="popover-title">
									Envoyer une TC
									<button (click)="this.popoverTourService.quitSequence()" type="button" class="close popover-close" aria-label="Close">
										<span aria-hidden="true" >&times;</span>
									</button>
								</h3>
								<div class="popover-content">
									Entrer le nom d'une TC à envoyer, par exemple "TC_17_1".
								</div>
								<div class="popover-footer">
									<button (click)="this.popoverTourService.quitSequence()" type="button" class="btn btn-default add-on-button mr-4">Quitter</button>
									<button	(click)="this.popoverTourService.nextSequence()" type="button" class="btn btn-primary">Suivant</button>
								</div>
							</div>
							<form *ngIf="form" class="form-horizontal form-sendTc" (ngSubmit)="onSubmit()" [formGroup]="form">
								<app-argumentview-detail [hideFixedArgument]="hideFixedArgument" [argumentView]="selectArgumentView"
								 [tcArguments]="packetsTCList" [form]="form"></app-argumentview-detail>
								<div class="form-group" *ngIf="selectArgumentView">
									<div class="mt-5  text-center">
										<button type="submit" class="btn btn-lg btn-pill btn-outline-primary" id="buttonSendTC" [disabled]="!form.valid">Envoyer</button>

									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-6 col-lg-5 col-xl-5">
			<app-tc-history [tcpackets]="tcpackets" [tcArguments]="packetsTCList"></app-tc-history>
		</div>
	</div>
</div>