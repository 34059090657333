import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PopoverTourService } from "app/popover-tour.service";
import { Subscription } from "rxjs";
import {
  AbstractContainer,
  formatDate,
  SpacePacket,
  TmPacket,
  updatePacketStatus,
  properWayForIntervalSubscription,
  Criticality,
} from "../model/spacepacket";
import { TmPacketsConfiguration } from "../model/tm-packets-configuration";
import { TmPacketListService } from "../tm-packet-list.service";
import { TmPacketsService } from "../tm-packets.service";
declare let $: any;

@Component({
  selector: "app-tm-packets",
  templateUrl: "./tm-packets.component.html",
  styleUrls: ["./tm-packets.component.css"],
})
export class TmPacketsComponent implements OnInit, OnDestroy {
  tmpackets: TmPacket[] = new Array<TmPacket>();
  tmPacketsConfiguration: TmPacketsConfiguration;
  selectedPacket: TmPacket;
  selectedContainer: AbstractContainer;
  initTableSort = false;
  nbPacketsNotSeen = 0;

  nbPacketsNominal = 0;
  nbPacketsWatch = 0;
  nbPacketsWarning = 0;
  nbPacketsDistress = 0;
  nbPacketsCritical = 0;
  nbPacketsSevere = 0;

  maxLimitDownload: number;
  orderByDownload: string;
  format: string;
  namePacketToDownload: string;

  displayNominal = false;
  displayWatch = false;
  displayWarning = false;
  displayDistress = false;
  displayCritical = false;
  displaySevere = false;

  showDateChoice = true;
  showNbPacketChoice = true;

  tmList: SpacePacket[];
  packetsList: SpacePacket[];

  subscription: Subscription;
  previousCallRunning = false;

  popoverTourService: PopoverTourService = PopoverTourService.Instance;

  private exportPacketsUrl = "api/download-tm-packets";

  constructor(
    private router: Router,
    private tmPacketsService: TmPacketsService,
    private http: HttpClient,
    private tmPacketListService: TmPacketListService
  ) {
    this.tmPacketsConfiguration = this.tmPacketsService.tmPacketsConfiguration;
    this.tmPacketsConfiguration.orderBy = "onBordTime";

    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.exportPacketsUrl = "http://localhost:15000/" + this.exportPacketsUrl;
    }
  }

  ngOnInit() {
    this.popoverTourService.sequenceIndex = 100;

    this.subscription = properWayForIntervalSubscription(
      800,
      "getTmPackets",
      this
    );

    this.getPacketList();
    //this.initScrollBar();

    /* Init the param of the download */
    this.format = "csv";
    this.maxLimitDownload = 10;
    this.orderByDownload = "onBordTime";

    this.nbPacketsNominal = 0;
    this.nbPacketsWatch = 0;
    this.nbPacketsWarning = 0;
    this.nbPacketsDistress = 0;
    this.nbPacketsCritical = 0;
    this.nbPacketsSevere = 0;

    $("body").on("click", "#rawPacketCopie-button", function (e) {
      e.preventDefault();
      return false;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getPacketList() {
    this.tmPacketListService
      .getTmPacketList()
      .subscribe((tmList) => this.initPacketlist(tmList));
  }

  initPacketlist(list: SpacePacket[]) {
    this.tmList = list;
    this.packetsList = this.alphabeticalSort(list);
  }

  getUrlForPacketExport(): void {
    let filterDateURL = "";

    if (this.tmPacketsConfiguration.realTime) {
      filterDateURL = "?start=realTime";
    } else {
      filterDateURL = formatDate(
        this.tmPacketsConfiguration.dateStart,
        this.tmPacketsConfiguration.timeStart,
        this.tmPacketsConfiguration.dateEnd,
        this.tmPacketsConfiguration.timeEnd
      );
    }

    const filterURL = "&filter=" + this.namePacketToDownload;
    const nbPacketsURL = "&maxLimit=" + this.maxLimitDownload;
    const orderByURL = "&orderBy=" + this.orderByDownload;
    const formatURL = "&format=" + this.format;

    $("#downloadTm").modal("hide");
    window.open(
      this.exportPacketsUrl +
        filterDateURL +
        nbPacketsURL +
        orderByURL +
        filterURL +
        formatURL
    );
  }

  getMessageNbPacket(): string {
    if (this.maxLimitDownload == -1 || this.maxLimitDownload == null) {
      return "Les paquets seront récupérés à partir de la base de données.";
    } else {
      return (
        "Les " +
        this.maxLimitDownload +
        " paquets les plus récents seront récupérés à partir de la base de données."
      );
    }
  }

  returnCriticalityClass(status: Criticality): string {
    let statusToReturn;
    switch (status) {
      case Criticality.NOMINAL:
        statusToReturn = "nominal-grey";
        break;
      case Criticality.WATCH:
        statusToReturn = "watch";
        break;
      case Criticality.WARNING:
        statusToReturn = "warning";
        break;
      case Criticality.DISTRESS:
        statusToReturn = "distress";
        break;
      case Criticality.CRITICAL:
        statusToReturn = "critical";
        break;
      case Criticality.SEVERE:
        statusToReturn = "severe";
        break;
      default:
        statusToReturn = "";
    }
    return statusToReturn;
  }

  onSelect(packet: TmPacket): void {
    this.selectedPacket = packet;
    this.selectedContainer = packet.spacePacket.rootContainer;
  }

  getTmPackets(): void {
    this.previousCallRunning = true;
    this.tmPacketsService
      .getTmPackets()
      .subscribe((tmpackets) => this.setTmPackets(tmpackets));
  }

  setTmPackets(tmpackets: TmPacket[]) {
    this.merge(tmpackets);

    this.initTableSort = true;
    this.previousCallRunning = false;
  }

  selectedContainerChange(selectedContainer: AbstractContainer) {
    this.selectedContainer = selectedContainer;
  }

  //Ajoute à la liste de paquets les nouveaux paquets reçus
  merge(newTmpackets: TmPacket[]) {
    let size: number = this.tmpackets.length;
    newTmpackets.forEach((newTmPacket, i) => {
      if (i < size) {
        const oldTmPacket: TmPacket = this.tmpackets[i];
        if (newTmPacket.id != oldTmPacket.id) {
          updatePacketStatus(newTmPacket);
          this.tmpackets.splice(i, 0, newTmPacket);
          size++;
        }
      } else {
        updatePacketStatus(newTmPacket);
        this.tmpackets.splice(i, 0, newTmPacket);
        size++;
      }
    });
    // Cette ligne permet de supprimer les valeurs qui sont toujours là alors que le back-end ne renvoi plus rien
    this.tmpackets.splice(newTmpackets.length, size - newTmpackets.length);
    this.updateStatusCount();
  }

  //Met a jour les compteurs de statut dans le header de la liste de paquets
  updateStatusCount() {
    this.nbPacketsNominal = 0;
    this.nbPacketsWatch = 0;
    this.nbPacketsWarning = 0;
    this.nbPacketsDistress = 0;
    this.nbPacketsCritical = 0;
    this.nbPacketsSevere = 0;
    for (const tm of this.tmpackets) {
      this.statusCounter(tm.status);
    }
    $('[data-toggle="tooltip"]').tooltip();
    this.hideTooltipStatusButton();
  }

  //Incrémente le bon compteur en fonction du statut en entrée
  statusCounter(status: Criticality) {
    switch (status) {
      case Criticality.NOMINAL:
        this.nbPacketsNominal++;
        break;
      case Criticality.WATCH:
        this.nbPacketsWatch++;
        break;
      case Criticality.WARNING:
        this.nbPacketsWarning++;
        break;
      case Criticality.DISTRESS:
        this.nbPacketsDistress++;
        break;
      case Criticality.CRITICAL:
        this.nbPacketsCritical++;
        break;
      case Criticality.SEVERE:
        this.nbPacketsSevere++;
        break;
      default:
    }
  }

  //Cache la tooltip d'un bouton qui n'est pas affiché
  hideTooltipStatusButton() {
    if (this.nbPacketsNominal == 0 && !this.displayNominal) {
      $("#tooltip-nominal").tooltip("hide");
    }
    if (this.nbPacketsWatch == 0 && !this.displayWatch) {
      $("#tooltip-watch").tooltip("hide");
    }
    if (this.nbPacketsWarning == 0 && !this.displayWarning) {
      $("#tooltip-warning").tooltip("hide");
    }
    if (this.nbPacketsDistress == 0 && !this.displayDistress) {
      $("#tooltip-distress").tooltip("hide");
    }
    if (this.nbPacketsCritical == 0 && !this.displayCritical) {
      $("#tooltip-critical").tooltip("hide");
    }
    if (this.nbPacketsSevere == 0 && !this.displaySevere) {
      $("#tooltip-severe").tooltip("hide");
    }
  }

  toggleNominalStatus() {
    this.displayNominal = !this.displayNominal;
    this.hideTooltipStatusButton();
  }

  toggleWatchStatus() {
    this.displayWatch = !this.displayWatch;
    this.hideTooltipStatusButton();
  }

  toggleWarningStatus() {
    this.displayWarning = !this.displayWarning;
    this.hideTooltipStatusButton();
  }

  toggleDistressStatus() {
    this.displayDistress = !this.displayDistress;
    this.hideTooltipStatusButton();
  }

  toggleCriticalStatus() {
    this.displayCritical = !this.displayCritical;
    this.hideTooltipStatusButton();
  }

  toggleSevereStatus() {
    this.displaySevere = !this.displaySevere;
    this.hideTooltipStatusButton();
  }

  //Formate la date au format dd/mm/aaaa hh:mm:ss.nnn (Format en entré aaaa-mm-ddThh:mm:ss.nnnZ ou aaaa-mm-ddThh:mmZ si nano = 000)
  formatDate(date: string): string {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    let time = date.slice(11, 23).replace("Z", "");
    if (time.length == 8) {
      time += ".000";
    }
    return day + "/" + month + "/" + year + " " + time;
  }

  //Permet de modifier le trie de la liste de paquet (Date bord/Date de réception)
  changeSorting(newValue: string) {
    if (this.tmPacketsConfiguration.orderBy !== newValue) {
      this.tmPacketsConfiguration.orderBy = newValue;
    }
  }

  //Permet de mettre dans le presse-papier (clipboard) le code hexadecimal du paquet
  onCopieHexPacket(raw: string) {
    //        event.preventDefault();
    //        event.stopPropagation();
    document.execCommand("copy");
    const $temp = $("<input>");
    $("body").append($temp);
    $temp.val(raw).select();
    document.execCommand("copy");
    $temp.remove();
  }

  alphabeticalSort(list: SpacePacket[]): SpacePacket[] {
    return list.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  getLengthPacket(raw: string): number {
    return raw.length / 2;
  }

  packetStatusIsNominal(packet: TmPacket): boolean {
    return packet.status == Criticality.NOMINAL;
  }

  packetStatusIsWatch(packet: TmPacket): boolean {
    return packet.status == Criticality.WATCH;
  }

  packetStatusIsWarning(packet: TmPacket): boolean {
    return packet.status == Criticality.WARNING;
  }

  packetStatusIsDistress(packet: TmPacket): boolean {
    return packet.status == Criticality.DISTRESS;
  }

  packetStatusIsCritical(packet: TmPacket): boolean {
    return packet.status == Criticality.CRITICAL;
  }

  packetStatusIsSevere(packet: TmPacket): boolean {
    return packet.status == Criticality.SEVERE;
  }
}
