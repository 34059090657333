import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { formatDate, TcPacket } from "./model/spacepacket";
import { TcPacketsConfiguration } from "./model/tc-packets-configuration";
declare let $: any;

@Injectable()
export class TcPacketsService {
  private tcPacketsUrl = "api/tc-packets";

  tcPacketsConfiguration: TcPacketsConfiguration = new TcPacketsConfiguration();

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.tcPacketsUrl = "http://localhost:15000/" + this.tcPacketsUrl;
    }
    this.handleError = httpErrorHandler.createHandleError("TcPacketsService");
  }

  getTcPackets(): Observable<Array<TcPacket>> {
    let filterDateURL = "";
    if (this.tcPacketsConfiguration.realTime) {
      filterDateURL = "?start=realTime";
    } else {
      filterDateURL = formatDate(
        this.tcPacketsConfiguration.dateStart,
        this.tcPacketsConfiguration.timeStart,
        this.tcPacketsConfiguration.dateEnd,
        this.tcPacketsConfiguration.timeEnd
      );
    }

    let filterPacketsURL = "";
    const nbPacketsURL =
      "&maxLimit=" + this.tcPacketsConfiguration.nbPaquetsMax;
    this.tcPacketsConfiguration.selectList.forEach(
      (value: boolean, key: string) => {
        if (value) {
          filterPacketsURL += "&filter=" + key;
        }
      }
    );
    return this.http
      .get<Array<TcPacket>>(
        this.tcPacketsUrl + filterDateURL + filterPacketsURL + nbPacketsURL
      )
      .pipe(catchError(this.handleError("getTcPackets", [])));
  }
}
