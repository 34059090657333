<div id="paquet-sidebar" class="scrollbar-style" data-container="body" data-html="true" data-placement="right"
 data-trigger="manual" data-title="Menu latÃƒÂ©ral" data-content="PossibilitÃƒÂ© de filtrer la <b>Liste des paquets</b> par :
					<br><span class='icon icon-dot-single'>Date
					<br><span class='icon icon-dot-single'>Type de paquet
					<br><span class='icon icon-dot-single'>Nombre de paquets
					">
	<div id="menu-date" class="card">
		<div class="card-header menu-drop-down" id="headingDate">
			<p class="mb-0">
				<a class="accordion-toggle" data-toggle="collapse" href="#collapseDate" aria-expanded="false" aria-controls="collapseDate">
					Filtrer par date </a>
			</p>
		</div>
		<div class="card-header header-real-time" id="headingRealTime">

			<p class="mb-0">
				<a class="accordion-toggle text-toogle"> <span *ngIf=this.synopticConfiguration.realTime> <label><span>Temps
								réel activé</span> </label>
					</span> <span *ngIf=!this.synopticConfiguration.realTime> <label><span>Temps
								réel désactivé</span> </label></span>
				</a> <a> <label class="control-toggle" id="checkbox-temps-reelSynoptic"><input type="checkbox" checked="checked"
						 [(ngModel)]="this.synopticConfiguration.realTime" (ngModelChange)="onChangeRealTime()">
						<div class="toggle-button"></div>
					</label>
				</a>
			</p>
		</div>
		<div id="collapseRealTimeSynoptic" class="collapse">
			<div class="card-block block-real-time input-daterange">
				<div class="input-group input-date">
					<span class="input-group-addon"> Du </span> <input id="dateStartFilter" type="text" name="Date-du"
					 data-date-format="dd/mm/yyyy" class="form-control date-picker" #datePickerStart data-date-today-highlight="true"
					 [(ngModel)]="synopticConfiguration.dateStart" data-provide="datepicker" (blur)="synopticConfiguration.dateStart = datePickerStart.value;onChange()">
				</div>
				<div class="input-group input-date">
					<span class="input-group-addon"> Au </span> <input id="dateEndFilter" type="text" name="Date-au" data-date-format="dd/mm/yyyy"
					 class="form-control date-picker" data-date-today-highlight="true" #datePickerEnd [(ngModel)]="synopticConfiguration.dateEnd"
					 data-provide="datepicker" (blur)="synopticConfiguration.dateEnd = datePickerEnd.value;onChange()">
				</div>
			</div>
			<div id="timeFilterGroup">
				<div class="input-group input-date">
					<input id="timeStartFilter" class="form-control time-picker" type="time" name="Heure-au" step="1" value="00:00:00"
					 [(ngModel)]="synopticConfiguration.timeStart" (ngModelChange)="onChange()">
				</div>
				<div class="input-group input-date">
					<input id="timeEndFilter" class="form-control time-picker" type="time" name="Heure-du" step="1" value="00:00:00"
					 [(ngModel)]="synopticConfiguration.timeEnd" (ngModelChange)="onChange()">
				</div>
			</div>
			<div class="clearfix"></div>
		</div>
		<div *ngIf="this.popoverTourService.SequenceIndex == 303" style="top:100%" class="popover bs-tether-element-attached-top help-tour-popover" role="tooltip">
			<h3 class="popover-title">
				Filtrer par date
				<button (click)="this.popoverTourService.quitSequence()" type="button" class="close popover-close" aria-label="Close">
					<span aria-hidden="true" >&times;</span>
				</button>
			</h3>
			<div class="popover-content">
				<strong>Désactiver le temps réel</strong> pour filtrer par date.<br />
				Le filtre s'applique sur <strong>la date bord ou la date de réception.</strong>
			</div>
			<div class="popover-footer">
				<button (click)="this.popoverTourService.previousSequence()" type="button" class="btn btn-default add-on-button mr-4">Précédent</button>
				<button	(click)="this.popoverTourService.nextSequence()" type="button" class="btn btn-primary">Suivant</button>
			</div>
		</div>

	</div>

	<div id="menu-temps" class="card">
		<div class="card-header menu-drop-down" id="headingTemps">
			<p class="mb-0">
				<a class="accordion-toggle collapseTemps" data-toggle="collapse" href="#collapseTemps" aria-expanded="false"
				 aria-controls="collapseVue"> Temps référence </a>
			</p>
		</div>
		<div id="collapseTemps" class="collapse show">
			<div class="card-block block-menu-drop-down" id="synoptic-list">
				<div class="radio">
					<label> <input type="radio" name="optionsTemps" id="onBoardTime" value="onBoardTime" [(ngModel)]="timeReference"
						 (ngModelChange)="changeReferenceTime($event)"> Date bord
					</label>
				</div>
				<div class="radio">
					<label> <input type="radio" name="optionsTemps" id="onReceptionTime" value="onReceptionTime" [(ngModel)]="timeReference"
						 (ngModelChange)="changeReferenceTime($event)"> Date de
						réception
					</label>
				</div>
			</div>
		</div>
	</div>

	<div id="menu-vue" class="card">
		<div class="card-header menu-drop-down" id="headingVue">
			<p class="mb-0">
				<a class="accordion-toggle collapseVue" data-toggle="collapse" href="#collapseVue" aria-expanded="false"
				 aria-controls="collapseVue"> Liste des synoptiques </a>
			</p>
		</div>
		<div id="collapseVue" class="collapse show">
			<div class="card-block block-menu-drop-down" id="synoptic-list">
				<ul class="list-group">
					<li *ngFor="let synoptic of synopticList" class="list-group-item pointer-hover"><span class="icon icon-controller-record"></span><a
						 class="list" (click)="onSelect(synoptic)">{{synoptic.title}}</a><a class="delete" data-toggle="modal"
						 data-target="#delete-synoptic"><span class="icon icon-cross hidden" (click)="chooseToDelete(synoptic)"></span></a></li>
					<li class="list-group-item pointer-hover">
						<span class="icon icon-plus"></span>
						<a data-toggle="modal" data-target="#creer-synoptique">Créer Synoptique</a>
					</li>
				</ul>
			</div>
		</div>

		<div *ngIf="this.popoverTourService.SequenceIndex == 300" style="top: -80%" class="popover bs-tether-element-attached-bottom help-tour-popover" role="tooltip">
			<h3 class="popover-title">
			  Liste des synoptiques
			  <button (click)="this.popoverTourService.quitSequence()" type="button" class="close popover-close" aria-label="Close">
			  <span aria-hidden="true" >&times;</span>
			  </button>
			</h3>
			<div class="popover-content">
			  Cliquer sur "Créer Synoptique" afin d'ajouter une vue synoptique.
			</div>
			<div class="popover-footer">
				<button (click)="this.popoverTourService.quitSequence()" type="button" class="btn btn-default add-on-button mr-4">Quitter</button>
			  	<button	(click)="this.popoverTourService.nextSequence()" type="button" class="btn btn-primary">Suivant</button>
			</div>
		  </div>

		<!-- 		Modal for synoptic craetion -->
		<div class="modal fade" id="creer-synoptique" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-sm">
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="semi-transparent modal-title">Créer un Synoptique</h6>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<app-create-synoptic (synopticToSend)="addSynoptic($event)"></app-create-synoptic>
				</div>
			</div>
		</div>

		<!-- 		Modal for synoptic delete confirmation -->
		<div class="modal fade" id="delete-synoptic" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-sm">
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="semi-transparent modal-title">Supprimer un
							Synoptique</h6>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<form (ngSubmit)="deleteSynoptic()">
						<!-- [formGroup]="form"  -->
						<div class="modal-body">
							<p>Vous confirmez la suppression du synoptique suivant :</p>
							<p>Titre : "{{toDelete.title}}"</p>
							<p>Description : "{{toDelete.description}}"</p>
							<hr class="mb-0">
						</div>
						<div class="modal-actions">
							<button type="button" class="btn btn-sm btn-pill btn-danger" data-dismiss="modal">Annuler</button>
							<button type="submit" class="btn btn-sm btn-pill btn-success" id="deleteSynoptic">
								<strong>Confirmer</strong>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

	</div>
</div>
