<div class="main-container container with-top-navbar" id="home">
  <img
    src="assets/img/LogoSCCColor.png"
    class="mx-auto d-block"
    width="150"
    height="150"
    alt="Responsive image"
    id="home-logo-scc"
  />
  <span id="home-title">
    <h1 class="text-center mt-5 display-3 Montserrat">Simple Control Center</h1>
    <h2 class="text-center">Monitor and control your satellite</h2>
  </span>

  <div class="row mt-3" id="home-menu">
    <div class="col-md-3 col-sm-6 mt-4">
      <div class="list-group">
        <a
          routerLink="/tm-packets"
          class="list-group-header list-group-item-action text-center"
          ><div class="">
            <img
              height="128"
              class="mx-auto d-block"
              src="assets/img/home/telemesureBlanc.png"
            />
            <p></p>
          </div>
          <h4 class="title-card">Télémesure</h4></a
        >
        <a
          routerLink="/tm-packets"
          class="list-group-item list-group-item-action"
          >Explorateur de paquets</a
        >
        <a href="#" class="list-group-item list-group-item-action"
          >Vue personnalisée</a
        >
      </div>
    </div>
    <div class="col-md-3 col-sm-6 mt-4">
      <div class="list-group">
        <a
          routerLink="/send-tc"
          class="list-group-header list-group-item-action text-center"
          ><div class="">
            <img
              height="128"
              class="mx-auto d-block"
              src="assets/img/home/telecommandeBlanc.png"
            />
            <p></p>
          </div>
          <h4 class="title-card">Télécommande</h4></a
        >
        <a routerLink="/send-tc" class="list-group-item list-group-item-action"
          >Envoyer une télécommande</a
        >
        <a href="#" class="list-group-item list-group-item-action"
          >Vérifier une exécution</a
        >
      </div>
    </div>
    <div class="col-md-3 col-sm-6 mt-4">
      <div class="list-group">
        <a
          href="/#"
          class="list-group-header list-group-item-action text-center"
          ><div class="">
            <img
              height="128"
              class="mx-auto d-block"
              src="assets/img/home/procedureBlanc.png"
            />
            <p></p>
          </div>
          <h4 class="title-card">Procédure<br />(Dév.)</h4></a
        >
        <a class="list-group-item list-group-item-action">Créer un plan</a>
        <a href="#" class="list-group-item list-group-item-action"
          >Editer un plan</a
        >
      </div>
    </div>
    <div class="col-md-3 col-sm-6 mt-4">
      <div class="list-group">
        <a
          href="#"
          class="list-group-header list-group-item-action text-center disabled"
          ><div class="">
            <img
              height="128"
              class="mx-auto d-block"
              src="assets/img/home/journalEvenementsBlanc.png"
            />
            <p></p>
          </div>
          <h4 class="title-card">Journal<br />(Non disponible)</h4></a
        >
        <a href="#" class="list-group-item list-group-item-action"
          >Voir le journal des évènements</a
        >
      </div>
    </div>
  </div>
  <template>
    <div id="help-tour-popover-template" class="popover" role="tooltip">
      <div class="arrow"></div>
      <h3 class="popover-title"></h3>
      <div class="popover-content"></div>
      <div class="popover-footer">
        <button type="button" class="btn btn-default add-on-button mr-4">
          Précédent
        </button>
        <button type="button" class="btn btn-primary" id="btn-next-help-tour">
          Suivant
        </button>
      </div>
    </div>
  </template>
</div>
