// ------------------ The model

export class TmPacketsConfiguration {
  selectList: Map<string, boolean> = new Map<string, boolean>();
  dateStart: string;
  timeStart: string;
  dateEnd: string;
  timeEnd: string;
  realTime: boolean;
  nbPaquetsMax: number;
  orderBy: string;
}
