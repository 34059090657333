// ------------------ Static functions

/*
 * This function can be used to find type of subcontainers.
 * This type can be either a Parameter or a PacketContainer.
 *
 */
import { interval, Subscription } from "rxjs";

/**
 *
 * @param abstractContainer
 */
export function isParameter(abstractContainer: AbstractContainer): boolean {
  if (abstractContainer) {
    return (<Parameter>abstractContainer).fixedValue !== undefined;
  }
  return false;
}

/**
 *
 * @param parameter
 */
export function iNumericValueParameter(parameter: Parameter): boolean {
  if (parameter) {
    const valueEncoding: boolean =
      parameter.ptc == 3 || parameter.ptc == 4 || parameter.ptc == 5;
    const isEnumRes = isEnum(parameter.calibration);
    return valueEncoding && !isEnumRes;
  }
  return false;
}

/**
 *
 * @param abstractContainer
 */
export function isPacketContainer(
  abstractContainer: AbstractContainer
): boolean {
  if (abstractContainer) {
    return (<PacketContainer>abstractContainer).subContainers !== undefined;
  }
  return false;
}

/**
 *
 * @param abstractContainer
 */
export function isPacketParameter(
  abstractContainer: AbstractContainer
): boolean {
  return (<PacketParameter>abstractContainer).spacePacket !== undefined;
}

/**
 *
 * @param calibration
 */
export function isEnum(calibration: Calibration): boolean {
  if (calibration) {
    return (<TextualCalibration>calibration).enumerationList !== undefined;
  }
  return false;
}

/**
 *
 * @param calibration
 */
export function isPolynomialCalibration(calibration: Calibration): boolean {
  if (calibration) {
    return (<PolynomialCalibration>calibration).coefficients !== undefined;
  }
  return false;
}

/**
 *
 * @param monitoring
 */
export function isNumericMonitoring(monitoring: Monitoring): boolean {
  if (monitoring) {
    return (
      (<NumericMonitoring>monitoring).severeRange != undefined ||
      (<NumericMonitoring>monitoring).criticalRange != undefined ||
      (<NumericMonitoring>monitoring).distressRange != undefined ||
      (<NumericMonitoring>monitoring).warningRange != undefined ||
      (<NumericMonitoring>monitoring).watchRange != undefined
    );
  }
  return false;
}

/**
 *
 * @param monitoring
 */
export function isEnumMonitoring(monitoring: Monitoring): boolean {
  if (monitoring) {
    return (
      (<EnumMonitoring>monitoring).severeCondition != undefined ||
      (<EnumMonitoring>monitoring).criticalCondition != undefined ||
      (<EnumMonitoring>monitoring).distressCondition != undefined ||
      (<EnumMonitoring>monitoring).warningCondition != undefined ||
      (<EnumMonitoring>monitoring).watchCondition != undefined
    );
  }
  return false;
}

/**
 *
 * @param dateStart
 * @param timeStart
 * @param dateEnd
 * @param timeEnd
 */
export function formatDate(
  dateStart: string,
  timeStart: string,
  dateEnd: string,
  timeEnd: string
): string {
  const yearStart = dateStart.slice(6, 10);
  const monthStart = dateStart.slice(3, 5);
  const dayStart = dateStart.slice(0, 2);
  const yearEnd = dateEnd.slice(6, 10);
  const monthEnd = dateEnd.slice(3, 5);
  const dayEnd = dateEnd.slice(0, 2);
  const onReturn =
    "?start=" +
    yearStart +
    "-" +
    monthStart +
    "-" +
    dayStart +
    "T" +
    addZerosDigitstoTime(timeStart) +
    ".999999999Z&end=" +
    yearEnd +
    "-" +
    monthEnd +
    "-" +
    dayEnd +
    "T" +
    addZerosDigitstoTime(timeEnd) +
    ".999999999Z";
  //        var onReturn = "?start=" + yearStart + "-" + monthStart + "-" + dayStart + "T" + this.addZerosDigitstoTime( timeStart ) + "Z&end=" + yearEnd + "-" + monthEnd + "-" + dayEnd + "T" + this.addZerosDigitstoTime( timeEnd ) + "Z";
  return onReturn;
}

/**
 *
 * @param arg
 */
export function addZeros9Digit(arg: string): string {
  let toReturn: string;
  toReturn = arg + "";
  while (toReturn.length < 9) {
    toReturn = "0" + toReturn;
  }
  return toReturn;
}

/**
 *
 * @param arg
 * @param pfc
 */
export function addZerosDigitToHex(arg: string, pfc: number): string {
  let toReturn: string;
  toReturn = arg + "";
  while (toReturn.length < pfc * 2) {
    toReturn = "0" + toReturn;
  }
  return toReturn;
}

/**
 *
 * @param arg
 */
export function addZerosDigitstoTime(arg: string): string {
  let toReturn: string;
  toReturn = arg + "";

  if (toReturn.length < 2) {
    toReturn = toReturn + "00";
  }
  while (toReturn.length < 8) {
    toReturn = toReturn + ":00";
  }
  return toReturn;
}

// Met a jour le statut du paquet en fonction de son contenu (met le statut le plus sévère)

/**
 *
 * @param tmPacket
 */
export function updatePacketStatus(tmPacket: TmPacket) {
  let status = Criticality.NOMINAL;
  for (const container of tmPacket.spacePacket.rootContainer.subContainers) {
    const newStatus = getStatus(container);
    if (newStatus > status) {
      status = newStatus;
    }
  }
  tmPacket.status = status;
}

//Fonction récursive qui met a jour le statut de chaque sous-paquets en fonction des paramètres qu'il contient (met le statut le plus sévère)
/**
 *
 * @param abstractContainer
 */
export function getStatus(abstractContainer: AbstractContainer): Criticality {
  let status = Criticality.NOMINAL;
  if (isParameter(abstractContainer)) {
    if ((<Parameter>abstractContainer).status == null) {
      (<Parameter>abstractContainer).status = Criticality.NOMINAL;
    }
    if ((<Parameter>abstractContainer).status > status) {
      status = (<Parameter>abstractContainer).status;
    }
  } else if (
    isPacketContainer(abstractContainer) &&
    (<PacketContainer>abstractContainer).subContainers != null
  ) {
    for (const container of (<PacketContainer>abstractContainer)
      .subContainers) {
      const newStatus = getStatus(container);
      if (newStatus > (<PacketContainer>abstractContainer).status) {
        (<PacketContainer>abstractContainer).status = newStatus;
      }
      if (newStatus > status) {
        status = (<Parameter>abstractContainer).status;
      }
      if ((<PacketContainer>abstractContainer).status == undefined) {
        (<PacketContainer>abstractContainer).status = Criticality.NOMINAL;
      }
    }
  }
  return status;
}

// Cette méthode permet de ne pas surcharger les call qui sont fait à chaque interval et d'attendre que le précédent soit finit,
// il faut l'utiliser partout où il y a un interval
// En fait si jamais il y a une requete qui met énormément de temps (par exemple un filtre TM sur une TM qui n'existe pas dans une grosse BDD,
// alors il faut que les autres requêtes attende la fin de leur execution avant de relancer des requetes avec interval())
/**
 *
 * @param intervalPeriod
 * @param toLaunch
 * @param component
 */
export function properWayForIntervalSubscription(
  intervalPeriod: number,
  toLaunch: string,
  component
): Subscription {
  return interval(intervalPeriod).subscribe((x) => {
    // On ne lance le get que si le précédent s'est terminé
    if (!component.previousCallRunning) {
      if (toLaunch == "getTmPackets") {
        component.getTmPackets();
      } else if (toLaunch == "getParameters") {
        component.getParameters();
      } else if (toLaunch == "getSynopticList") {
        component.getSynopticList();
      } else if (toLaunch == "getTcPackets") {
        component.getTcPackets();
      } else if (toLaunch == "getProcedureHistory") {
        component.getProcedures();
      }
    }
  });
}

export function shallowCopy(parameter: Parameter): Parameter;
export function shallowCopy(parameter: PacketContainer): PacketContainer;
export function shallowCopy(
  parameter: AbstractContainerImpl
): AbstractContainerImpl;
/**
 *
 * @param parameter
 */
export function shallowCopy(parameter: any): any {
  let copy;

  if ((<Parameter>parameter).pfc !== undefined) {
    copy = new Parameter();

    copy.name = parameter.name;
    copy.pfc = parameter.pfc;
    copy.ptc = parameter.ptc;
    copy.calibration = parameter.calibration;
    copy.description = parameter.description;
    copy.dynamicLengthParamRef = parameter.dynamicLengthParamRef;
    copy.dynamicRepeat = parameter.dynamicRepeat;
    copy.dynamicRepeatParameterRef = parameter.dynamicRepeatParameterRef;
    copy.fixedLength = parameter.fixedLength;
    copy.fixedRepeat = parameter.fixedRepeat;
    copy.fixedValue = parameter.fixedValue;
    if (copy.fixedValue === true) {
      copy.physicalValue = parameter.physicalValue;
      copy.rawData = parameter.rawData;
      copy.rawValue = new Value<any>();
      copy.rawValue.value = null;
      copy.rawValue.value = parameter.rawValue.value;
      copy.rawValue.valueType = parameter.rawValue.valueType;
    } else {
      copy.physicalValue = new Value<any>();
      copy.physicalValue.valueType = null;
      copy.physicalValue.valueType = parameter.physicalValue.valueType;
      copy.rawValue = new Value<any>();
      copy.rawValue.value = null;
      copy.rawValue.valueType = parameter.rawValue.valueType;
    }
    copy.length = parameter.length;
    copy.monitoring = parameter.monitoring;
    copy.offset = parameter.offset;
    copy.status = parameter.status;
    copy.unit = parameter.unit;
    copy.useCalibratedValue = parameter.useCalibratedValue;
    copy.argument = parameter.argument;
    copy.linearAdjustment = parameter.linearAdjustment;
  } else if (<PacketContainer>parameter.subContainers !== undefined) {
    copy = new PacketContainer();

    copy.name = parameter.name;
    copy.subContainers = new Array<AbstractContainer>();
    for (const index in parameter.subContainers) {
      copy.subContainers.push(shallowCopy(parameter.subContainers[index]));
    }
    copy.dynamicRepeatParameterRef = parameter.dynamicRepeatParameterRef;
    copy.dynamicRepeat = parameter.dynamicRepeat;
    copy.fixedRepeat = parameter.fixedRepeat;
    copy.useCalibratedValue = parameter.useCalibratedValue;
    copy.linearAdjustment = parameter.linearAdjustment;
    copy.fixedRepeatNumber = parameter.fixedRepeatNumber;
  } else {
    throw new Error(
      "Invalid type:'" + parameter.constructor.name + "', can not copy."
    );
  }
  return copy;
}

// ------------------ The model
export enum TcAck {
  SUCCESS,
  FAILURE,
}

export class DatedTcAck {
  time: string;
  ack: string;
}

export class TmPacket {
  /**
   * Id of the packet. This field is only used after storing.
   */
  id: number;

  /**
   * Raw binary values of the packet
   */
  rawPacket: string;

  /**
   * The ground reception time
   */
  receptionTime: string;

  /**
   * The on board time
   */
  onBoardTime: string;

  /**
   * The type of the packet
   */
  packetType: string;

  /**
   * The APID of the packet
   */
  apid: number;

  /**
   * The source sequence counter
   */
  sourceSeqCount: number;

  /**
   * The grouping Flag
   */
  groupingFlag: number;

  /**
   * The space packet.
   */
  spacePacket: SpacePacket;

  status: Criticality;
}

export class TcPacket {
  /**
   * Id of the packet. This field is only used after storing.
   */
  id: number;

  /**
   * Raw binary values of the packet
   */
  rawPacket: string;

  /**
   * The ground generation time
   */
  generationnTime: string;

  /**
   * The type of the packet
   */
  packetType: string;

  /**
   * The APID of the packet
   */
  apid: number;

  /**
   * The source sequence counter
   */
  sourceSeqCount: number;

  /**
   * The grouping Flag
   */
  groupingFlag: number;

  /**
   * The acceptance status of the packet
   */
  acceptance: DatedTcAck;

  /**
   * The started status of the packet
   */
  started: DatedTcAck;

  /**
   * The progress status of the packet
   */
  progress: DatedTcAck;

  /**
   * The completed status of the packet
   */
  completed: DatedTcAck;

  /**
   * The space packet.
   */
  spacePacket: SpacePacket;

  status: Criticality;
}

export class SpacePacket {
  name: string;

  description: string;

  //    receptionDate: string;
  //
  //    onBoardDate: string;

  rootContainer: PacketContainer;
}

export interface AbstractContainer {}

export abstract class AbstractContainerImpl implements AbstractContainer {
  name: string;

  fixedRepeat = false;

  fixedRepeatNumber = 0;

  dynamicRepeat = false;

  dynamicRepeatParameterRef: string = null;

  useCalibratedValue = true;

  linearAdjustment: LinearAdjustement;
}

export abstract class AbstractParameter extends AbstractContainerImpl {}

export class PacketContainer extends AbstractContainerImpl {
  subContainers: Array<AbstractContainer>;

  status: Criticality;
}

export class PacketParameter extends AbstractParameter {
  description: string;

  spacePacket: SpacePacket;

  repeatedSpacePacket: SpacePacket[];

  argument = false;

  offset: number;
}

export class DatedParameter {
  parameter: Parameter;

  receptionTime: string;

  onBoardTime: string;

  sourcePacketName: string;

  packetId: number;
}

export class TcPacketId {
  generationTime: string;

  sourceSequenceCount: number;

  groupingFlag: number;

  apid: number;
}

export class Parameter extends AbstractParameter {
  fixedValue = false;

  argument = false;

  pfc: number;

  ptc: number;

  fixedLength = true;

  /**
   * The length in bits.
   */
  length: number;

  dynamicLengthParamRef: string;

  name: string;

  description: string;

  rawData: string;

  rawValue: Value<any>;

  physicalValue: Value<any>;

  calibration: Calibration;

  monitoring: Monitoring;

  status: Criticality;

  offset: number;

  // Maybe insert Unit into PhysicalValue, for that create a class physical value which extends
  // Value
  unit: Unit;
}

export abstract class Calibration {}

export class TextualCalibration extends Calibration {
  enumerationList: EnumValue[];
}

export class PolynomialCalibration extends Calibration {
  coefficients: Term[];
}

export class EnumValue {
  string: string;

  minValue: number;

  maxDefaultValue: number;
}

export class Term {
  factor: number;

  exponent: number;
}

export class Value<T> {
  value: T;

  valueType: ValueType;
}

export enum ValueType {
  Enum = "Enum",

  Binary = "Binary",

  Integer = "Integer",

  Double = "Double",

  AbsoluteTime = "AbsoluteTime",

  RelativeTime = "RelativeTime",
}

export enum Criticality {
  MAX = 9999,

  SEVERE = 5,

  CRITICAL = 4,

  DISTRESS = 3,

  WARNING = 2,

  WATCH = 1,

  NOMINAL = 0,
}

export class Unit {
  unit: string;

  description: string;

  power: number;

  factor: number;
}

export abstract class Monitoring {}

export class NumericMonitoringRange {
  criticality: Criticality;

  minInclusive: number;

  maxInclusive: number;

  minExclusive: number;

  maxExclusive: number;
}

export class NumericMonitoring extends Monitoring {
  severeRange: NumericMonitoringRange;

  criticalRange: NumericMonitoringRange;

  distressRange: NumericMonitoringRange;

  warningRange: NumericMonitoringRange;

  watchRange: NumericMonitoringRange;
}

export class EnumMonitoringCondition {
  criticality: Criticality;

  condition: string;
}

export class EnumMonitoring extends Monitoring {
  severeCondition: EnumMonitoringCondition;

  criticalCondition: EnumMonitoringCondition;

  distressCondition: EnumMonitoringCondition;

  warningCondition: EnumMonitoringCondition;

  watchCondition: EnumMonitoringCondition;

  nominalCondition: EnumMonitoringCondition;
}

export class LinearAdjustement {
  slope: number;

  intercept: number;
}
