import { Directive } from "@angular/core";
import {
  NG_VALIDATORS,
  FormControl,
  ValidatorFn,
  AbstractControl,
  Validators,
} from "@angular/forms";
import {
  Calibration,
  PolynomialCalibration,
  isPolynomialCalibration,
} from "../model/spacepacket";

/**
 *
 * @param calibration
 */
export function validateIsHexBinary(calibration: Calibration): ValidatorFn {
  return (c: FormControl) => {
    let hexFormatOk = false;
    const regexpHexString = new RegExp("^[a-fA-F0-9]+$");
    hexFormatOk = regexpHexString.test(c.value);

    return hexFormatOk
      ? null
      : {
          validateIsHexBinary: {
            valid: false,
          },
        };
  };
}

@Directive({
  selector: "[validateIsHexBinary]",
})
export class BinaryValidatorDirective {
  private valFn = Validators.nullValidator;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.valFn(control);
  }
}
