<span class="icon-stack" [id]="'InfoBulle-' + this.widgetId + '-' + this.getNameForTooltip(this.datedParameters) + '-' + this.index"
data-toggle="tooltip" data-placement="left" data-html="true" data-delay='{"show":"500", "hide":"0"}' title="test">
  <i *ngIf="triggered()" class="icon icon-controller-record icon-stack-base" [style.color]="this.getColor()"></i>
  <i class="icon icon-circle"></i>
</span>
<div class="text-xs-center text-truncate my-2" style="text-align: center">
  <div class="color-legend border-severe" [style.background]="getBackgoundLegendColor()"
    [style.border-color]="getColor()"></div>
  <span *ngIf="this.datedParameters[0]" [title]="getName()" class="parameterName">
    <span class="legendName"> {{getName()}} </span>
    <span *ngIf="this.isObsolete()" class="obsolescenceInfo"> ! </span>
  </span>
</div>