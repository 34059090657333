import { TmPacketsConfiguration } from "../../model/tm-packets-configuration";
import {
  SpacePacket,
  Parameter,
  TmPacket,
  PacketContainer,
  isPacketContainer,
  isParameter,
  AbstractContainer,
  isPacketParameter,
  PacketParameter,
} from "../../model/spacepacket";
import { TmPacketListService } from "../../tm-packet-list.service";
import { TmPacketsService } from "../../tm-packets.service";
import { Component, OnInit, Input, Directive } from "@angular/core";
import { isUndefined } from "util";
import { PopoverTourService } from "app/popover-tour.service";
declare let $: any;

@Component({
  selector: "app-tm-packets-sidebar",
  templateUrl: "./tm-packets-sidebar.component.html",
  styleUrls: ["./tm-packets-sidebar.component.css"],
})
export class TmPacketsSidebarComponent implements OnInit {
  listTcPacket: SpacePacket[];
  @Input() set tmList(tmList: SpacePacket[]) {
    if (tmList !== undefined && tmList.length > 0) {
      this.listTcPacket = tmList;
      this.getPacketList();
    }
  }

  @Input() showDateChoice: boolean;
  @Input() showNbPacketChoice: boolean;

  displayList: SpacePacket[];
  packetsList: SpacePacket[];

  tmPacketsConfiguration: TmPacketsConfiguration;
  packetSearch: string;
  edited: boolean;

  LimitPacketsMax: number;

  tmCheckedPackets: SpacePacket[];
  hideCheckedPackets = true;
  max10Packets = false;

  popoverTourService: PopoverTourService = PopoverTourService.Instance;

  constructor(
    private tmPacketListService: TmPacketListService,
    private tmPacketsService: TmPacketsService
  ) {
    this.tmPacketsConfiguration = this.tmPacketsService.tmPacketsConfiguration;
    this.tmPacketsConfiguration.selectList.forEach(this.unselectAll);
    this.setDateInterval();
    this.setPaquetMax();
    this.tmPacketsConfiguration.nbPaquetsMax = this.LimitPacketsMax;

    this.tmPacketsConfiguration.realTime = true;

    console.log("------------   Initialisation   ------------");
    console.log("DateStart : " + this.tmPacketsConfiguration.dateStart);
    console.log("TimeStart : " + this.tmPacketsConfiguration.timeStart);
    console.log("DateEnd : " + this.tmPacketsConfiguration.dateEnd);
    console.log("TimeEnd : " + this.tmPacketsConfiguration.timeEnd);

    this.edited = false;
  }

  ngOnInit() {
    //this.getPacketList();
  }

  getPacketList() {
    this.initPacketlist(this.listTcPacket);
  }

  //Initialisation des liste de type de paquets
  //displayList : liste qui est à afficher
  //packetsList : liste des paquets à garder en mémoire
  //Calcule aussi la taille de la liste pour lui assigner une place dans l'interface
  initPacketlist(list: SpacePacket[]) {
    this.displayList = this.alphabeticalSort(list);
    this.packetsList = list;
    this.tmCheckedPackets = this.packetsList;

    const heightSearch = 65;
    const heightElement = 34;
    if (
      this.displayList.length * heightElement + heightSearch >
      $("#paquet-sidebar").height() * 0.65
    ) {
      $("#collapsePaquet .block-menu-drop-down").css({
        height: $("#paquet-sidebar").height() * 0.65 + "px",
      });
    } else {
      $("#collapsePaquet .block-menu-drop-down").css({
        height: this.displayList.length * heightElement + heightSearch + "px",
      });
    }
    $("#scroll-liste-paquet").css({
      "max-height": $("#collapsePaquet").height() - 37 + "px",
    });
  }

  //Initialise la date d'intervalle lors du passage en temps différé
  setDateInterval() {
    //Nombre de jours d'intervalle entre la date de début et la date de fin lorsque on passe en temps différé
    const intervalDate = 10;

    const dEnd = new Date();
    const dateEnd =
      ("0" + dEnd.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (dEnd.getUTCMonth() + 1)).slice(-2) +
      "/" +
      dEnd.getUTCFullYear();
    const timeEnd =
      ("0" + dEnd.getUTCHours()).slice(-2) +
      ":" +
      ("0" + dEnd.getUTCMinutes()).slice(-2) +
      ":" +
      ("0" + dEnd.getUTCSeconds()).slice(-2);
    const dStart = new Date(dEnd);
    dStart.setDate(dStart.getUTCDate() - 10);
    const dateStart =
      ("0" + dStart.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (dStart.getUTCMonth() + 1)).slice(-2) +
      "/" +
      dStart.getUTCFullYear();
    const timeStart = timeEnd;

    this.tmPacketsConfiguration.dateStart = dateStart;
    this.tmPacketsConfiguration.timeStart = timeStart;
    this.tmPacketsConfiguration.dateEnd = dateEnd;
    this.tmPacketsConfiguration.timeEnd = timeEnd;
  }

  //Initialisation du nombre de paquets à afficher dans la liste des paquets
  setPaquetMax() {
    this.LimitPacketsMax = 100;
  }

  //Changement de date dans le date picker, et modification du nombre de paquets max à afficher
  onChange() {
    console.log("------------ Change ------------");
    console.log("DateStart : " + this.tmPacketsConfiguration.dateStart);
    console.log("TimeStart : " + this.tmPacketsConfiguration.timeStart);
    console.log("DateEnd : " + this.tmPacketsConfiguration.dateEnd);
    console.log("TimeEnd : " + this.tmPacketsConfiguration.timeEnd);
    this.tmPacketsConfiguration.nbPaquetsMax = this.LimitPacketsMax;
  }

  //Passage du temps réel au temps différé
  onChangeRealTime() {
    // Désactive la checkbox pendant l'animation
    $("#checkbox-temps-reelTM input").prop("disabled", true);
    setTimeout(function () {
      console.log("TestTimeOut");
      $("#checkbox-temps-reelTM input").prop("disabled", false);
    }, 400);
    if (!this.tmPacketsConfiguration.realTime) {
      this.setDateInterval();
      $("#collapseRealTimeTM").collapse("show");
    } else {
      $("#collapseRealTimeTM").collapse("hide");
    }
  }

  //Recherche d'un type de paquet dans la barre de recherche(Nom Parametre, Description, Nom subParametre))
  onChangeSearchPacket() {
    this.displayList = [];
    let find = false;

    for (const packet of this.packetsList) {
      if (packet.name != null) {
        if (
          packet.name.toUpperCase().indexOf(this.packetSearch.toUpperCase()) >
          -1
        ) {
          this.displayList.push(packet);
          find = true;
        }
      }
      if (packet.description != null && !find) {
        if (
          packet.description
            .toUpperCase()
            .indexOf(this.packetSearch.toUpperCase()) > -1
        ) {
          this.displayList.push(packet);
          find = true;
        }
      }
      if (!find) {
        for (const subPacket of this.getParameters(packet.rootContainer)) {
          if (this.isParameter) {
            const parameter = <Parameter>subPacket;
            if (parameter.name != null) {
              if (
                parameter.name
                  .toUpperCase()
                  .indexOf(this.packetSearch.toUpperCase()) > -1 &&
                !find
              ) {
                find = true;
                this.displayList.push(packet);
              }
            }
            if (parameter.description != null) {
              if (
                parameter.description
                  .toUpperCase()
                  .indexOf(this.packetSearch.toUpperCase()) > -1 &&
                !find &&
                parameter.description != null
              ) {
                find = true;
                this.displayList.push(packet);
              }
            }
          }
        }
      }
      find = false;
    }
    if (this.packetSearch.length > 0) {
      this.edited = true;
    } else {
      this.edited = false;
    }
  }

  onChangeCheckedPackets(packetName: string) {
    this.tmPacketsConfiguration.selectList.set(
      packetName,
      !this.tmPacketsConfiguration.selectList.get(packetName)
    );
    let isCheck = false;

    let nbCheckedPacket = 0;

    for (const packet of this.tmCheckedPackets) {
      if (this.tmPacketsConfiguration.selectList.get(packet.name)) {
        this.hideCheckedPackets = false;
        isCheck = true;
        nbCheckedPacket++;
      }
    }

    // Ca ne sert que pour bloquer le nombre max de paquet selectionné quand >= 10
    if (nbCheckedPacket >= 10) {
      this.max10Packets = true;
    } else {
      this.max10Packets = false;
    }

    if (!isCheck) this.hideCheckedPackets = true;
  }

  unselectAll(valeur: boolean, packetName: string, map: Map<string, boolean>) {
    map.set(packetName, false);
  }

  getParameters(selectedContainer: PacketContainer): Parameter[] {
    let parametersToReturn: Parameter[] = new Array<Parameter>();
    parametersToReturn = this.recursiveGetParameters(
      selectedContainer,
      parametersToReturn
    );
    return parametersToReturn;
  }

  recursiveGetParameters(
    selectedContainer: AbstractContainer,
    parameterList: Parameter[]
  ): Parameter[] {
    const parametersToReturn = parameterList;
    if (isPacketContainer(selectedContainer)) {
      for (const packetContainer of (<PacketContainer>selectedContainer)
        .subContainers) {
        parametersToReturn.concat(
          this.recursiveGetParameters(packetContainer, parameterList)
        );
      }
    } else if (isParameter(selectedContainer)) {
      parametersToReturn.push(<Parameter>selectedContainer);
    } else if (isPacketParameter(selectedContainer)) {
      parametersToReturn.concat(
        this.recursiveGetParameters(
          (<PacketParameter>selectedContainer).spacePacket.rootContainer,
          parameterList
        )
      );
    }

    return parametersToReturn;
  }

  //Click sur le bouton "clear"
  clearSearch() {
    this.packetSearch = "";
    this.onChangeSearchPacket();
  }

  isParameter(abstractContainer: AbstractContainer): boolean {
    return isParameter(abstractContainer);
  }

  alphabeticalSort(list: SpacePacket[]): SpacePacket[] {
    return list.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
