import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { ParametersService } from "app/parameters.service";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { RoutingModule } from "./routing.module";
import { ArgumentviewDetailComponent } from "./send-tc/argumentview-detail/argumentview-detail.component";
import { PacketParameterComponent } from "./send-tc/argumentview-detail/packet-parameter/packet-parameter.component";
import { ParameterComponent } from "./send-tc/argumentview-detail/parameter/parameter.component";
import { SendTcComponent } from "./send-tc/send-tc.component";
import { TcHistoryComponent } from "./send-tc/tc-history/tc-history.component";
import { TcPacketsSidebarComponent } from "./send-tc/tc-packets-sidebar/tc-packets-sidebar.component";
import { CreateWidgetComponent } from "./synoptic/create-widget/create-widget.component";
import { DisplayWidgetComponent } from "./synoptic/display-widget/display-widget.component";
import { LineChartComponent } from "./synoptic/display-widget/widget-view/line-chart/line-chart.component";
import { SynopticSidebarComponent } from "./synoptic/synoptic-sidebar/synoptic-sidebar.component";
import { SynopticComponent } from "./synoptic/synoptic.component";
import { ParametersDetailsComponent } from "./tm-packets/parameters-details/parameters-details.component";
import { TmPacketsExplorerComponent } from "./tm-packets/tm-packets-explorer/tm-packets-explorer.component";
import { TmPacketsSidebarComponent } from "./tm-packets/tm-packets-sidebar/tm-packets-sidebar.component";
import { TmPacketsComponent } from "./tm-packets/tm-packets.component";
import { TmParamFromXtceService } from "./tm-param-from-xtce.service";
import { FloatValidatorDirective } from "./validators/float-validator.directive";
import { IntegerValidatorDirective } from "./validators/integer-validator.directive";
import { MaxValidatorDirective } from "./validators/max-validator.directive";
import { MinValidatorDirective } from "./validators/min-validator.directive";
import { NanoValidatorDirective } from "./validators/nano-validator.directive";
import { BinaryValidatorDirective } from "./validators/binary-validator.directive";
import { MaxHexValidatorDirective } from "./validators/max-hex-validator.directive";
import { BdsPacketsComponent } from "./bds-packets/bds-packets.component";
import { CreateSynopticComponent } from "./synoptic/create-synoptic/create-synoptic.component";
import { AlphaNumWidgetComponent } from "./synoptic/display-widget/alpha-num-widget/alpha-num-widget.component";
import { JaugeWidgetComponent } from "./synoptic/display-widget/jauge-widget/jauge-widget.component";
import { TriggerWidgetComponent } from "./synoptic/display-widget/trigger-widget/trigger-widget.component";
import { SingleValueComponent } from "./synoptic/display-widget/alpha-num-widget/single-value/single-value.component";
import { HttpErrorHandlerService } from "./http-error-handler.service";
import { RepeatParameterComponent } from "./send-tc/argumentview-detail/repeat-parameter/repeat-parameter.component";
import { SecondValidatorDirective } from "./validators/second-validator.directive";

@NgModule({
  declarations: [
    AppComponent,
    TmPacketsComponent,
    SendTcComponent,
    ArgumentviewDetailComponent,
    ParameterComponent,
    PacketParameterComponent,
    HomeComponent,
    TmPacketsSidebarComponent,
    TmPacketsExplorerComponent,
    ParametersDetailsComponent,
    TcPacketsSidebarComponent,
    TcHistoryComponent,
    MinValidatorDirective,
    MaxValidatorDirective,
    IntegerValidatorDirective,
    FloatValidatorDirective,
    NanoValidatorDirective,
    SecondValidatorDirective,
    BinaryValidatorDirective,
    MaxHexValidatorDirective,
    SynopticComponent,
    SynopticSidebarComponent,
    CreateWidgetComponent,
    DisplayWidgetComponent,
    LineChartComponent,
    BdsPacketsComponent,
    CreateSynopticComponent,
    AlphaNumWidgetComponent,
    JaugeWidgetComponent,
    TriggerWidgetComponent,
    SingleValueComponent,
    RepeatParameterComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RoutingModule,
    ReactiveFormsModule,
  ],
  providers: [
    ParametersService,
    TmParamFromXtceService,
    HttpErrorHandlerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
