import { Directive } from "@angular/core";
import {
  NG_VALIDATORS,
  FormControl,
  ValidatorFn,
  AbstractControl,
  Validators,
} from "@angular/forms";

/**
 *
 */
export function validateNanoLengthFactory(): ValidatorFn {
  return (c: FormControl) => {
    let valueToCompare = "";
    valueToCompare = c.value;
    if (
      valueToCompare != null &&
      valueToCompare.toString().indexOf("e") == -1 &&
      valueToCompare.toString().length <= 9 &&
      valueToCompare.toString().length >= 0
    ) {
      return null;
    }
    return {
      nanoValidator: {
        valid: false,
      },
    };
  };
}

@Directive({
  selector: "[nanoValidator]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NanoValidatorDirective,
      multi: true,
    },
  ],
})
export class NanoValidatorDirective {
  private valFn = Validators.nullValidator;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.valFn(control);
  }
}
