<nav
  class="navbar navbar-toggleable-sm fixed-top navbar-inverse bg-inverse app-navbar"
>
  <button
    class="navbar-toggler navbar-toggler-right hidden-md-up"
    type="button"
    data-toggle="collapse"
    data-target="#navbarResponsive"
    aria-controls="navbarResponsive"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div
    *ngIf="this.popoverTourService.SequenceIndex == 0"
    class="popover popover-center help-tour-popover"
    role="tooltip"
  >
    <h3 class="popover-title">
      Simple Control Center Demo
      <button
        (click)="this.popoverTourService.quitSequence()"
        type="button"
        class="close popover-close"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </h3>
    <div class="popover-content" style="text-align: center">
      <strong
        >Bienvenue dans l'interface de démonstration du Simple Control
        Center.</strong
      ><br />
      Cliquer sur Suivant pour découvrir un bref résumé des interfaces et des
      fonctionnalités proposées par SCC.
    </div>
    <div class="popover-footer">
      <button
        (click)="this.popoverTourService.quitSequence()"
        type="button"
        class="btn btn-default add-on-button mr-4"
      >
        Quitter
      </button>
      <button
        (click)="this.popoverTourService.nextSequence()"
        type="button"
        class="btn btn-primary"
      >
        Suivant
      </button>
    </div>
  </div>

  <a routerLink="/" (click)="closeHelp()" class="navbar-brand Montserrat"
    ><img
      class="iconav-brand-icon"
      src="assets/img/LogoSCCColor.png"
      width="30"
      height="30"
      class="d-inline-block align-top"
      alt=""
    />
  </a>

  <div class="collapse navbar-collapse mr-auto" id="navbarResponsive">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <div
          id="help-tm-navbar"
          data-container="body"
          data-html="true"
          data-placement="bottom"
          data-trigger="manual"
          data-title="Interface Télémesure (TM)"
        >
          <a
            class="nav-link"
            routerLink="/tm-packets"
            routerLinkActive="active"
            (click)="closeHelp()"
            >Télémesure</a
          >

          <div
            *ngIf="this.popoverTourService.SequenceIndex == 1"
            class="popover bs-tether-element-attached-top help-tour-popover"
            role="tooltip"
          >
            <h3 class="popover-title">
              Interface Télémesure (TM)
              <button
                (click)="this.popoverTourService.quitSequence()"
                type="button"
                class="close popover-close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </h3>
            <div class="popover-content">
              - <strong>Visualisation des paquets TM reçus</strong> en temps
              réel et en différé<br />
              - Visualisation de la valeur brute et physique
              <strong>des paramètres TM</strong> <br />
              - Visualisation des paramètres en dehors des seuils surveillance
              sol<br />
              - <strong>Recherche des paquets TM reçus</strong> par nom et par
              date
            </div>
            <div class="popover-footer">
              <button
                (click)="this.popoverTourService.previousSequence()"
                type="button"
                class="btn btn-default add-on-button mr-4"
              >
                Précédent
              </button>
              <button
                (click)="this.popoverTourService.nextSequence()"
                type="button"
                class="btn btn-primary"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="/send-tc"
          routerLinkActive="active"
          (click)="closeHelp()"
          id="tcNavbarItem"
          >Télécommande</a
        >

        <div
          *ngIf="this.popoverTourService.SequenceIndex == 2"
          class="popover bs-tether-element-attached-top help-tour-popover"
          role="tooltip"
        >
          <h3 class="popover-title">
            Interface Télécommande (TC)
            <button
              (click)="this.popoverTourService.quitSequence()"
              type="button"
              class="close popover-close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </h3>
          <div class="popover-content">
            - <strong>Envoi d'un paquet TC</strong> en temps réel<br />
            - Liste des paquets TC envoyés<br />
            - Visualisation <strong>état d'exécution</strong> des TC envoyés<br />
            - <strong>Recherche des paquets TC envoyés</strong> par nom et par
            date
          </div>
          <div class="popover-footer">
            <button
              (click)="this.popoverTourService.previousSequence()"
              type="button"
              class="btn btn-default add-on-button mr-4"
            >
              Précédent
            </button>
            <button
              (click)="this.popoverTourService.nextSequence()"
              type="button"
              class="btn btn-primary"
            >
              Suivant
            </button>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="/synoptic"
          routerLinkActive="active"
          (click)="closeHelp()"
          id="synopticNavbarItem"
          >Synoptiques</a
        >

        <div
          *ngIf="this.popoverTourService.SequenceIndex == 3"
          class="popover bs-tether-element-attached-top help-tour-popover"
          role="tooltip"
        >
          <div class="arrow"></div>
          <h3 class="popover-title">
            Interface Synoptiques
            <button
              (click)="this.popoverTourService.quitSequence()"
              type="button"
              class="close popover-close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </h3>
          <div class="popover-content">
            - <strong>Création de vue</strong> contenant des widgets courbe,
            histogramme, jauge...<br />
            - Visualisation du contenu des
            <strong>paquets TM sous forme graphique</strong> en temps réel<br />
            - Visualisation du contenu des paquets TM sous forme graphique par
            intervalle de date de réception<br />
            - Visualisation des seuils définis dans les
            <strong>surveillances sol</strong><br />
          </div>
          <div class="popover-footer">
            <button
              (click)="this.popoverTourService.previousSequence()"
              type="button"
              class="btn btn-default add-on-button mr-4"
            >
              Précédent
            </button>
            <button
              (click)="this.popoverTourService.nextSequence()"
              type="button"
              class="btn btn-primary"
            >
              Suivant
            </button>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="/bds-packets"
          routerLinkActive="active"
          (click)="closeHelp()"
          id="bdsNavbarItem"
          >BDS Explorer</a
        >
        <div
          *ngIf="this.popoverTourService.SequenceIndex == 4"
          class="popover bs-tether-element-attached-top help-tour-popover"
          role="tooltip"
        >
          <div class="arrow"></div>
          <h3 class="popover-title">
            Interface BDS Explorer
            <button
              (click)="this.popoverTourService.quitSequence()"
              type="button"
              class="close popover-close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </h3>
          <div class="popover-content">
            - Liste de l'ensemble des paquets TM et TC définis dans la BDS (Base
            de Données Système)<br />
            - Visualisation de la structure des paquets<br />
            - Visualisation des types et des valeurs des paramètres/arguments
            pour chaque paquet TM et TC<br />
          </div>
          <div class="popover-footer">
            <button
              (click)="this.popoverTourService.previousSequence()"
              type="button"
              class="btn btn-default add-on-button mr-4"
            >
              Précédent
            </button>
            <button
              (click)="this.popoverTourService.quitSequence()"
              type="button"
              class="btn btn-primary"
            >
              Quitter
            </button>
          </div>
        </div>
      </li>
      <!-- <li class="nav-item"><a class="nav-link" href="#" (click)="closeHelp()">Journal
					des évènements</a></li> -->
    </ul>
    <ul class="nav navbar-nav ml-auto" id="navbar-icons">
      <div
        *ngIf="
          this.popoverTourService.SequenceIndex == 105 ||
          this.popoverTourService.SequenceIndex == 203 ||
          this.popoverTourService.SequenceIndex == 304
        "
        class="popover bs-tether-element-attached-top help-tour-popover popover-help"
        role="tooltip"
      >
        <h3 class="popover-title">
          Aide
          <button
            (click)="this.popoverTourService.quitSequence()"
            type="button"
            class="close popover-close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </h3>
        <div class="popover-content">
          Cliquer sur l'icone d'aide afin d'avoir plus d'information.
        </div>
        <div class="popover-footer">
          <button
            (click)="this.popoverTourService.previousSequence()"
            type="button"
            class="btn btn-default add-on-button mr-4"
          >
            Précédent
          </button>
          <button
            (click)="this.popoverTourService.quitSequence()"
            type="button"
            class="btn btn-primary"
          >
            Quitter
          </button>
        </div>
      </div>

      <li><span class="navbar-text" id="date-UTC"></span></li>

      <li>
        <!-- <span data-toggle="button" aria-pressed="false" autocomplete="off"><button type="button" class="btn btn-xs btn-pill btn-secondary"
					 id="language-button" data-toggle="tooltip" data-placement="bottom" data-delay='{"show":"500", "hide":"0"}'
					 data-trigger="hover" title="Langue">
						<span></span>
					</button>
				</span> -->
        <span data-toggle="button" aria-pressed="false" autocomplete="off">
          <button
            type="button"
            class="btn btn-xs btn-pill btn-secondary"
            id="help-button"
            (click)="clickedHelp()"
            data-toggle="tooltip"
            data-placement="bottom"
            data-delay='{"show":"500", "hide":"0"}'
            data-trigger="hover"
            title="Aide"
          >
            <span></span>
          </button>
        </span>
        <span data-toggle="button" aria-pressed="false" autocomplete="off">
          <button
            type="button"
            class="btn btn-xs btn-pill btn-secondary"
            id="full-screen-button"
            data-toggle="tooltip"
            data-placement="bottom"
            data-delay='{"show":"500", "hide":"0"}'
            data-trigger="hover"
            title=""
          >
            <span></span>
          </button>
        </span>
      </li>
    </ul>
  </div>
</nav>
<router-outlet></router-outlet>
