//import { PACKETS } from './mock-tm-packets';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { SpacePacket } from "./model/spacepacket";
declare let $: any;

@Injectable()
export class TmPacketListService {
  private tmPacketsUrl = "api/tm-packet-list";

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.tmPacketsUrl = "http://localhost:15000/" + this.tmPacketsUrl;
    }
    this.handleError = httpErrorHandler.createHandleError(
      "TmPacketListService"
    );
  }

  getTmPacketListString(): Observable<Array<string>> {
    //              return Promise.resolve(PACKETS);
    return this.http
      .get<Array<string>>(this.tmPacketsUrl)
      .pipe(catchError(this.handleError("getTmPacketListString", [])));
  }

  getTmPacketList(): Observable<Array<SpacePacket>> {
    //              return Promise.resolve(PACKETS);
    return this.http
      .get<Array<SpacePacket>>(this.tmPacketsUrl)
      .pipe(catchError(this.handleError("getTmPacketList", [])));
  }
}
