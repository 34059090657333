<div id="paquet-sidebar" class="scrollbar-style" data-container="body" data-html="true" data-placement="right"
 data-trigger="manual" data-title="Menu latéral" data-content="Description">
	<div id="menu-date" class="card">
		<div class="card-header menu-drop-down" id="headingDate">
			<p class="mb-0">
				<a class="accordion-toggle" data-toggle="collapse" href="#collapseDate" aria-expanded="false" aria-controls="collapseDate">
					Filtrer par date </a>
			</p>
		</div>

		<div id="collapseDate" class="collapse show">
			<div class="card-block block-menu-drop-down">
				<div class="card-header header-real-time" id="headingRealTime">

					<p class="mb-0">
						<a class="accordion-toggle text-toogle"> <span *ngIf=this.tcPacketsConfiguration.realTime> <label><span>Temps
										réel activé</span> </label>
							</span> <span *ngIf=!this.tcPacketsConfiguration.realTime> <label><span>Temps
										réel désactivé</span> </label></span>
						</a> <a> <label class="control-toggle" id="checkbox-temps-reelTC"><input type="checkbox" checked="checked"
								 [(ngModel)]="this.tcPacketsConfiguration.realTime" (ngModelChange)="onChangeRealTime()">
								<div class="toggle-button"></div>
							</label>
						</a>
					</p>
				</div>
				<div id="collapseRealTimeTC" class="collapse">
					<div class="card-block block-real-time input-daterange">
						<div class="input-group input-date">
							<span class="input-group-addon"> Du </span> <input id="dateStartFilter" type="text" name="Date-du"
							 data-date-format="dd/mm/yyyy" class="form-control date-picker" #datePickerStart data-date-today-highlight="true"
							 [(ngModel)]="tcPacketsConfiguration.dateStart" data-provide="datepicker" (blur)="tcPacketsConfiguration.dateStart = datePickerStart.value;onChange()">
						</div>
						<div class="input-group input-date">
							<span class="input-group-addon"> Au </span> <input id="dateEndFilter" type="text" name="Date-au"
							 data-date-format="dd/mm/yyyy" class="form-control date-picker" data-date-today-highlight="true" #datePickerEnd
							 [(ngModel)]="tcPacketsConfiguration.dateEnd" data-provide="datepicker" (blur)="tcPacketsConfiguration.dateEnd = datePickerEnd.value;onChange()">
						</div>
					</div>
					<div id="timeFilterGroup">
						<div class="input-group input-date">
							<input id="timeStartFilter" class="form-control time-picker" type="time" name="Heure-au" step="1" value="00:00:00"
							 [(ngModel)]="tcPacketsConfiguration.timeStart" (ngModelChange)="onChange()">
						</div>
						<div class="input-group input-date">
							<input id="timeEndFilter" class="form-control time-picker" type="time" name="Heure-du" step="1" value="00:00:00"
							 [(ngModel)]="tcPacketsConfiguration.timeEnd" (ngModelChange)="onChange()">
						</div>
					</div>
					<div class="clearfix"></div>
				</div>
				<div *ngIf="this.popoverTourService.SequenceIndex == 202" class="popover bs-tether-element-attached-top help-tour-popover" role="tooltip">
					<h3 class="popover-title">
						Filtrer par date
						<button (click)="this.popoverTourService.quitSequence()" type="button" class="close popover-close" aria-label="Close">
							<span aria-hidden="true" >&times;</span>
						</button>
					</h3>
					<div class="popover-content">
						<strong>Désactiver le temps réel</strong> pour filtrer par date.<br />
						Le filtre s'applique sur <strong>la date d'envoi.</strong>
					</div>
					<div class="popover-footer">
						<button (click)="this.popoverTourService.previousSequence()" type="button" class="btn btn-default add-on-button mr-4">Précédent</button>
						<button	(click)="this.popoverTourService.nextSequence()" type="button" class="btn btn-primary">Suivant</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div id="menu-paquet" class="card">
		<div class="card-header menu-drop-down" id="headingPaquet">
			<p class="mb-0">
				<a class="accordion-toggle" data-toggle="collapse" href="#collapsePaquet" aria-expanded="false" aria-controls="collapsePaquet">
					Filtrer par paquet </a>
			</p>
		</div>
		<div class="card-block block-menu-drop-down">
			<div id="tc-packet-sidebar-search" class="input-group" data-toggle="tooltip" data-placement="top" title="Rechercher par nom de paquet, description du paquet ou par nom d'un argument"
			 data-delay='{"show":"800", "hide":"0"}' data-trigger="hover" aria-pressed="false" autocomplete="off">
				<input type="text" class="form-control" placeholder="Rechercher..." [(ngModel)]="packetSearch" (ngModelChange)="onChangeSearchPacket()">
				<span class="input-group-btn">
					<button id="searchButton" class="btn btn-default" type="button" (click)="clearSearch()">
						<span [hidden]="!edited" class="icon icon-circle-with-cross noir" aria-hidden="true"></span> <span [hidden]="edited"
						 class="icon icon-magnifying-glass noir" aria-hidden="true"></span>
					</button>
				</span>
			</div>
			<ul [hidden]="hideCheckedPackets" id="scroll-liste-paquet" class="list-group list-group-root scroll-y  scrollbar-style">
				<ng-container *ngFor="let packet of tcCheckedPackets">
					<li *ngIf="tcPacketsConfiguration.selectList.get(packet.packetName)" class="list-group-item"><label class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" [checked]="tcPacketsConfiguration.selectList.get(packet.packetName)"
							 (change)="onChangeCheckedPackets(packet.packetName)"> <span class="custom-control-indicator"></span> <span
							 class="custom-control-description" data-toggle="tooltip" data-delay='{"show":"800", "hide":"0"}' data-placement="right"
							 title="{{packet.packetDescription}}">{{packet.packetName}}</span>
						</label></li>
				</ng-container>
			</ul>
			<hr [hidden]="hideCheckedPackets" />
			<div id="collapsePaquet" class="collapse show">
				<ul id="scroll-liste-paquet" class="list-group list-group-root scroll-y  scrollbar-style">
					<li *ngFor="let packet of displayList" class="list-group-item"><label class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" [checked]="tcPacketsConfiguration.selectList.get(packet.packetName)"
							 [disabled]="max10Packets && !tcPacketsConfiguration.selectList.get(packet.packetName)" (change)="onChangeCheckedPackets(packet.packetName)">
							<span class="custom-control-indicator"></span> <span class="custom-control-description" data-toggle="tooltip"
							 data-delay='{"show":"800", "hide":"0"}' data-placement="right" title="{{packet.packetDescription}}">{{packet.packetName}}</span>
						</label></li>
				</ul>
			</div>
		</div>
	</div>
	<div id="menu-vue" class="card">
		<div class="card-header menu-drop-down" id="headingNbPackets">
			<p class="mb-0">
				<a class="accordion-toggle" data-toggle="collapse" href="#collapseNbPackets" aria-expanded="false" aria-controls="collapseNbPackets">
					Nombre de paquets </a>
			</p>
		</div>
		<div id="collapseNbPackets" class="collapse show">
			<div class="card-block block-menu-drop-down">
				<label class="mr-sm-2" for="inlineFormCustomSelect">Limite
					du nombre de paquets</label> <select class="custom-select mb-2 mr-sm-2 mb-sm-0" id="inlineFormCustomSelect"
				 [(ngModel)]="LimitPacketsMax" (ngModelChange)="onChange()">
					<option value="10">10</option>
					<option value="100">100</option>
					<option value="500">500</option>
					<option value="1000">1000</option>
				</select>
			</div>
		</div>
	</div>
</div>