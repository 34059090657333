import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { DatedParameter, formatDate } from "app/model/spacepacket";
import { SynopticConfiguration } from "app/model/synoptic-configuration";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";

declare let $: any;

@Injectable()
export class ParametersService {
  private parametersUrl = "api/parameters";

  synopticConfiguration: SynopticConfiguration = new SynopticConfiguration();

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private test: DatedParameter[][];

  private handleError: HandleError;

  private ngZone: NgZone;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService,
    ngZone: NgZone
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.parametersUrl = "http://localhost:15000/" + this.parametersUrl;
    }
    this.handleError = httpErrorHandler.createHandleError("ParametersService");
    this.ngZone = ngZone;
  }

  //    TODO Il faut ajouter la possibilité d'avoir une map de nom de paquets mappés à une lsite de paramètres

  getParameters(
    packets: string[],
    params: string[],
    nbValues: number
  ): Observable<Array<DatedParameter[]>> {
    let filterDateURL = "";
    if (this.synopticConfiguration.realTime) {
      filterDateURL = "?start=realTime";
    } else {
      filterDateURL = formatDate(
        this.synopticConfiguration.dateStart,
        this.synopticConfiguration.timeStart,
        this.synopticConfiguration.dateEnd,
        this.synopticConfiguration.timeEnd
      );
    }

    let filterPacketsURL = "";
    const nbPacketsURL = "&maxLimit=" + nbValues;
    // On ajoute tous les packets même si ils sont en doublon car le back-end en a besoin
    packets.forEach((value: string) => {
      filterPacketsURL += "&filter=" + value;
    });
    const orderByURL = "&orderBy=" + this.synopticConfiguration.orderBy;
    let requestedParameters = "";
    for (const param of params) {
      requestedParameters += "&requestedParameters=" + param;
    }

    return this.http
      .get<Array<DatedParameter[]>>(
        this.parametersUrl +
          filterDateURL +
          filterPacketsURL +
          nbPacketsURL +
          orderByURL +
          requestedParameters
      )
      .pipe(catchError(this.handleError("getParameters", [])));
  }
}
