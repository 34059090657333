import { ArgumentView } from "../../model/argumentview";
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Directive,
} from "@angular/core";
import {
  isParameter,
  isPacketParameter,
  AbstractContainer,
  Parameter,
  shallowCopy,
  AbstractContainerImpl,
} from "../../model/spacepacket";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-argumentview-detail",
  templateUrl: "./argumentview-detail.component.html",
  styleUrls: ["./argumentview-detail.component.css"],
})
export class ArgumentviewDetailComponent implements OnInit {
  @Input()
  argumentView: ArgumentView;

  @Input()
  tcArguments: ArgumentView[];

  @Input()
  hideFixedArgument: boolean;

  @Input()
  form: FormGroup;

  dynamicRepeatRefParam: Map<string, Subscription>;

  constructor() {}

  ngOnInit() {
    this.dynamicRepeatRefParam = new Map();
  }

  ngOnDestroy() {
    this.dynamicRepeatRefParam.forEach((val) => val.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName == "argumentView") {
        //When TC change, clean subscriptions
        if (this.dynamicRepeatRefParam != null) {
          this.dynamicRepeatRefParam.forEach((val) => val.unsubscribe());
          this.dynamicRepeatRefParam.clear();
        }
      }
    }
  }

  isParameter(abstractContainer: AbstractContainer): boolean {
    return isParameter(abstractContainer);
  }

  isPacketParameter(abstractContainer: AbstractContainer): boolean {
    return isPacketParameter(abstractContainer);
  }

  subscribeToDynamicRepeatRefChange(arg: AbstractContainerImpl) {
    let oldValue = -1;
    let isStart = true;
    //This is only usefull for repetetion with linearAdjustment
    //if all repeatedParameter are destroyed this subscription will re-add repeatedParameter
    if (
      this.dynamicRepeatRefParam.has(arg.dynamicRepeatParameterRef) == false ||
      this.dynamicRepeatRefParam.get(arg.dynamicRepeatParameterRef) === null ||
      this.dynamicRepeatRefParam.get(arg.dynamicRepeatParameterRef).closed
    ) {
      const dynamicRepeatRefSubscription = this.form
        .get(arg.dynamicRepeatParameterRef)
        .valueChanges.subscribe((val) => {
          //If dynamicRepeatRef old val is zero and new val upper than 0, re-add repeated param
          // TODO: Use linear adjustment to compare old value
          if (
            val !== undefined &&
            val !== null &&
            val > 0 &&
            !isStart &&
            (oldValue == null || oldValue <= 0)
          ) {
            for (let i = 0; i < val; i++) {
              const clone = shallowCopy(arg);
              clone.fixedRepeatNumber = 0;
              clone.dynamicRepeatParameterRef = null;
              this.argumentView.argumentParameters.splice(
                this.argumentView.argumentParameters.length,
                0,
                <AbstractContainer>clone
              );
              if (i == 0) {
                clone.fixedRepeatNumber = arg.fixedRepeatNumber;
                clone.dynamicRepeatParameterRef = arg.dynamicRepeatParameterRef;
              }
            }
          }
          oldValue = val;
          isStart = false;
        });
      this.dynamicRepeatRefParam.set(
        arg.dynamicRepeatParameterRef,
        dynamicRepeatRefSubscription
      );
    }
  }

  getRepeatedDynamicRefValue(arg: AbstractContainerImpl) {
    if (this.form.get(arg.dynamicRepeatParameterRef) == null) {
      return 0;
    }
    this.subscribeToDynamicRepeatRefChange(arg);
    return this.form.get(arg.dynamicRepeatParameterRef).value;
  }

  isParamDynamicRef(arg: Parameter) {
    return this.dynamicRepeatRefParam.has(arg.name);
  }

  dynamicRefIncrease(dynamicRefName: string) {
    if (this.form.get(dynamicRefName).value === null) {
      this.form.get(dynamicRefName).setValue(0);
    } else {
      this.form
        .get(dynamicRefName)
        .setValue(this.form.get(dynamicRefName).value + 1);
    }
    return false; //Return false to avoid form submit
  }

  dynamicRefDecrease(dynamicRefName: string) {
    this.form
      .get(dynamicRefName)
      .setValue(this.form.get(dynamicRefName).value - 1);
    return false; //Return false to avoid form submit
  }
}
