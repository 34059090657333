import {
  Parameter,
  isNumericMonitoring,
  isEnumMonitoring,
  NumericMonitoring,
  EnumMonitoring,
  Criticality,
} from "./spacepacket";

/**
 * Returns criticality list of a parameter.
 *
 * @param {Parameter} parameter Parameter
 * @returns {string} A string table listing parameter criticalities
 */
export function returnCriticalityList(parameter: Parameter): string {
  let listToReturn = "";
  if (isNumericMonitoring(parameter.monitoring)) {
    listToReturn =
      "<table><tbody>" +
      "<tr>" +
      getIntervalParameter(parameter, Criticality.NOMINAL) +
      "</tr>" +
      "<tr>" +
      getIntervalParameter(parameter, Criticality.WATCH) +
      "</tr>" +
      "<tr>" +
      getIntervalParameter(parameter, Criticality.WARNING) +
      "</tr>" +
      "<tr>" +
      getIntervalParameter(parameter, Criticality.DISTRESS) +
      "</tr>" +
      "<tr>" +
      getIntervalParameter(parameter, Criticality.CRITICAL) +
      "</tr>" +
      "<tr>" +
      getIntervalParameter(parameter, Criticality.SEVERE) +
      "</tr>" +
      "</tbody></table>";
  }

  if (isEnumMonitoring(parameter.monitoring)) {
    let listEnum = "";
    if ((<EnumMonitoring>parameter.monitoring).nominalCondition != null) {
      const nominalCondition =
        "<span class='icon icon-controller-record nominal'></span> Nominal : " +
        (<EnumMonitoring>parameter.monitoring).nominalCondition.condition;
      listEnum +=
        setBoldCurrentEnumStatus(
          nominalCondition,
          parameter.status,
          Criticality.NOMINAL
        ) + "<br>";
    }
    if ((<EnumMonitoring>parameter.monitoring).watchCondition != null) {
      const watchCondition =
        "<span class='icon icon-controller-record watch'></span> Watch : " +
        (<EnumMonitoring>parameter.monitoring).watchCondition.condition;
      listEnum +=
        setBoldCurrentEnumStatus(
          watchCondition,
          parameter.status,
          Criticality.WATCH
        ) + "<br>";
    }
    if ((<EnumMonitoring>parameter.monitoring).warningCondition != null) {
      const warningCondition =
        "<span class='icon icon-controller-record warning'></span> Warning : " +
        (<EnumMonitoring>parameter.monitoring).warningCondition.condition;
      listEnum +=
        setBoldCurrentEnumStatus(
          warningCondition,
          parameter.status,
          Criticality.WARNING
        ) + "<br>";
    }
    if ((<EnumMonitoring>parameter.monitoring).distressCondition != null) {
      const distressCondition =
        "<span class='icon icon-controller-record distress'></span> Distress : " +
        (<EnumMonitoring>parameter.monitoring).distressCondition.condition;
      listEnum +=
        setBoldCurrentEnumStatus(
          distressCondition,
          parameter.status,
          Criticality.DISTRESS
        ) + "<br>";
    }
    if ((<EnumMonitoring>parameter.monitoring).criticalCondition != null) {
      const criticalCondition =
        "<span class='icon icon-controller-record critical'></span> Critical : " +
        (<EnumMonitoring>parameter.monitoring).criticalCondition.condition;
      listEnum +=
        setBoldCurrentEnumStatus(
          criticalCondition,
          parameter.status,
          Criticality.CRITICAL
        ) + "<br>";
    }
    if ((<EnumMonitoring>parameter.monitoring).severeCondition != null) {
      const severeCondition =
        "<span class='icon icon-controller-record severe'></span> Severe : " +
        (<EnumMonitoring>parameter.monitoring).severeCondition.condition;
      listEnum += setBoldCurrentEnumStatus(
        severeCondition,
        parameter.status,
        Criticality.SEVERE
      );
    }
    listToReturn = "<div class='text-left'>" + listEnum + "</div>";
  }

  return listToReturn;
}

/**
 * Sets the name of an enum to bold if its criticality matches the current parameter status.
 *
 * @param {string} statusEnum Name of the enum member
 * @param {Criticality} statusParameter Parameter status
 * @param {Criticality} statusCriticality Criticality status
 * @returns {string} The enum name, normal or bold
 */
function setBoldCurrentEnumStatus(
  statusEnum: string,
  statusParameter: Criticality,
  statusCriticality: Criticality
): string {
  let stringToReturn = "";
  if (statusParameter == statusCriticality) {
    stringToReturn = "<strong>" + statusEnum + "</strong>";
  } else {
    stringToReturn = statusEnum;
  }
  return stringToReturn;
}

/**
 * Gets the interval corresponding to a specific criticality of a parameter.
 *
 * @param {Parameter} parameter Telemetry packet parameter
 * @param {Criticality} criticality Criticality to get the interval from
 * @returns {string} Value interval
 */
function getIntervalParameter(
  parameter: Parameter,
  criticality: Criticality
): string {
  let stringToReturn = "";
  let firstInterval;
  let secondInterval;
  const status = parameter.status;
  switch (criticality) {
    case Criticality.NOMINAL: {
      const nominalInterval =
        getMinIntervalHigherCriticality(parameter, Criticality.WATCH) +
        " ; " +
        getMaxIntervalHigherCriticality(parameter, Criticality.WATCH);
      stringToReturn = setCurrentStatusBold(
        nominalInterval,
        "",
        Criticality.NOMINAL,
        status
      );
      break;
    }
    case Criticality.WATCH:
      if (
        (<NumericMonitoring>parameter.monitoring).watchRange != null &&
        (<NumericMonitoring>parameter.monitoring).watchRange != undefined
      ) {
        const watchMinIncl = (<NumericMonitoring>parameter.monitoring)
          .watchRange.minInclusive;
        const watchMaxIncl = (<NumericMonitoring>parameter.monitoring)
          .watchRange.maxInclusive;
        const watchMinExcl = (<NumericMonitoring>parameter.monitoring)
          .watchRange.minExclusive;
        const watchMaxExcl = (<NumericMonitoring>parameter.monitoring)
          .watchRange.maxExclusive;
        firstInterval =
          getMinIntervalHigherCriticality(parameter, Criticality.WARNING) +
          " ; " +
          getMinInterval(watchMinIncl, watchMinExcl);
        secondInterval =
          getMaxInterval(watchMaxIncl, watchMaxExcl) +
          " ; " +
          getMaxIntervalHigherCriticality(parameter, Criticality.WARNING);
        stringToReturn = setCurrentStatusBold(
          firstInterval,
          secondInterval,
          Criticality.WATCH,
          status
        );
      }

      break;
    case Criticality.WARNING:
      if (
        (<NumericMonitoring>parameter.monitoring).warningRange != null &&
        (<NumericMonitoring>parameter.monitoring).warningRange != undefined
      ) {
        const warningMinIncl = (<NumericMonitoring>parameter.monitoring)
          .warningRange.minInclusive;
        const warningMaxIncl = (<NumericMonitoring>parameter.monitoring)
          .warningRange.maxInclusive;
        const warningMinExcl = (<NumericMonitoring>parameter.monitoring)
          .warningRange.minExclusive;
        const warningMaxExcl = (<NumericMonitoring>parameter.monitoring)
          .warningRange.maxExclusive;
        firstInterval =
          getMinIntervalHigherCriticality(parameter, Criticality.DISTRESS) +
          " ; " +
          getMinInterval(warningMinIncl, warningMinExcl);
        secondInterval =
          getMaxInterval(warningMaxIncl, warningMaxExcl) +
          " ; " +
          getMaxIntervalHigherCriticality(parameter, Criticality.DISTRESS);
        stringToReturn = setCurrentStatusBold(
          firstInterval,
          secondInterval,
          Criticality.WARNING,
          status
        );
      }

      break;
    case Criticality.DISTRESS:
      if (
        (<NumericMonitoring>parameter.monitoring).distressRange != null &&
        (<NumericMonitoring>parameter.monitoring).distressRange != undefined
      ) {
        const distressMinIncl = (<NumericMonitoring>parameter.monitoring)
          .distressRange.minInclusive;
        const distressMaxIncl = (<NumericMonitoring>parameter.monitoring)
          .distressRange.maxInclusive;
        const distressMinExcl = (<NumericMonitoring>parameter.monitoring)
          .distressRange.minExclusive;
        const distressMaxExcl = (<NumericMonitoring>parameter.monitoring)
          .distressRange.maxExclusive;
        firstInterval =
          getMinIntervalHigherCriticality(parameter, Criticality.CRITICAL) +
          " ; " +
          getMinInterval(distressMinIncl, distressMinExcl);
        secondInterval =
          getMaxInterval(distressMaxIncl, distressMaxExcl) +
          " ; " +
          getMaxIntervalHigherCriticality(parameter, Criticality.CRITICAL);
        stringToReturn = setCurrentStatusBold(
          firstInterval,
          secondInterval,
          Criticality.DISTRESS,
          status
        );
      }

      break;
    case Criticality.CRITICAL:
      if (
        (<NumericMonitoring>parameter.monitoring).criticalRange != null &&
        (<NumericMonitoring>parameter.monitoring).criticalRange != undefined
      ) {
        const criticalMinIncl = (<NumericMonitoring>parameter.monitoring)
          .criticalRange.minInclusive;
        const criticalMaxIncl = (<NumericMonitoring>parameter.monitoring)
          .criticalRange.maxInclusive;
        const criticalMinExcl = (<NumericMonitoring>parameter.monitoring)
          .criticalRange.minExclusive;
        const criticalMaxExcl = (<NumericMonitoring>parameter.monitoring)
          .criticalRange.maxExclusive;
        firstInterval =
          getMinIntervalHigherCriticality(parameter, Criticality.SEVERE) +
          " ; " +
          getMinInterval(criticalMinIncl, criticalMinExcl);
        secondInterval =
          getMaxInterval(criticalMaxIncl, criticalMaxExcl) +
          " ; " +
          getMaxIntervalHigherCriticality(parameter, Criticality.SEVERE);
        stringToReturn = setCurrentStatusBold(
          firstInterval,
          secondInterval,
          Criticality.CRITICAL,
          status
        );
      }

      break;
    case Criticality.SEVERE:
      if (
        (<NumericMonitoring>parameter.monitoring).severeRange != null &&
        (<NumericMonitoring>parameter.monitoring).severeRange != undefined
      ) {
        const severeMinIncl = (<NumericMonitoring>parameter.monitoring)
          .severeRange.minInclusive;
        const severeMaxIncl = (<NumericMonitoring>parameter.monitoring)
          .severeRange.maxInclusive;
        const severeMinExcl = (<NumericMonitoring>parameter.monitoring)
          .severeRange.minExclusive;
        const severeMaxExcl = (<NumericMonitoring>parameter.monitoring)
          .severeRange.maxExclusive;
        firstInterval =
          getMinIntervalHigherCriticality(parameter, Criticality.MAX) +
          " ; " +
          getMinInterval(severeMinIncl, severeMinExcl);
        secondInterval =
          getMaxInterval(severeMaxIncl, severeMaxExcl) +
          " ; " +
          getMaxIntervalHigherCriticality(parameter, Criticality.MAX);
        stringToReturn = setCurrentStatusBold(
          firstInterval,
          secondInterval,
          Criticality.SEVERE,
          status
        );
      }
      break;
    default:
  }
  return stringToReturn;
}

/**
 * Returns the min value of the left interval by seeking the higher criticality interval.
 *
 * @param {Parameter} parameter Parameter
 * @param {Criticality} criticality Criticality
 * @returns {string} Min value
 */
function getMinIntervalHigherCriticality(
  parameter: Parameter,
  criticality: Criticality
): string {
  let stringToReturn = "error";
  if (isNumericMonitoring(parameter.monitoring)) {
    switch (criticality) {
      case Criticality.NOMINAL:
        stringToReturn = getMinIntervalHigherCriticality(
          parameter,
          Criticality.WATCH
        );
        break;
      case Criticality.WATCH:
        if (
          (<NumericMonitoring>parameter.monitoring).watchRange == null ||
          (<NumericMonitoring>parameter.monitoring).watchRange == undefined
        ) {
          stringToReturn = getMinIntervalHigherCriticality(
            parameter,
            Criticality.WARNING
          );
        } else {
          const watchMinIncl = (<NumericMonitoring>parameter.monitoring)
            .watchRange.minInclusive;
          const watchMinExcl = (<NumericMonitoring>parameter.monitoring)
            .watchRange.minExclusive;
          if (watchMinIncl != null) {
            stringToReturn = "]" + watchMinIncl;
          } else if (watchMinExcl != null) {
            stringToReturn = "[" + watchMinExcl;
          } else {
            stringToReturn = null;
          }
        }
        break;
      case Criticality.WARNING:
        if (
          (<NumericMonitoring>parameter.monitoring).warningRange == null ||
          (<NumericMonitoring>parameter.monitoring).warningRange == undefined
        ) {
          stringToReturn = getMinIntervalHigherCriticality(
            parameter,
            Criticality.DISTRESS
          );
        } else {
          const warningMinIncl = (<NumericMonitoring>parameter.monitoring)
            .warningRange.minInclusive;
          const warningMinExcl = (<NumericMonitoring>parameter.monitoring)
            .warningRange.minExclusive;
          if (warningMinIncl != null) {
            stringToReturn = "]" + warningMinIncl;
          } else if (warningMinExcl != null) {
            stringToReturn = "[" + warningMinExcl;
          } else {
            stringToReturn = null;
          }
        }
        break;
      case Criticality.DISTRESS:
        if (
          (<NumericMonitoring>parameter.monitoring).distressRange == null ||
          (<NumericMonitoring>parameter.monitoring).distressRange == undefined
        ) {
          stringToReturn = getMinIntervalHigherCriticality(
            parameter,
            Criticality.CRITICAL
          );
        } else {
          const distressMinIncl = (<NumericMonitoring>parameter.monitoring)
            .distressRange.minInclusive;
          const distressMinExcl = (<NumericMonitoring>parameter.monitoring)
            .distressRange.minExclusive;
          if (distressMinIncl != null) {
            stringToReturn = "]" + distressMinIncl;
          } else if (distressMinExcl != null) {
            stringToReturn = "[" + distressMinExcl;
          } else {
            stringToReturn = null;
          }
        }
        break;
      case Criticality.CRITICAL:
        if (
          (<NumericMonitoring>parameter.monitoring).criticalRange == null ||
          (<NumericMonitoring>parameter.monitoring).criticalRange == undefined
        ) {
          stringToReturn = getMinIntervalHigherCriticality(
            parameter,
            Criticality.SEVERE
          );
        } else {
          const criticalMinIncl = (<NumericMonitoring>parameter.monitoring)
            .criticalRange.minInclusive;
          const criticalMinExcl = (<NumericMonitoring>parameter.monitoring)
            .criticalRange.minExclusive;
          if (criticalMinIncl != null) {
            stringToReturn = "]" + criticalMinIncl;
          } else if (criticalMinExcl != null) {
            stringToReturn = "[" + criticalMinExcl;
          } else {
            stringToReturn = null;
          }
        }
        break;
      case Criticality.SEVERE:
        if (
          (<NumericMonitoring>parameter.monitoring).severeRange == null ||
          (<NumericMonitoring>parameter.monitoring).severeRange == undefined
        ) {
          stringToReturn = getMinIntervalHigherCriticality(
            parameter,
            Criticality.MAX
          );
        } else {
          const severeMinIncl = (<NumericMonitoring>parameter.monitoring)
            .severeRange.minInclusive;
          const severeMinExcl = (<NumericMonitoring>parameter.monitoring)
            .severeRange.minExclusive;
          if (severeMinIncl != null) {
            stringToReturn = "]" + severeMinIncl;
          } else if (severeMinExcl != null) {
            stringToReturn = "[" + severeMinExcl;
          } else {
            stringToReturn = null;
          }
        }
        break;
      case Criticality.MAX:
        stringToReturn = "] -∞";
        break;
      default:
        stringToReturn = "Non disponible";
    }
  }
  return stringToReturn;
}

/**
 * Returns the max value of the right interval by seeking the higher criticality interval.
 *
 * @param {Parameter} parameter Parameter
 * @param {Criticality} criticality Criticality
 * @returns {string} Max value
 */
function getMaxIntervalHigherCriticality(
  parameter: Parameter,
  criticality: Criticality
): string {
  let stringToReturn = "error";
  if (isNumericMonitoring(parameter.monitoring)) {
    switch (criticality) {
      case Criticality.NOMINAL:
        stringToReturn = getMaxIntervalHigherCriticality(
          parameter,
          Criticality.WATCH
        );
        break;
      case Criticality.WATCH:
        if (
          (<NumericMonitoring>parameter.monitoring).watchRange == null ||
          (<NumericMonitoring>parameter.monitoring).watchRange == undefined
        ) {
          stringToReturn = getMaxIntervalHigherCriticality(
            parameter,
            Criticality.WARNING
          );
        } else {
          const watchMaxIncl = (<NumericMonitoring>parameter.monitoring)
            .watchRange.maxInclusive;
          const watchMaxExcl = (<NumericMonitoring>parameter.monitoring)
            .watchRange.maxExclusive;
          if (watchMaxIncl != null) {
            stringToReturn = watchMaxIncl + "[";
          } else if (watchMaxExcl != null) {
            stringToReturn = watchMaxExcl + "]";
          } else {
            stringToReturn = null;
          }
        }
        break;
      case Criticality.WARNING:
        if (
          (<NumericMonitoring>parameter.monitoring).warningRange == null ||
          (<NumericMonitoring>parameter.monitoring).warningRange == undefined
        ) {
          stringToReturn = getMaxIntervalHigherCriticality(
            parameter,
            Criticality.DISTRESS
          );
        } else {
          const warningMaxIncl = (<NumericMonitoring>parameter.monitoring)
            .warningRange.maxInclusive;
          const warningMaxExcl = (<NumericMonitoring>parameter.monitoring)
            .warningRange.maxExclusive;
          if (warningMaxIncl != null) {
            stringToReturn = warningMaxIncl + "[";
          } else if (warningMaxExcl != null) {
            stringToReturn = warningMaxExcl + "]";
          } else {
            stringToReturn = null;
          }
        }
        break;
      case Criticality.DISTRESS:
        if (
          (<NumericMonitoring>parameter.monitoring).distressRange == null ||
          (<NumericMonitoring>parameter.monitoring).distressRange == undefined
        ) {
          stringToReturn = getMaxIntervalHigherCriticality(
            parameter,
            Criticality.CRITICAL
          );
        } else {
          const distressMaxIncl = (<NumericMonitoring>parameter.monitoring)
            .distressRange.maxInclusive;
          const distressMaxExcl = (<NumericMonitoring>parameter.monitoring)
            .distressRange.maxExclusive;
          if (distressMaxIncl != null) {
            stringToReturn = distressMaxIncl + "[";
          } else if (distressMaxExcl != null) {
            stringToReturn = distressMaxExcl + "]";
          } else {
            stringToReturn = null;
          }
        }
        break;
      case Criticality.CRITICAL:
        if (
          (<NumericMonitoring>parameter.monitoring).criticalRange == null ||
          (<NumericMonitoring>parameter.monitoring).criticalRange == undefined
        ) {
          stringToReturn = getMaxIntervalHigherCriticality(
            parameter,
            Criticality.SEVERE
          );
        } else {
          const criticalMaxIncl = (<NumericMonitoring>parameter.monitoring)
            .criticalRange.maxInclusive;
          const criticalMaxExcl = (<NumericMonitoring>parameter.monitoring)
            .criticalRange.maxExclusive;
          if (criticalMaxIncl != null) {
            stringToReturn = criticalMaxIncl + "[";
          } else if (criticalMaxExcl != null) {
            stringToReturn = criticalMaxExcl + "]";
          } else {
            stringToReturn = null;
          }
        }
        break;
      case Criticality.SEVERE:
        if (
          (<NumericMonitoring>parameter.monitoring).severeRange == null ||
          (<NumericMonitoring>parameter.monitoring).severeRange == undefined
        ) {
          stringToReturn = getMaxIntervalHigherCriticality(
            parameter,
            Criticality.MAX
          );
        } else {
          const severeMaxIncl = (<NumericMonitoring>parameter.monitoring)
            .severeRange.maxInclusive;
          const severeMaxExcl = (<NumericMonitoring>parameter.monitoring)
            .severeRange.maxExclusive;
          if (severeMaxIncl != null) {
            stringToReturn = severeMaxIncl + "[";
          } else if (severeMaxExcl != null) {
            stringToReturn = severeMaxExcl + "]";
          } else {
            stringToReturn = null;
          }
        }
        break;
      case Criticality.MAX:
        stringToReturn = "+∞ [";
        break;
      default:
        stringToReturn = "Non Disponible";
    }
  }
  return stringToReturn;
}

/**
 * Returns the min value of the interval with a left bracket (including or excluding)
 *
 * @param {number} intervalIncl Including value
 * @param {number} intervalExcl Excluding value
 * @returns {string} Value as string
 */
function getMinInterval(intervalIncl: number, intervalExcl: number): string {
  let stringToReturn;
  if (intervalIncl != null) {
    stringToReturn = intervalIncl + "]";
  } else if (intervalExcl != null) {
    stringToReturn = intervalExcl + "[";
  } else {
    stringToReturn = null;
  }
  return stringToReturn;
}

/**
 * Returns the max value of the interval with a right bracket (including or excluding)
 *
 * @param {number} intervalIncl Including value
 * @param {number} intervalExcl Excluding value
 * @returns {string} Value as string
 */
function getMaxInterval(intervalIncl: number, intervalExcl: number): string {
  let stringToReturn;
  if (intervalIncl != null) {
    stringToReturn = "[" + intervalIncl;
  } else if (intervalExcl != null) {
    stringToReturn = "]" + intervalExcl;
  } else {
    stringToReturn = null;
  }
  return stringToReturn;
}

/**
 * Sets the current status to bold if matches the parameter status.
 *
 * @param {string} firstInterval First interval
 * @param {string} secondInterval Second interval
 * @param {Criticality} statusCurrent Current status
 * @param {Criticality} statusParameter Parameter status
 * @returns {string} Status as string
 */
function setCurrentStatusBold(
  firstInterval: string,
  secondInterval: string,
  statusCurrent: Criticality,
  statusParameter: Criticality
): string {
  let stringToReturn;
  if (statusCurrent == statusParameter) {
    stringToReturn =
      "<td align='left'> <strong><span class='icon icon-controller-record " +
      returnCriticalityClass(statusCurrent) +
      "'></span>  " +
      statusCurrent +
      ": </strong></td>" +
      "<td align='left'><strong>" +
      firstInterval +
      "</strong></td>" +
      "<td align='left' style='padding-left: 20px;'><strong>" +
      secondInterval +
      "</strong></td>";
  } else {
    stringToReturn =
      "<td align='left'><span class='icon icon-controller-record " +
      returnCriticalityClass(statusCurrent) +
      "'></span>  " +
      statusCurrent +
      ": </td>" +
      "<td align='left'>" +
      firstInterval +
      "</td>" +
      "<td align='left' style='padding-left: 20px;'>" +
      secondInterval +
      "</td>";
  }
  return stringToReturn;
}

//Permet de retourner la classe CSS en fonction du "status" en entré
/**
 * Returns the CSS class corresponding to a certain input status.
 *
 * @param {Criticality} status Input status
 * @returns {string} Css class as string
 */
function returnCriticalityClass(status: Criticality): string {
  let statusToReturn;
  switch (status) {
    case Criticality.NOMINAL:
      statusToReturn = "nominal";
      break;
    case Criticality.WATCH:
      statusToReturn = "watch";
      break;
    case Criticality.WARNING:
      statusToReturn = "warning";
      break;
    case Criticality.DISTRESS:
      statusToReturn = "distress";
      break;
    case Criticality.CRITICAL:
      statusToReturn = "critical";
      break;
    case Criticality.SEVERE:
      statusToReturn = "severe";
      break;
    default:
      statusToReturn = "";
  }
  return statusToReturn;
}
