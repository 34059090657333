import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TmPacketsComponent } from "./tm-packets/tm-packets.component";
import { SendTcComponent } from "./send-tc/send-tc.component";
import { HomeComponent } from "./home/home.component";
import { SynopticComponent } from "./synoptic/synoptic.component";
import { BdsPacketsComponent } from "./bds-packets/bds-packets.component";

const routes: Routes = [
  //    { path: '', redirectTo: '', pathMatch: 'full' },
  { path: "tm-packets", component: TmPacketsComponent },
  { path: "send-tc", component: SendTcComponent },
  { path: "synoptic", component: SynopticComponent },
  { path: "bds-packets", component: BdsPacketsComponent },
  { path: "", component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RoutingModule {}
