import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
declare let $: any;

@Injectable()
export class BdsVersionService {
  private bdsServiceUrl = "api/bds-version";

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.bdsServiceUrl = "http://localhost:15000/" + this.bdsServiceUrl;
    }
    this.handleError = httpErrorHandler.createHandleError("SendTcService");
  }

  bdsVersion(): Observable<string[]> {
    return this.http
      .get<string[]>(this.bdsServiceUrl)
      .pipe(catchError(this.handleError("bdsVersion", [])));
  }
}
