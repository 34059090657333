<form name="form" (ngSubmit)="addSynoptic()" ngNativeValidate>
  <!-- [formGroup]="form"  -->
  <div class="modal-body">
    <div class="form-group row">
      <label for="title" class="col-sm-2 col-form-label  mr-auto">Title</label>
      <div class="col-sm-8">
        <input class="form-control" id="title" name="title" placeholder="" [(ngModel)]="newSynoptic.title"
          maxlength="64" required />
      </div>
    </div>
    <hr>
    <div class="form-group row">
      <label for="description" class="col-sm-2 col-form-label mr-auto">Description</label>
      <div class="col-sm-8 ">
        <textarea class="form-control" id="description" name="description" [(ngModel)]="newSynoptic.description"
          rows="3" cols="40" maxlength="64"></textarea>
      </div>
    </div>
    <hr>
  </div>
  <div class="modal-actions">
    <button type="button" class="btn btn-sm btn-pill btn-danger" data-dismiss="modal">Annuler</button>
    <button type="submit" class="btn btn-sm btn-pill btn-success">
      <strong>
        <ng-container *ngIf="isModify">Modifier</ng-container>
        <ng-container *ngIf="!isModify">Ajouter</ng-container>
      </strong>
    </button>
  </div>
</form>