import {
  TcPacket,
  SpacePacket,
  PacketContainer,
  AbstractContainer,
  formatDate,
  updatePacketStatus,
  AbstractParameter,
  isParameter,
  isPacketContainer,
  Parameter,
  isPacketParameter,
  PacketParameter,
  TcAck,
  Value,
} from "../../model/spacepacket";
import { TcPacketsConfiguration } from "../../model/tc-packets-configuration";
import { TcPacketsService } from "../../tc-packets.service";
import { SendTcService } from "../../send-tc.service";
import { ArgumentView } from "../../model/argumentview";
import { Component, OnInit, Input, Directive } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { PopoverTourService } from "app/popover-tour.service";
declare let $: any;

@Component({
  selector: "app-tc-history",
  templateUrl: "./tc-history.component.html",
  styleUrls: ["./tc-history.component.css"],
})
export class TcHistoryComponent implements OnInit {
  tcArgument: ArgumentView[];

  @Input() tcpackets: TcPacket[];
  @Input() set tcArguments(tcArguments: ArgumentView[]) {
    if (tcArguments !== undefined && tcArguments.length > 0) {
      this.tcArgument = tcArguments;
      this.getArgumentViews();
    }
  }

  tcPacketsConfiguration: TcPacketsConfiguration;
  initTableSort = false;
  parameterList: Parameter[];

  displayList: ArgumentView[];
  packetsTCList: ArgumentView[];

  maxLimitDownload: number;
  format: string;
  namePacketToDownload: string;
  nameError: boolean;

  popoverTourService: PopoverTourService = PopoverTourService.Instance;

  private exportPacketsUrl = "api/download-tc-packets";

  constructor(
    private router: Router,
    private sendTcService: SendTcService,
    private tcPacketsService: TcPacketsService
  ) {
    this.tcPacketsConfiguration = this.tcPacketsService.tcPacketsConfiguration;

    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.exportPacketsUrl = "http://localhost:15000/" + this.exportPacketsUrl;
    }
  }

  ngOnInit() {
    this.format = "csv";
    this.maxLimitDownload = 50;
    this.namePacketToDownload = "";
  }

  getArgumentViews() {
    this.initPacketlist(this.tcArgument);
  }

  initPacketlist(list: ArgumentView[]) {
    this.packetsTCList = this.alphabeticalSort(list);
  }

  alphabeticalSort(list: ArgumentView[]): ArgumentView[] {
    return list.sort(function (a, b) {
      if (a.packetName < b.packetName) {
        return -1;
      } else if (a.packetName > b.packetName) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  onSelect(id: number): void {
    $("#" + id).toggleClass("selected");
    $("#hiddenRow" + id).collapse("toggle");
    //Efface le bouton "CopieHEX" a la fin de la transition
    $("#hiddenRow" + id).on("hidden.bs.collapse", function () {
      $("#rawPacketCopie-button" + id).hide();
    });
  }

  onHoverIn(id: number) {
    if ($("#hiddenRow" + id).hasClass("show")) {
    } else {
      $('[data-toggle="tooltip"]').tooltip();
      $("#rawPacketCopie-button" + id).show();
    }
  }

  onHoverOut(id: number) {
    if (
      $("#hiddenRow" + id).hasClass("show") ||
      $("#hiddenRow" + id).hasClass("collapsing")
    ) {
    } else {
      $("#rawPacketCopie-button" + id).hide();
    }
  }

  isParameter(abstractContainer: AbstractContainer): boolean {
    return isParameter(abstractContainer);
  }

  isPacketParameter(abstractContainer: AbstractContainer): boolean {
    return isPacketParameter(abstractContainer);
  }

  getArguments(selectedContainer: PacketContainer): AbstractParameter[] {
    let argumentsToReturn: AbstractParameter[] = new Array<AbstractParameter>();
    argumentsToReturn = this.recursiveGetArguments(
      selectedContainer,
      argumentsToReturn
    );
    return argumentsToReturn;
  }

  getUrlForPacketExport(): void {
    this.nameError = false;
    let filterDateURL = "";

    if (this.tcPacketsConfiguration.realTime) {
      filterDateURL = "?start=realTime";
    } else {
      filterDateURL = formatDate(
        this.tcPacketsConfiguration.dateStart,
        this.tcPacketsConfiguration.timeStart,
        this.tcPacketsConfiguration.dateEnd,
        this.tcPacketsConfiguration.timeEnd
      );
    }

    const filterURL = "&filter=" + this.namePacketToDownload;
    const nbPacketsURL = "&maxLimit=" + this.maxLimitDownload;
    const formatURL = "&format=" + this.format;

    $("#downloadTc").modal("hide");
    window.open(
      this.exportPacketsUrl +
        filterDateURL +
        nbPacketsURL +
        filterURL +
        formatURL
    );
  }

  getMessageNbPacket(): string {
    if (this.maxLimitDownload == -1 || this.maxLimitDownload == null) {
      return "Les paquets seront récupérés à partir de la base de données.";
    } else {
      return (
        "Les " +
        this.maxLimitDownload +
        " paquets les plus récents seront récupérés à partir de la base de données."
      );
    }
  }

  recursiveGetArguments(
    selectedContainer: AbstractContainer,
    parameterList: AbstractParameter[]
  ): AbstractParameter[] {
    const argumentsToReturn = parameterList;
    if (
      isPacketContainer(selectedContainer) &&
      (<PacketContainer>selectedContainer).subContainers != null
    ) {
      for (const packetContainer of (<PacketContainer>selectedContainer)
        .subContainers) {
        argumentsToReturn.concat(
          this.recursiveGetArguments(packetContainer, parameterList)
        );
      }
    } else if (isParameter(selectedContainer)) {
      if ((<Parameter>selectedContainer).argument) {
        argumentsToReturn.push(<Parameter>selectedContainer);
      }
    } else if (isPacketParameter(selectedContainer)) {
      argumentsToReturn.push(<PacketParameter>selectedContainer);
    }

    return argumentsToReturn;
  }

  getNbArguments(packet: TcPacket): number {
    return this.getArguments(packet.spacePacket.rootContainer).length;
  }

  onCopieHexPacket(event: Event, raw: string) {
    console.log(raw);
    event.stopPropagation();
    document.execCommand("copy");
    const $temp = $("<input>");
    $("body").append($temp);
    $temp.val(raw).select();
    document.execCommand("copy");
    $temp.remove();
  }

  getLengthPacket(raw: string): number {
    return raw.length / 2;
  }

  //Formate la date au format dd/mm/aaaa hh:mm:ss.nnn (Format en entré aaaa-mm-ddThh:mm:ss.nnnZ ou aaaa-mm-ddThh:mmZ si nano = 000)
  formatDate(date: string): string {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    let time = date.slice(11, 23).replace("Z", "");
    if (time.length == 8) {
      time += ".000";
    }
    return day + "/" + month + "/" + year + " " + time;
  }

  //retourne le status de la TC (retourne "" si la TC n'a pas encore été acceptée)
  lastStateTc(packet: TcPacket): string {
    let statusToReturn = "";
    if (packet.acceptance != null) {
      statusToReturn = "acceptance";
    }
    if (packet.started != null) {
      statusToReturn = "started";
    }
    if (packet.progress != null) {
      statusToReturn = "progress";
    }
    if (packet.completed != null) {
      statusToReturn = "completed";
    }
    return statusToReturn;
  }

  lastCriticalityTc(packet: TcPacket): string {
    let statusToReturn = "";
    switch (this.lastStateTc(packet)) {
      case "acceptance":
        //                console.log(packet.acceptance.ack);
        if (packet.acceptance.ack == "SUCCESS") {
          statusToReturn = "SUCCESS";
        } else if (packet.acceptance.ack == "FAILURE") {
          statusToReturn = "FAILURE";
        }
        break;
      case "started":
        if (packet.started.ack == "SUCCESS") {
          statusToReturn = "SUCCESS";
        } else if (packet.started.ack == "FAILURE") {
          statusToReturn = "FAILURE";
        }
        break;
      case "progress":
        if (packet.progress.ack == "SUCCESS") {
          statusToReturn = "SUCCESS";
        } else if (packet.progress.ack == "FAILURE") {
          statusToReturn = "FAILURE";
        }
        break;
      case "completed":
        if (packet.completed.ack == "SUCCESS") {
          statusToReturn = "SUCCESS";
        } else if (packet.completed.ack == "FAILURE") {
          statusToReturn = "FAILURE";
        }
        break;
      default:
        statusToReturn = "";
        break;
    }

    return statusToReturn;
  }

  getColorSatus(packet: TcPacket): string {
    let colorToReturn = "";
    switch (this.lastCriticalityTc(packet)) {
      case "":
        colorToReturn = "#cfd2da";
        break;
      case "SUCCESS":
        colorToReturn = "#1bc98e";
        break;
      case "FAILURE":
        colorToReturn = "#e64759";
        break;
      default:
        break;
    }
    return colorToReturn;
  }

  isBugFligthSoft(packet: TcPacket): boolean {
    const ackStatus = Array<string>();
    if (packet.acceptance != null) ackStatus.push(packet.acceptance.ack);
    if (packet.started != null) ackStatus.push(packet.started.ack);
    if (packet.progress != null) ackStatus.push(packet.progress.ack);
    if (packet.completed != null) ackStatus.push(packet.completed.ack);

    for (let i = 0; i < ackStatus.length; i++) {
      if (ackStatus[i] == "FAILURE") {
        for (let j = i + 1; j < ackStatus.length; j++) {
          if (ackStatus[j] == "SUCCESS" || ackStatus[j] == "FAILURE") {
            return true;
          }
        }
      }
    }

    return false;
  }
}
