import { Component, OnInit, Input, Output, Directive } from "@angular/core";
import {
  DatedParameter,
  SpacePacket,
  Parameter,
  isEnum,
} from "app/model/spacepacket";
import { returnCriticalityList } from "app/model/criticalityList";
import { getPointBackgroundColor } from "../utils/color-getter";
import { Colors } from "../../../model/colors";
declare let $: any;

@Component({
  selector: "app-trigger-widget",
  templateUrl: "./trigger-widget.component.html",
  styleUrls: ["./trigger-widget.component.css"],
})
export class TriggerWidgetComponent implements OnInit {
  colors: Colors = new Colors();

  @Input() datedParameters: DatedParameter[];
  @Input() index: number;
  @Input() idColor: number;
  @Input() obsolescence: number;
  @Input() orderBy: string;
  @Input() widgetId: number;

  constructor() {}

  ngOnInit() {}

  ngAfterViewChecked() {
    $(
      "#InfoBulle-" +
        this.widgetId +
        "-" +
        this.getNameForTooltip(this.datedParameters) +
        "-" +
        this.index
    )
      .tooltip()
      .attr("data-original-title", this.getMessageBulle());
  }

  private getMessageBulle(): string {
    let toReturn = "";
    toReturn += this.getDate(this.datedParameters[0]);
    toReturn += this.getObsoleteMessage();
    toReturn += "<br>";
    toReturn += this.getValueFromParam(this.datedParameters[0]);
    toReturn += "<br>";
    toReturn += "valeur brute : ";
    toReturn += this.getRawValue(this.datedParameters[0]);
    toReturn += "<br>";
    toReturn += "valeur hexa : ";
    toReturn += this.getRawData(this.datedParameters[0]);
    toReturn += this.getCriticalityList(this.datedParameters[0].parameter);
    return toReturn;
  }

  getColor() {
    return this.colors.lineChartColors[this.idColor].pointBackgroundColor;
  }

  getValueFromParam(param: DatedParameter) {
    const value = param.parameter.physicalValue.value;
    return value + "" + this.getUnit(param);
  }

  getRawValue(param: DatedParameter) {
    return param.parameter.rawValue.value;
  }

  getRawData(param: DatedParameter) {
    return param.parameter.rawData;
  }

  private getUnit(param: DatedParameter): string {
    try {
      return param.parameter.unit.unit;
    } catch (e) {
      return "";
    }
  }

  getBackgoundLegendColor() {
    return this.colors.lineChartColors[this.idColor].backgroundColor;
  }

  getName() {
    if (this.datedParameters[0]) {
      return this.datedParameters[0].parameter.name;
    }
  }

  triggered(): boolean {
    if (this.datedParameters[0]) {
      return this.datedParameters[0].parameter.physicalValue.value > 5;
    }
  }

  isObsolete(): boolean {
    if (this.obsolescence) {
      if (this.orderBy == "onReceptionTime") {
        var pointDate = new Date(this.datedParameters[0].receptionTime);
      } else {
        var pointDate = new Date(this.datedParameters[0].onBoardTime);
      }
      const currentDate = new Date();

      const diffInSec = (currentDate.getTime() - pointDate.getTime()) / 1000;

      if (diffInSec > this.obsolescence) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getObsoleteMessage(): string {
    if (this.isObsolete()) {
      return "<br><b>La valeur est obsolète</b>";
    } else {
      return "";
    }
  }

  getDate(dataParameter: DatedParameter): string {
    if (this.orderBy == "onReceptionTime") {
      return new Date(dataParameter.receptionTime).toLocaleString();
    } else {
      return new Date(dataParameter.onBoardTime).toLocaleString();
    }
  }

  getCriticalityList(parameter: Parameter): string {
    return returnCriticalityList(parameter);
  }

  getNameForTooltip(datedParameters: DatedParameter[]) {
    if (datedParameters != undefined) {
      return datedParameters[0].parameter.name;
    } else {
      return "";
    }
  }
}
