import { TcPacketsConfiguration } from "../../model/tc-packets-configuration";
import { ArgumentView } from "../../model/argumentview";
import { SendTcService } from "../../send-tc.service";
import { TcPacketsService } from "../../tc-packets.service";
import { Component, OnInit, Input, Directive } from "@angular/core";
import {
  Parameter,
  isParameter,
  isPacketParameter,
  AbstractContainer,
  PacketParameter,
  isPacketContainer,
  PacketContainer,
} from "../../model/spacepacket";
import { PopoverTourService } from "app/popover-tour.service";
declare let $: any;

@Component({
  selector: "app-tc-packets-sidebar",
  templateUrl: "./tc-packets-sidebar.component.html",
  styleUrls: ["./tc-packets-sidebar.component.css"],
})
export class TcPacketsSidebarComponent implements OnInit {
  @Input() tcPacketsConfiguration: TcPacketsConfiguration;

  tcArguments: ArgumentView[];

  displayList: ArgumentView[];
  packetsTCList: ArgumentView[];

  //tcPacketsConfiguration: TcPacketsConfiguration;
  packetSearch: string;
  edited: boolean;

  tcCheckedPackets: ArgumentView[];
  hideCheckedPackets = true;
  max10Packets = false;

  LimitPacketsMax: number;

  popoverTourService: PopoverTourService = PopoverTourService.Instance;

  constructor(
    private tmPacketListService: SendTcService,
    private sendTcService: SendTcService,
    private tcPacketsService: TcPacketsService
  ) {
    this.tcPacketsConfiguration = this.tcPacketsService.tcPacketsConfiguration;
    this.tcPacketsConfiguration.selectList.forEach(this.unselectAll);
    this.setDateInterval();
    this.setPaquetMax();
    this.tcPacketsConfiguration.nbPaquetsMax = this.LimitPacketsMax;

    this.tcPacketsConfiguration.realTime = true;

    console.log("------------   Initialisation   ------------");
    console.log("DateStart TC: " + this.tcPacketsConfiguration.dateStart);
    console.log("TimeStart : " + this.tcPacketsConfiguration.timeStart);
    console.log("DateEnd : " + this.tcPacketsConfiguration.dateEnd);
    console.log("TimeEnd : " + this.tcPacketsConfiguration.timeEnd);

    this.edited = false;
  }

  ngOnInit() {
    this.getPacketList();
    //        $('[data-toggle="tooltip"]').tooltip();
  }

  getPacketList() {
    this.sendTcService
      .getTcArguments()
      .subscribe((tcArguments) => this.initPacketlist(tcArguments));
    console.log("Test Liste TC");
  }

  initPacketlist(list: ArgumentView[]) {
    this.packetsTCList = list;
    //        for ( var i = 0; i < list.length; i++ ) {
    //            this.packetsTCList[i] = this.tcArguments[i];
    ////            this.packetsTCList[i] = this.tcArguments[i].packetName;
    //        }
    this.displayList = this.alphabeticalSort(this.packetsTCList);
    this.tcCheckedPackets = this.alphabeticalSort(this.packetsTCList);

    const heightSearch = 65;
    const heightElement = 34;
    if (
      this.displayList.length * heightElement + heightSearch >
      $("#paquet-sidebar").height() * 0.65
    ) {
      $("#collapsePaquet .block-menu-drop-down").css({
        height: $("#paquet-sidebar").height() * 0.65 + "px",
      });
    } else {
      $("#collapsePaquet .block-menu-drop-down").css({
        height: this.displayList.length * heightElement + heightSearch + "px",
      });
    }
    $("#scroll-liste-paquet").css({
      "max-height": $("#collapsePaquet").height() - 37 + "px",
    });
  }

  setDateInterval() {
    const dEnd = new Date();
    const dateEnd =
      ("0" + dEnd.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (dEnd.getUTCMonth() + 1)).slice(-2) +
      "/" +
      dEnd.getUTCFullYear();
    const timeEnd =
      ("0" + dEnd.getUTCHours()).slice(-2) +
      ":" +
      ("0" + dEnd.getUTCMinutes()).slice(-2) +
      ":" +
      ("0" + dEnd.getUTCSeconds()).slice(-2);
    const dStart = new Date(dEnd);
    dStart.setDate(dStart.getUTCDate() - 10);
    const dateStart =
      ("0" + dStart.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (dStart.getUTCMonth() + 1)).slice(-2) +
      "/" +
      dStart.getUTCFullYear();
    const timeStart = timeEnd;
    this.tcPacketsConfiguration.dateStart = dateStart;
    this.tcPacketsConfiguration.timeStart = timeStart;
    this.tcPacketsConfiguration.dateEnd = dateEnd;
    this.tcPacketsConfiguration.timeEnd = timeEnd;
  }

  setPaquetMax() {
    this.LimitPacketsMax = 100;
  }

  onChange() {
    console.log("------------ Change ------------");
    console.log("DateStart : " + this.tcPacketsConfiguration.dateStart);
    console.log("TimeStart : " + this.tcPacketsConfiguration.timeStart);
    console.log("DateEnd : " + this.tcPacketsConfiguration.dateEnd);
    console.log("TimeEnd : " + this.tcPacketsConfiguration.timeEnd);
    console.log("NbPaquetMax : " + this.LimitPacketsMax);
    this.tcPacketsConfiguration.nbPaquetsMax = this.LimitPacketsMax;
  }

  onChangeRealTime() {
    // Désactive la checkbox pendant l'animation
    $("#checkbox-temps-reelTC input").prop("disabled", true);
    setTimeout(function () {
      $("#checkbox-temps-reelTC input").prop("disabled", false);
    }, 400);
    if (!this.tcPacketsConfiguration.realTime) {
      this.setDateInterval();
      $("#collapseRealTimeTC").collapse("show");
    } else {
      $("#collapseRealTimeTC").collapse("hide");
    }
  }

  onChangeSearchPacket() {
    this.displayList = [];
    let find = false;
    for (const packet of this.packetsTCList) {
      if (packet.packetName != null) {
        if (
          packet.packetName
            .toUpperCase()
            .indexOf(this.packetSearch.toUpperCase()) > -1
        ) {
          this.displayList.push(packet);
          find = true;
        }
      }
      if (packet.packetDescription != null && !find) {
        if (
          packet.packetDescription
            .toUpperCase()
            .indexOf(this.packetSearch.toUpperCase()) > -1
        ) {
          this.displayList.push(packet);
          find = true;
        }
      }
      if (!find) {
        for (const subPacket of packet.argumentParameters) {
          if (this.isPacketParameter) {
            const argument = <PacketParameter>subPacket;
            if (argument.name != null) {
              if (
                argument.name
                  .toUpperCase()
                  .indexOf(this.packetSearch.toUpperCase()) > -1 &&
                !find
              ) {
                find = true;
                this.displayList.push(packet);
              }
            }
            if (argument.description != null) {
              if (
                argument.description
                  .toUpperCase()
                  .indexOf(this.packetSearch.toUpperCase()) > -1 &&
                !find
              ) {
                find = true;
                this.displayList.push(packet);
              }
            }
          }
        }
      }
      find = false;
    }
    if (this.packetSearch.length > 0) {
      this.edited = true;
    } else {
      this.edited = false;
    }
  }

  onChangeCheckedPackets(packetName: string) {
    this.tcPacketsConfiguration.selectList.set(
      packetName,
      !this.tcPacketsConfiguration.selectList.get(packetName)
    );
    let isCheck = false;

    let nbCheckedPacket = 0;

    for (const packet of this.tcCheckedPackets) {
      if (this.tcPacketsConfiguration.selectList.get(packet.packetName)) {
        this.hideCheckedPackets = false;
        isCheck = true;
        nbCheckedPacket++;
      }
    }

    // Ca ne sert que pour bloquer le nombre max de paquet selectionné quand >= 10
    if (nbCheckedPacket >= 10) {
      this.max10Packets = true;
    } else {
      this.max10Packets = false;
    }

    if (!isCheck) this.hideCheckedPackets = true;
  }

  unselectAll(valeur: boolean, packetName: string, map: Map<string, boolean>) {
    map.set(packetName, false);
  }

  isPacketParameter(abstractContainer: AbstractContainer): boolean {
    return isPacketParameter(abstractContainer);
  }

  clearSearch() {
    this.packetSearch = "";
    this.onChangeSearchPacket();
  }

  alphabeticalSort(list: ArgumentView[]): ArgumentView[] {
    return list.sort(function (a, b) {
      if (a.packetName < b.packetName) {
        return -1;
      } else if (a.packetName > b.packetName) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
