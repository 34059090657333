import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  Directive,
} from "@angular/core";
import { Synoptic } from "../../model/synoptic";
declare let $: any;

@Component({
  selector: "app-create-synoptic",
  templateUrl: "./create-synoptic.component.html",
  styleUrls: ["./create-synoptic.component.css"],
})
export class CreateSynopticComponent implements OnInit {
  constructor() {}

  newSynoptic: Synoptic = new Synoptic();
  toModify: Synoptic;

  @Input() isModify = false;

  @Input() set synopticToModify(toModify: Synoptic) {
    this.newSynoptic = Object.assign({}, toModify);
    this.toModify = toModify;
  }

  @Output()
  synopticToSend: EventEmitter<Synoptic> = new EventEmitter();

  ngOnInit() {
    // on remet à zéro le form si on clique sur le coté
    $("#creer-synoptique").on("hidden.bs.modal", () => {
      if (!this.isModify) {
        this.newSynoptic.title = "";
        this.newSynoptic.description = "";
      }
    });
  }

  addSynoptic() {
    this.synopticToSend.emit(this.newSynoptic);
    //clean form when we create a widget
    if (!this.isModify) {
      this.newSynoptic.title = "";
      this.newSynoptic.description = "";
    }
  }
}
