<div class="progress mt-2">
  <ng-container *ngIf="this.thresholds != undefined">
    <!-- seuils Severe -->
    <ng-container *ngIf="this.thresholds.severeRange != undefined && getValueMin(this.thresholds.severeRange) != null">
      <div class="bar-step" [style.left]="this.getPlaceMin(this.thresholds.severeRange)">
        <div class="label-line" [style.background]="this.getSevereColor()"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="this.thresholds.severeRange != undefined && getValueMax(this.thresholds.severeRange) != null">
      <div class="bar-step" [style.left]="this.getPlaceMax(this.thresholds.severeRange)">
        <div class="label-line" [style.background]="this.getSevereColor()"></div>
      </div>
    </ng-container>

    <!-- seuils critical -->
    <ng-container
      *ngIf="this.thresholds.criticalRange != undefined && getValueMin(this.thresholds.criticalRange) != null">
      <div class="bar-step" [style.left]="this.getPlaceMin(this.thresholds.criticalRange)">
        <div class="label-line" [style.background]="this.getCriticalColor()"></div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="this.thresholds.criticalRange != undefined && getValueMax(this.thresholds.criticalRange) != null">
      <div class="bar-step" [style.left]="this.getPlaceMax(this.thresholds.criticalRange)">
        <div class="label-line" [style.background]="this.getCriticalColor()"></div>
      </div>
    </ng-container>

    <!-- seuils distress -->
    <ng-container
      *ngIf="this.thresholds.distressRange != undefined && getValueMin(this.thresholds.distressRange) != null">
      <div class="bar-step" [style.left]="this.getPlaceMin(this.thresholds.distressRange)">
        <div class="label-line" [style.background]="this.getDistressColor()"></div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="this.thresholds.distressRange != undefined && getValueMax(this.thresholds.distressRange) != null">
      <div class="bar-step" [style.left]="this.getPlaceMax(this.thresholds.distressRange)">
        <div class="label-line" [style.background]="this.getDistressColor()"></div>
      </div>
    </ng-container>

    <!-- seuils warning -->
    <ng-container
      *ngIf="this.thresholds.warningRange != undefined && getValueMin(this.thresholds.warningRange) != null">
      <div class="bar-step" [style.left]="this.getPlaceMin(this.thresholds.warningRange)">
        <div class="label-line" [style.background]="this.getWarningColor()"></div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="this.thresholds.warningRange != undefined && getValueMax(this.thresholds.warningRange) != null">
      <div class="bar-step" [style.left]="this.getPlaceMax(this.thresholds.warningRange)">
        <div class="label-line" [style.background]="this.getWarningColor()"></div>
      </div>
    </ng-container>

    <!-- seuils watch -->
    <ng-container *ngIf="this.thresholds.watchRange != undefined && getValueMin(this.thresholds.watchRange) != null">
      <div class="bar-step" [style.left]="this.getPlaceMin(this.thresholds.watchRange)">
        <div class="label-line" [style.background]="this.getWatchColor()"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="this.thresholds.watchRange != undefined && getValueMax(this.thresholds.watchRange) != null">
      <div class="bar-step" [style.left]="this.getPlaceMax(this.thresholds.watchRange)">
        <div class="label-line" [style.background]="this.getWatchColor()"></div>
      </div>
    </ng-container>
  </ng-container>

  <div class="progress-bar" role="progressbar" [style.width]="this.getValue()" [style.background-color]="getColor()"
    aria-valuemin="0" aria-valuemax="100"></div>
  <span class="valueGauge">
    <app-single-value [datedParameters]="this.datedParameters" [index]="this.index" [obsolescence]="this.obsolescence" [orderBy]="this.orderBy" 
    [widgetId]="this.widgetId" [isPercentage]="true"></app-single-value>
  </span>
</div>
<div class="text-xs-center text-truncate mt-2" style="text-align: center" id="jaugeLegend">
  <span *ngIf="this.datedParameters[0]" class="parameterName">
    <div class="color-legend border-severe" [style.background]="getBackgoundLegendColor()"
      [style.border-color]="getColor()"></div>
    <span class="legendName"> {{getName()}} </span>
    <span *ngIf="this.isObsolete()" class="obsolescenceInfo"> ! </span>
  </span>
</div>