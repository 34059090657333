import { Directive } from "@angular/core";
import {
  FormControl,
  ValidatorFn,
  AbstractControl,
  Validators,
} from "@angular/forms";
import { Calibration } from "../model/spacepacket";

/**
 *
 * @param calibration
 * @param max
 */
export function validateMaxHexFactory(
  calibration: Calibration,
  max: number
): ValidatorFn {
  return (c: FormControl) => {
    if (max !== undefined && max != 0) {
      const valueToCompare = convertHexStringToInt(c.value, 16);

      return valueToCompare <= max
        ? null
        : {
            validateMaxHex: {
              valid: false,
            },
          };
    } else {
      return null;
    }
  };
}

@Directive({
  selector: "[validateMaxHex]",
})
export class MaxHexValidatorDirective {
  private valFn = Validators.nullValidator;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.valFn(control);
  }
}

/**
 *
 * @param x
 * @param base
 */
function convertHexStringToInt(x, base): number {
  const parsed = parseInt(x, base);
  if (isNaN(parsed)) {
    return 0;
  }
  return parsed;
}
