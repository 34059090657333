<div id="historic-tc" class="card card-grey">
  <div class="card-header card-grey" id="help-tc-paquet-historic" data-container="body" data-html="true"
    data-placement="bottom" data-trigger="manual" data-title="Historique des télécommandes"
    data-content="Affiche l'historique des TC envoyées.
					<br>- Possibilité de trier la liste à l'aide du menu de gauche.
					<br>- Vous pouvez cliquer sur la TC pour afficher plus d'informations.
					<br>- Les télécommandes envoyées ont plusieurs états possibles :<br>
					<br>- <b>Acceptation</b> de la TC à bord :
					<br><div class='row' style='padding-left: 14px;'><svg width='21' height='20'><circle	cx='11' cy='10' r='8' fill='transparent' stroke='#cfd2da' stroke-width='3'/></svg> Attente
					<svg width='21'	height='20'><circle	cx='11' cy='10' r='8' fill='transparent' stroke='#1bc98e' stroke-width='3'/></svg> Succès
					<svg width='21'	height='20'><circle	cx='11' cy='10' r='8' fill='transparent' stroke='#e64759' stroke-width='3'/></svg> Echec</div>
					
					<br>- <b>Début de l'exécution</b> de la TC à bord : 
					<br><div class='row' style='padding-left: 14px;'><svg width='21' height='20'><circle	cx='11' cy='10' r='8' fill='transparent' stroke='#1bc98e' stroke-width='3'/><circle cx='12' cy='10' r='8' fill='#1bc98e' clip-path='url(#cut-off-quarter)' />  </svg> Succès
					<svg width='21'	height='20'><circle	cx='11' cy='10' r='8' fill='transparent' stroke='#e64759' stroke-width='3'/><circle cx='12' cy='10' r='8' fill='#e64759' clip-path='url(#cut-off-quarter)' />  </svg> Echec</div>
					
					<br>- <b>Progression de l'exécution</b> de la TC à bord : 
					<br><div class='row' style='padding-left: 14px;'><svg width='21' height='20'><circle	cx='11' cy='10' r='8' fill='transparent' stroke='#1bc98e' stroke-width='3'/><circle cx='12' cy='10' r='8' fill='#1bc98e'  clip-path='url(#cut-off-buttom)' /><circle cx='12' cy='10' r='8' fill='#1bc98e' clip-path='url(#cut-off-quarter)' />  </svg> Succès
					<svg width='21'	height='20'><circle	cx='11' cy='10' r='8' fill='transparent' stroke='#e64759' stroke-width='3'/><circle cx='12' cy='10' r='8' fill='#e64759'  clip-path='url(#cut-off-buttom)' /><circle cx='12' cy='10' r='8' fill='#e64759' clip-path='url(#cut-off-quarter)' />  </svg> Echec</div>
					
					<br>- <b>Achèvement de l'execution</b> de la TC à bord : 
					<div class='row' style='padding-left: 14px;'><svg width='21' height='20'><circle	cx='11' cy='10' r='8' fill='transparent' stroke='#1bc98e' stroke-width='3'/><circle cx='11' cy='10' r='7' fill='#1bc98e' /></svg>Succès
					<svg width='21'	height='20'><circle	cx='11' cy='10' r='8' fill='transparent' stroke='#e64759' stroke-width='3'/><circle cx='11' cy='10' r='7' fill='#e64759' /></svg>Echec</div>">
    <h6><strong>Historique des télécommandes</strong></h6>
    <a data-toggle="modal" class="icon icon-download pointer-hover" data-target="#downloadTc"></a>
  </div>
  <div id="scroll-history-packet-tc-list" class="card-block scroll-y scrollbar-style">
    <table #table class="table table-sm" data-sort="table">
      <thead>
        <tr>
          <th></th>
          <th>Nom</th>
          <th>Date d'envoi</th>
          <th id="raw-packet-copie-col"></th>
        </tr>
      </thead>
      <tbody>

        <ng-container *ngFor="let packet of tcpackets; let indexOfelement = index">
          <tr class="pointer-hover" (click)="onSelect(packet.id)" (mouseover)="onHoverIn(packet.id)"
            (mouseout)="onHoverOut(packet.id)">
            <td scope="row"><svg width="21" height="20">
                <clipPath id="cut-off-buttom">
                  <rect x="0" y="10" width="20" height="10" />
                </clipPath>
                <clipPath id="cut-off-quarter">
                  <rect x="11" y="0" width="10" height="10" />
                </clipPath>

                <circle *ngIf="lastStateTc(packet) === 'completed'" cx="11" cy="10" r="7"
                  [attr.fill]=getColorSatus(packet) />

                <circle *ngIf="lastStateTc(packet) === 'completed' || lastStateTc(packet) === 'progress'" cx="12" cy="10"
                  r="8" [attr.fill]=getColorSatus(packet) clip-path="url(#cut-off-buttom)" />

                <circle
                  *ngIf="lastStateTc(packet) === 'completed' || lastStateTc(packet) === 'progress' || lastStateTc(packet) === 'started'"
                  cx="12" cy="10" r="8" [attr.fill]=getColorSatus(packet) clip-path="url(#cut-off-quarter)" />

                <circle
                  *ngIf="lastStateTc(packet) === 'completed' || lastStateTc(packet) === 'progress' || lastStateTc(packet) === 'started' || lastStateTc(packet) === 'acceptance'"
                  cx="11" cy="10" r="8" fill="transparent" [attr.stroke]=getColorSatus(packet) stroke-width="3" />

                <circle *ngIf="lastStateTc(packet) === ''" cx="11" cy="10" r="8" fill="transparent"
                  [attr.stroke]=getColorSatus(packet) stroke-width="3" />
                  
                <!-- 							<path d ="M0,8 A 8 8 240 0 1 8,16" fill ="blue" stroke ="red" stroke-width ="4" /> -->
              </svg>

              <span *ngIf="isBugFligthSoft(packet)">Bug</span>
            </td>
            <td>{{packet.packetType}}</td>
            <td>{{formatDate(packet.generationTime)}}</td>
            <td>

              <button type="button" class="btn btn-xs btn-secondary raw-packet-copie-button hidden"
                id="rawPacketCopie-button{{packet.id}}" (click)="onCopieHexPacket($event, packet.rawPacket)"
                data-toggle="tooltip" data-placement="left" data-html="true"
                title="Taille: {{getLengthPacket(packet.rawPacket)}} octets<br>Valeur de length: {{getLengthPacket(packet.rawPacket)-7}} octets<br>{{packet.rawPacket}}"
                data-delay='{"show":"300", "hide":"0"}' data-trigger="hover">
                <span>Copier HEX</span>
              </button>
            </td>
          </tr>
          <tr id="hiddenLine">
            <td colspan="6" id="hiddenRow">
              <div class="collapse" id=hiddenRow{{packet.id}}>
                <table #table class="table table-sm" data-sort="table">
                  <tbody>
                    <tr *ngIf="packet.acceptance != null">
                      <td *ngIf="packet.acceptance.ack === 'SUCCESS'"><span
                          class="icon icon-controller-record nominal"></span></td>
                      <td *ngIf="packet.acceptance.ack === 'FAILURE'"><span
                          class="icon icon-controller-record severe"></span></td>
                      <td>Acquittement :</td>
                      <td>{{formatDate(packet.acceptance.time)}}</td>
                    </tr>
                    <tr *ngIf="packet.started != null">
                      <td *ngIf="packet.started.ack === 'SUCCESS'"><span
                          class="icon icon-controller-record nominal"></span></td>
                      <td *ngIf="packet.started.ack === 'FAILURE'"><span
                          class="icon icon-controller-record severe"></span></td>
                      <td>Début de l'exécution :</td>
                      <td>{{formatDate(packet.started.time)}}</td>
                    </tr>
                    <tr *ngIf="packet.progress != null">
                      <td *ngIf="packet.progress.ack === 'SUCCESS'"><span
                          class="icon icon-controller-record nominal"></span></td>
                      <td *ngIf="packet.progress.ack === 'FAILURE'"><span
                          class="icon icon-controller-record severe"></span></td>
                      <td>Progression :</td>
                      <td>{{formatDate(packet.progress.time)}}</td>
                    </tr>
                    <tr *ngIf="packet.completed != null">
                      <td *ngIf="packet.completed.ack === 'SUCCESS'"><span
                          class="icon icon-controller-record nominal"></span></td>
                      <td *ngIf="packet.completed.ack === 'FAILURE'"><span
                          class="icon icon-controller-record severe"></span></td>
                      <td>Fin de l'execution :</td>
                      <td>{{formatDate(packet.completed.time)}}</td>
                    </tr>
                    <tr *ngIf="getNbArguments(packet) > 0">
                      <td><span class="icon icon-controller-record nominal-grey"></span></td>
                      <td>Liste des arguments :</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <table *ngIf="getNbArguments(packet) > 0" #table class="table table-sm">
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Valeur Physique</th>
                      <th>Raw Value</th>
                      <th>Ptc</th>
                      <th>Pfc</th>
                      <th>Binary Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let abstractParameter of getArguments(packet.spacePacket.rootContainer)">
                      <ng-container *ngIf="isParameter(abstractParameter)">
                        <td>{{abstractParameter.name}}</td>
                        <td>
                          <ng-container
                            *ngIf="abstractParameter.physicalValue.valueType === 'AbsoluteTime'; then thenBlock; else elseBlock">
                          </ng-container>
                          <ng-template #thenBlock> <span class="font-weight-bold">
                              {{formatDate(abstractParameter.physicalValue.value)}} </span> </ng-template>
                          <ng-template #elseBlock> <span
                              class="font-weight-bold">{{abstractParameter.physicalValue.value}}</span></ng-template>
                          <ng-container *ngIf="abstractParameter.unit"> <span
                              class="font-weight-bold">{{abstractParameter.unit.unit}}</span></ng-container>
                        </td>
                        <td *ngIf="rawValue">
                          <ng-container
                            *ngIf="abstractParameter.rawValue.valueType === 'AbsoluteTime'; then thenBlock; else elseBlock">
                          </ng-container>
                          <ng-template #thenBlock>
                            {{formatDate(abstractParameter.rawValue.value)}} </ng-template>
                          <ng-template #elseBlock> {{abstractParameter.rawValue.value}}</ng-template>
                        </td>
                        <td>{{abstractParameter.rawValue.value}}</td>
                        <td>{{abstractParameter.ptc}}</td>
                        <td>{{abstractParameter.pfc}}</td>
                        <td>{{abstractParameter.rawData}}</td>
                      </ng-container>
                      <ng-container *ngIf="isPacketParameter(abstractParameter)">
                        <td>{{abstractParameter.name}}</td>
                        <td>
                          <span class="font-weight-bold"></span>
                        </td>
                        <td >
                        </td>
                        <td>12</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="this.popoverTourService.SequenceIndex == 201 && indexOfelement == 7" class="popover bs-tether-element-attached-top help-tour-popover popover-tc-search" role="tooltip">
								<h3 class="popover-title">
									Historique des télécommandes
									<button (click)="this.popoverTourService.quitSequence()" type="button" class="close popover-close" aria-label="Close">
										<span aria-hidden="true" >&times;</span>
									</button>
								</h3>
								<div class="popover-content">
									Cliquer sur la télécommande afin d'afficher son status d'exécution et ses arguments s'il y en a.
								</div>
								<div class="popover-footer">
									<button (click)="this.popoverTourService.previousSequence()" type="button" class="btn btn-default add-on-button mr-4">Précédent</button>
									<button	(click)="this.popoverTourService.nextSequence()" type="button" class="btn btn-primary">Suivant</button>
								</div>
							</div>
            </td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</div>


<!-- 			Modal for TC download			 -->

<div class="modal fade" id="downloadTc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="semi-transparent modal-title">Download Tc</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <hr>
          <div *ngIf="this.tcPacketsConfiguration.realTime; else elseBlock">
            Temps réel
          </div>
          <div>
            <ng-template #elseBlock>
              du {{ this.tcPacketsConfiguration.dateStart }} {{ this.tcPacketsConfiguration.timeStart }}
              <br>
              au {{ this.tcPacketsConfiguration.dateEnd }} {{ this.tcPacketsConfiguration.timeEnd }}
            </ng-template>
          </div>
          <br>
          {{getMessageNbPacket()}}
          <hr>
        </div>

        <form name="form" (ngSubmit)="getUrlForPacketExport()" ngNativeValidate>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label mr-auto" for="nameTc">TC</label>
              <div class="col-sm-10">
                <input list="tcs" class="form-control" name="namePacketToDownload"
                  [(ngModel)]="this.namePacketToDownload" required />
                <datalist class="modal-dialog" id="tcs">
                  <div *ngFor="let packet of packetsTCList">
                    <option value={{packet.packetName}}>
                  </div>
                </datalist>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 col-form-label mr-auto" for="maxLimit">Nombre de paquets</label>
              <div class="col-sm-7">
                <input type="number" class="form-control" placeholder="" name="maxLimitDownload" id="maxLimit" min="-1"
                  [(ngModel)]="this.maxLimitDownload" required />
                <span class="text-muted">-1 pour une infinité de valeurs</span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 col-form-label mr-auto" for="format">Format</label>
              <div class="col-sm-7">
                <select class="form-control" id="format" name="format" [(ngModel)]="this.format" required>
                  <option value="csv">CSV</option>
                  <option value="tsv">TSV</option>
                </select>
              </div>
            </div>
            <hr>
          </div>
          <div class="modal-actions">
            <button type="button" class="btn btn-sm btn-pill btn-danger" data-dismiss="modal">Annuler</button>
            <button type="submit" class="btn btn-sm btn-pill btn-success" value="Submit">
              <strong>Charger</strong>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
