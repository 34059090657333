import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { ParameterWidget } from "./model/synoptic";
declare let $: any;

@Injectable()
export class ParameterWidgetService {
  private parameterWidgetUrl = "api/parameter-widget";

  private handleError: HandleError;

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.parameterWidgetUrl =
        "http://localhost:15000/" + this.parameterWidgetUrl;
    }
    this.handleError = httpErrorHandler.createHandleError(
      "ParameterWidgetService"
    );
  }

  getWidgets(synopticId: number): Observable<ParameterWidget[]> {
    return this.http
      .get<ParameterWidget[]>(this.parameterWidgetUrl + "?id=" + synopticId)
      .pipe(catchError(this.handleError("getWidgets", [])));
  }

  addWidget(parameterWidget: ParameterWidget): Observable<ParameterWidget> {
    //       On utilise un aprameter widget vide pour récupérer juste l'id

    // console.log(JSON.stringify(parameterWidget))
    return this.http
      .put<ParameterWidget>(
        this.parameterWidgetUrl,
        parameterWidget,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError<ParameterWidget>("addWidget", parameterWidget)
        )
      );
  }

  updateWidget(parameterWidget: ParameterWidget): Observable<ParameterWidget> {
    // save result
    return this.http
      .post<ParameterWidget>(
        this.parameterWidgetUrl,
        parameterWidget,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError<ParameterWidget>("updateWidget", parameterWidget)
        )
      );
  }

  deleteWidget(parameterWidget: ParameterWidget) {
    return this.http
      .delete(
        this.parameterWidgetUrl + "?id=" + parameterWidget.parameterWidgetId,
        this.httpOptions
      )
      .pipe(catchError(this.handleError("deleteWidget", [])));
  }
}
