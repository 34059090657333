import { Directive, forwardRef, Input } from "@angular/core";
import {
  NG_VALIDATORS,
  FormControl,
  ValidatorFn,
  AbstractControl,
  Validators,
} from "@angular/forms";
import {
  Calibration,
  PolynomialCalibration,
  isPolynomialCalibration,
} from "../model/spacepacket";

/**
 *
 * @param calibration
 * @param max
 */
export function validateMaxFactory(
  calibration: Calibration,
  max: number
): ValidatorFn {
  return (c: FormControl) => {
    let valueToCompare = 0;
    if (isPolynomialCalibration(calibration)) {
      for (const coefficient of (<PolynomialCalibration>calibration)
        .coefficients) {
        valueToCompare += Math.pow(
          coefficient.factor * c.value,
          coefficient.exponent
        );
      }
    } else {
      valueToCompare = c.value;
    }
    //        //Calcul de la valeur après calibration
    //        for ( let coefficient of (<PolynomialCalibration>calibration).coefficients ) {
    //            valueAfterCalibration += Math.pow(coefficient.factor * c.value, coefficient.exponent);
    //        }
    //        console.log(valueAfterCalibration);

    return valueToCompare <= max
      ? null
      : {
          validateMax: {
            valid: false,
          },
        };
  };
}

@Directive({
  selector: "[validateMax]",
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true },
  ],
})
export class MaxValidatorDirective {
  private valFn = Validators.nullValidator;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.valFn(control);
  }
}
