<ng-container *ngFor="let container of packetContainer.subContainers; let i = index">

<!-- 	    Containers       --> 
<a *ngIf="isPacketContainer(container)"
	(click)="onSelect(container)" href="#{{container.name + i}}"
	class="list-group-item list-group-item-action branch collapsed  {{returnCriticalityClass(container.status)}}"
	data-toggle="collapse" aria-expanded="false"> {{container.name}} </a>

	<div *ngIf="isPacketContainer(container) && this.popoverTourService.SequenceIndex == 103 && i == 1" class="popover bs-tether-element-attached-top help-tour-popover" role="tooltip">
		<h3 class="popover-title">
			Explorateur de paquet
			<button (click)="this.popoverTourService.quitSequence()" type="button" class="close popover-close" aria-label="Close">
				<span aria-hidden="true" >&times;</span>
			</button>
		</h3>
		<div class="popover-content">
			Explorer la structure du paquet TM sélectionné :<br />
			- Cliquer sur le conteneur dans la structure du paquet afin d'explorer son contenu.<br />
			- En cas d'anomalie détectée par les surveillances sol, sur un paramètre du conteneur, le nom du conteneur apparait en rouge.<br />
		</div>
		<div class="popover-footer">
			<button (click)="this.popoverTourService.previousSequence()" type="button" class="btn btn-default add-on-button mr-4">Précédent</button>
			<button	(click)="this.popoverTourService.nextSequence()" type="button" class="btn btn-primary">Suivant</button>
		</div>
	</div>
	
	
<!-- 	    Paramètres       -->
<a *ngIf="isParameter(container)" (click)="onSelect(container)"
	href="#{{container.name + i}}"
	class="list-group-item list-group-item-action leaf {{returnCriticalityClass(container.status)}}">{{container.name}}
</a>

<div *ngIf="isPacketContainer(container)"
	class="collapse collapse-container" id="{{container.name + i}}">
	<div class="list-group">
		<app-tm-packets-explorer class="anchor-left-padding"
			[packetContainer]="container"
			(selectedContainerChange)="selectedContainerCall($event)"></app-tm-packets-explorer>
	</div>
</div>
</ng-container>