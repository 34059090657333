import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Directive,
} from "@angular/core";
import { Synoptic, ParameterWidget } from "../model/synoptic";
import { ParameterWidgetService } from "../parameter-widget.service";
import { SynopticService } from "../synoptic.service";
import { SynopticConfiguration } from "app/model/synoptic-configuration";
import { ParametersService } from "app/parameters.service";
import { SpacePacket } from "app/model/spacepacket";
import { TmPacketListService } from "app/tm-packet-list.service";
import { Subscription } from "rxjs";
import { TmParamFromXtceService } from "../tm-param-from-xtce.service";
import { PopoverTourService } from "app/popover-tour.service";
declare let $: any;

@Component({
  selector: "app-synoptic",
  templateUrl: "./synoptic.component.html",
  styleUrls: ["./synoptic.component.css"],
})
export class SynopticComponent implements OnInit {
  synopticConfiguration: SynopticConfiguration;

  selected: Synoptic;

  synopticList: Synoptic[] = [];

  baseChart;

  synopticWidgets: ParameterWidget[];

  items = [];

  launchGridStack = false;

  updateChartStringFromFront = false;

  updateGridStack = false;

  updateSingleWidget = false;

  widgetToUpdateIndex: number;

  resetAllGrid = false;

  firstDone = false;

  packetsList: SpacePacket[];

  subscription: Subscription;

  popoverTourService: PopoverTourService = PopoverTourService.Instance;

  @Output()
  resetDataToWidgets: EventEmitter<any> = new EventEmitter();

  constructor(
    private parameterWidgetService: ParameterWidgetService,
    private synopticService: SynopticService,
    private parametersService: ParametersService,
    private tmParamFromXtceService: TmParamFromXtceService,
    private tmPacketListService: TmPacketListService
  ) {
    this.synopticConfiguration = this.parametersService.synopticConfiguration;
    this.synopticConfiguration.orderBy = "onBoardTime";
  }

  ngOnInit() {
    this.popoverTourService.sequenceIndex = 300;

    this.tmPacketListService
      .getTmPacketList()
      .subscribe((tmList) => this.initPacketlist(tmList));
  }

  initPacketlist(list: SpacePacket[]) {
    this.packetsList = list;
  }

  ngOnDestroy() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

  // --------------------- Fonctions pour les couleurs des cards
  public getWidgetHeaderClass(): string {
    return "header-synoptic-item-severe";
  }

  // --------------------- Fonctions Event from SIdeBar

  selectedSynopticChange(selectedSynoptic: Synoptic) {
    this.selected = selectedSynoptic;

    // update widgets
    if (selectedSynoptic != null) {
      this.subscription = this.parameterWidgetService
        .getWidgets(this.selected.synopticId)
        .subscribe((x) => this.updateWidgets(x));
    }
  }

  updateWidgets(widgets: ParameterWidget[]) {
    // First we remove all widgets
    if (this.firstDone) {
      const grid = $(".grid-stack").data("gridstack");
      if (grid != undefined) {
        grid.destroy(false);
      }
    }

    this.synopticWidgets = widgets;
    this.items = [];

    if (!this.firstDone) {
      this.launchGridStack = true;
      this.firstDone = true;
    } else {
      this.launchGridStack = true;
    }
  }

  resetAllGridStack() {
    this.setGridStackFromCHartString();

    const grid = $(".grid-stack").data("gridstack");

    const elements = $("div.grid-stack-item");
    const length = elements.length;
    for (let i = 0; i < length; i++) {
      grid.makeWidget("div.grid-stack-item:nth-child(" + i + ")");
    }
  }

  updateSynoptic() {
    this.selected.chartString = JSON.stringify(this.items);

    this.synopticService.updateSynoptic(this.selected).subscribe();
  }

  deleteWidget(widget: ParameterWidget, index: number) {
    // On supprime le widget de gridstack et du front
    this.synopticWidgets.splice(index, 1);

    this.items.splice(index, 1);

    // Fonction en plus pour cacher le modal, si on ne met pas ça le fond reste noir
    $("#delete-widget-" + index).modal("hide");

    this.selected.chartString = JSON.stringify(this.items);
    this.updateChartStringFromFront = true;
    //        this.updateGridStack = true;

    // On supprime le widget du back-end
    this.parameterWidgetService.deleteWidget(widget).subscribe();
  }

  newWidget(widget: ParameterWidget) {
    this.synopticWidgets.push(widget);

    this.items.push({
      x: 0,
      y: this.getMaxHeigth(),
      width: 5,
      height: 10,
    });

    this.selected.chartString = JSON.stringify(this.items);
    this.updateChartStringFromFront = true;
    this.updateGridStack = true;
  }

  updatedWidget(widget: ParameterWidget, index: number) {
    this.parameterWidgetService
      .getWidgets(this.selected.synopticId)
      .subscribe((x) => this.singleWidgetReload(x, index));
  }

  singleWidgetReload(widgets: ParameterWidget[], index: number) {
    this.widgetToUpdateIndex = index;
    // Ici il faut supprimer le widget de gridstack dès maintenant pour que gridstack soit bien au courant avant qu'on change le DOM
    // Si on ne fait pas ça alors : au moment de  faire makeWidget pour réactiver le widget après l'avoir updaté il y aura un problème
    // avec un décalage des widgets vers le bas
    const grid = $(".grid-stack").data("gridstack");
    // On met le dernier argument à false pour que gridstack laisse le html dans le DOM sans toucher au code mais le supprime de son algo,
    grid.removeWidget(
      "div.grid-stack-item:nth-child(" + (this.widgetToUpdateIndex + 1) + ")",
      false
    );
    this.synopticWidgets[index] = widgets[index];
    this.updateGridStack = true;
    this.updateSingleWidget = true;
  }

  private getMaxHeigth(): number {
    const elements = $("div.grid-stack-item");
    if (elements != undefined && elements.length != 0) {
      let max = 0;
      for (const e of elements) {
        if (
          +e.getAttribute("data-gs-y") + +e.getAttribute("data-gs-height") >
          max
        ) {
          max =
            +e.getAttribute("data-gs-y") + +e.getAttribute("data-gs-height");
        }
      }
      return max;
    } else {
      return 0;
    }
  }

  receivedWidgetsFromBackEnd(widgets: ParameterWidget[]) {
    this.synopticWidgets = widgets;
  }

  updateChartStringFromFrontEnd() {
    this.items = [];

    const elements = $("div.grid-stack-item");
    for (const e of elements) {
      this.items.push({
        x: e.getAttribute("data-gs-x"),
        y: e.getAttribute("data-gs-y"),
        width: e.getAttribute("data-gs-width"),
        height: e.getAttribute("data-gs-height"),
      });
    }

    this.updateSynoptic();
  }

  setGridStackFromCHartString() {
    const elements = $("div.grid-stack-item");

    // On reinitialise le tableau
    this.items = JSON.parse(this.selected.chartString) as Array<string>;

    if (this.items != null) {
      const length = elements.length;
      for (let i = 0; i < length; i++) {
        elements[i].setAttribute("data-gs-x", this.items[i].x);
        elements[i].setAttribute("data-gs-y", this.items[i].y);
        elements[i].setAttribute("data-gs-width", this.items[i].width);
        elements[i].setAttribute("data-gs-height", this.items[i].height);
      }
    }
  }

  ngAfterViewChecked() {
    // On a besoin de cette variable pour ne pas refaire un update de gridstack lors de la création du premier widget
    // si on vient juste de l'initialiser
    let firstTimeLaunchGridStack = false;
    if (this.launchGridStack) {
      //            console.log( "Mise à our GridStack" );
      if (
        this.synopticWidgets !== undefined &&
        this.synopticWidgets.length > 0
      ) {
        // TODO il faut trouver un moyen de couper grid stack et de relancer bon lien :
        //                console.log( "on rentre et on trace gridstack" );
        const options = {
          cellHeight: 10,
          verticalMargin: 20,
          horizontalMargin: 0,
          height: 0,
        };
        this.setGridStackFromCHartString();
        $(".grid-stack").gridstack(options);

        $(".grid-stack").on("dragstop resizestop", (e) => {
          this.updateChartStringFromFront = true;
        });

        $(".grid-stack-item").draggable({ cancel: "div.card-block" });

        $('[data-toggle="popover"]').popover({ html: true });

        this.launchGridStack = false;
        firstTimeLaunchGridStack = true;
      }
    }
    if (this.updateGridStack) {
      // On update grid stack avec le ouveau élément
      // Update grid stack element
      this.setGridStackFromCHartString();

      const grid = $(".grid-stack").data("gridstack");

      // Il ne faut pas faire le makeWidget pour le premier widget, car le widget est considéré comme déjà créé et donc il l'identifie
      // comme un double widget ce qui fait des bugs,à voir pk, mais pour la suite il faut
      if (
        (this.items.length != 1 || !firstTimeLaunchGridStack) &&
        !this.updateSingleWidget
      ) {
        grid.makeWidget(
          "div.grid-stack-item:nth-child(" + this.items.length + ")"
        );
        // On est obligé d'ajouter cette méthode jquery pour désactiver le drag and drop gridstack sur le contenu du  (uniquement le header)
        $(
          "div.grid-stack-item:nth-child(" + this.items.length + ") .card-block"
        ).mousedown(function (e) {
          e.stopPropagation();
        });
      }

      if (this.updateSingleWidget) {
        // On fait + 1 parce que l'index comment à 1 dans le dom pour gridstack
        grid.makeWidget(
          "div.grid-stack-item:nth-child(" +
            (this.widgetToUpdateIndex + 1) +
            ")"
        );
        // On est obligé d'ajouter cette méthode jquery pour désactiver le drag and drop gridstack sur le contenu du  (uniquement le header)
        $(
          "div.grid-stack-item:nth-child(" +
            (this.widgetToUpdateIndex + 1) +
            ") .card-block"
        ).mousedown(function (e) {
          e.stopPropagation();
        });
      }

      this.updateSingleWidget = false;
      this.widgetToUpdateIndex = null;

      // Il y a un bug avec makeWidget donc il faut recharger la bonne position de la Balise
      //            this.setGridStackFromCHartString();

      this.updateGridStack = false;
    }
    if (this.resetAllGrid) {
      this.resetAllGridStack();

      this.resetAllGrid = false;
    }
    if (this.updateChartStringFromFront) {
      //            console.log( 'Mise à jour Chart String dans la BDD' );

      this.updateChartStringFromFrontEnd();

      this.updateChartStringFromFront = false;
    }
  }

  // ------------------------------- Pour le modal zoom

  public isShownModal(index: number): boolean {
    return $("#zoom-widget-" + index).hasClass("show");
  }

  modifySynoptic(newSynoptic: Synoptic) {
    $("#modif-synoptic-" + newSynoptic.synopticId).modal("hide");

    this.selected.title = newSynoptic.title;
    this.selected.description = newSynoptic.description;

    this.synopticService
      .updateSynoptic(newSynoptic)
      .subscribe((x) => newSynoptic);
  }
}
