import { ChangeDetectorRef, Component, OnInit, Directive } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { PopoverTourService } from "app/popover-tour.service";
import { interval, Subscription } from "rxjs";
import { ArgumentView } from "../model/argumentview";
import {
  AbstractContainer,
  DatedTcAck,
  isPacketParameter,
  PacketParameter,
  TcPacket,
  properWayForIntervalSubscription,
} from "../model/spacepacket";
import { TcPacketsConfiguration } from "../model/tc-packets-configuration";
import { SendTcService } from "../send-tc.service";
import { TcPacketsService } from "../tc-packets.service";

declare let $: any;

@Component({
  selector: "app-send-tc",
  templateUrl: "./send-tc.component.html",
  styleUrls: ["./send-tc.component.css"],
})
export class SendTcComponent implements OnInit {
  tcpackets: TcPacket[] = new Array<TcPacket>();
  tcPacketsConfiguration: TcPacketsConfiguration;
  initTableSort = false;

  tcArguments: ArgumentView[];
  displayList: ArgumentView[];
  packetsTCList: ArgumentView[];

  selectArgumentView: ArgumentView;
  edited: boolean;
  packetSearch: string;

  hideFixedArgument = false;

  form: FormGroup;

  subscription: Subscription;

  previousCallRunning = false;

  popoverTourService: PopoverTourService = PopoverTourService.Instance;

  constructor(
    private router: Router,
    private sendTcService: SendTcService,
    private tcPacketsService: TcPacketsService,
    private cdRef: ChangeDetectorRef
  ) {
    this.tcPacketsConfiguration = this.tcPacketsService.tcPacketsConfiguration;
    this.edited = false;
  }

  ngOnInit() {
    this.popoverTourService.sequenceIndex = 200;

    this.subscription = properWayForIntervalSubscription(
      600,
      "getTcPackets",
      this
    );

    this.getTcPackets();

    this.getArgumentViews();
    //Le dropdown menu fait la largeur du champs de recherche
    $("#dropdown-list-tc").css({
      width: $("#searchbarTc").width() + "px",
    });
    $('[data-toggle="tooltip"]').tooltip();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getArgumentViews() {
    this.sendTcService
      .getTcArguments()
      .subscribe((tcArguments) => this.initPacketlist(tcArguments));
  }

  onSubmit() {
    this.sendTcService.sendTc(this.selectArgumentView).subscribe();
    this.scrollTop();
  }

  initPacketlist(list: ArgumentView[]) {
    this.displayList = this.alphabeticalSort(list);
    this.packetsTCList = list;
  }

  //Met a jour la liste de TC a afficher
  onChangeSearchPacket() {
    this.displayList = [];
    let find = false;
    for (const packet of this.packetsTCList) {
      if (
        packet.packetName
          .toUpperCase()
          .indexOf(this.packetSearch.toUpperCase()) > -1
      ) {
        this.displayList.push(packet);
        find = true;
      }
      if (packet.packetDescription != null && !find) {
        if (
          packet.packetDescription
            .toUpperCase()
            .indexOf(this.packetSearch.toUpperCase()) > -1
        ) {
          this.displayList.push(packet);
          find = true;
        }
      }
      if (!find) {
        for (const subPacket of packet.argumentParameters) {
          if (this.isPacketParameter) {
            const packetSearch = <PacketParameter>subPacket;
            if (packetSearch.name != null && !find) {
              if (
                packetSearch.name
                  .toUpperCase()
                  .indexOf(this.packetSearch.toUpperCase()) > -1
              ) {
                find = true;
                this.displayList.push(packet);
              }
            }
            if (packetSearch.description != null && !find) {
              if (
                packetSearch.description
                  .toUpperCase()
                  .indexOf(this.packetSearch.toUpperCase()) > -1
              ) {
                find = true;
                this.displayList.push(packet);
              }
            }
          }
        }
      }
      find = false;
    }
    if (this.packetSearch.length > 0) {
      this.edited = true;
    } else {
      this.edited = false;
    }
  }

  clearSearch() {
    this.packetSearch = "";
    this.onChangeSearchPacket();
  }

  setTC(argument: ArgumentView) {
    if (this.selectArgumentView != argument) {
      this.form = new FormGroup({});
      this.selectArgumentView = argument;
    }
  }

  isPacketParameter(abstractContainer: AbstractContainer): boolean {
    return isPacketParameter(abstractContainer);
  }

  ngAfterViewChecked() {
    //explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.cdRef.detectChanges();
  }

  getTcPackets(): void {
    this.previousCallRunning = true;
    this.tcPacketsService
      .getTcPackets()
      .subscribe((tcpackets) => this.setTcPackets(tcpackets));
  }

  setTcPackets(tcpackets: TcPacket[]) {
    this.merge(tcpackets);
    this.initTableSort = true;
    this.previousCallRunning = false;
  }

  merge(newTcpackets: TcPacket[]) {
    let size: number = this.tcpackets.length;
    newTcpackets.forEach((newTcPacket, i) => {
      if (i < size) {
        const oldTcPacket: TcPacket = this.tcpackets[i];
        if (newTcPacket.id != oldTcPacket.id) {
          this.tcpackets.splice(i, 0, newTcPacket);
          size++;
        } else {
          if (newTcPacket.acceptance != null) {
            var tcAck: DatedTcAck = new DatedTcAck();
            tcAck.ack = newTcPacket.acceptance.ack;
            tcAck.time = newTcPacket.acceptance.time;
            oldTcPacket.acceptance = tcAck;
          }
          if (newTcPacket.started != null) {
            var tcAck: DatedTcAck = new DatedTcAck();
            tcAck.ack = newTcPacket.started.ack;
            tcAck.time = newTcPacket.started.time;
            oldTcPacket.started = tcAck;
          }
          if (newTcPacket.progress != null) {
            var tcAck: DatedTcAck = new DatedTcAck();
            tcAck.ack = newTcPacket.progress.ack;
            tcAck.time = newTcPacket.progress.time;
            oldTcPacket.progress = tcAck;
          }
          if (newTcPacket.completed != null) {
            var tcAck: DatedTcAck = new DatedTcAck();
            tcAck.ack = newTcPacket.completed.ack;
            tcAck.time = newTcPacket.completed.time;
            oldTcPacket.completed = tcAck;
          }
        }
      } else {
        this.tcpackets.splice(i, 0, newTcPacket);
        size++;
      }
    });
    this.tcpackets.splice(newTcpackets.length, size - newTcpackets.length);
  }

  //Trie la liste en entrée par ordre alphabétique en fonction du nom de paquet
  alphabeticalSort(list: ArgumentView[]): ArgumentView[] {
    return list.sort(function (a, b) {
      if (a.packetName < b.packetName) {
        return -1;
      } else if (a.packetName > b.packetName) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  //Remonte la liste de l'historique des TC en haut
  scrollTop() {
    $("#scroll-history-packet-tc-list").animate(
      {
        scrollTop: "0px",
      },
      500
    );
  }
}
