import {
  PacketParameter,
  Value,
  ValueType,
  isEnum,
} from "../../../model/spacepacket";
import { Component, OnInit, Input, Directive } from "@angular/core";
import { ArgumentView } from "../../../model/argumentview";
import { FormGroup, FormControl, Validators } from "@angular/forms";
declare let $: any;

@Component({
  selector: "app-packet-parameter",
  templateUrl: "./packet-parameter.component.html",
  styleUrls: ["./packet-parameter.component.css"],
})
export class PacketParameterComponent implements OnInit {
  @Input()
  packetParameter: PacketParameter;

  @Input()
  selectedFirst: ArgumentView;

  @Input()
  tcArguments: ArgumentView[];

  @Input()
  form: FormGroup;

  formImbricated: FormGroup;

  displayList: ArgumentView[];
  packetsTCList: ArgumentView[];
  edited: boolean;
  packetSearch: string;

  constructor() {}

  ngOnInit() {
    this.tcArguments = this.tcArguments.filter(
      (argumentView) => argumentView.packetName != this.selectedFirst.packetName
    );
    this.initPacketlist(this.tcArguments);
    $("#dropdown-list-tc-time-tag").css({
      width: $("#searchbarTc-time-tag").width() + "px",
    });

    this.form.setControl(
      "imbricatedForm",
      new FormControl("", Validators.required)
    );
  }

  initPacketlist(list: ArgumentView[]) {
    //      this.tcArguments = list;
    this.displayList = list;
    this.packetsTCList = list;
  }

  onChangeSearchPacket() {
    this.displayList = [];
    for (const packet of this.packetsTCList) {
      if (
        packet.packetName.indexOf(this.packetSearch.toUpperCase()) > -1 ||
        packet.packetName.indexOf(this.packetSearch) > -1
      ) {
        this.displayList.push(packet);
      }
    }
    if (this.packetSearch.length > 0) {
      this.edited = true;
    } else {
      this.edited = false;
    }
  }

  clearSearch() {
    this.packetSearch = "";
    this.onChangeSearchPacket();
  }

  setTC(argument: ArgumentView) {
    this.selectedFirst.embeddedTc = argument;
    this.formImbricated = new FormGroup({});
    this.form.setControl("imbricatedForm", this.formImbricated);
  }
}
