import { DatedParameter, Parameter } from "../../../model/spacepacket";

/**
 *
 * @param datedParameters
 * @param maxDataTotal
 */
export function filterData(
  datedParameters: DatedParameter[][],
  maxDataTotal: number
): string {
  let toReturn = "0";
  if (datedParameters.length > 0) {
    // On affine le maxDataTotal dans le cas où on a du mult courbe
    const maxData = Math.floor(maxDataTotal / datedParameters.length) + 1;
    for (const parameters of datedParameters) {
      const parametersSize = parameters.length;
      if (maxData < parametersSize) {
        const pointsToRemove = parametersSize - maxData;

        let fraction = pointsToRemove / parametersSize;

        const fractionOld = fraction;

        // On arrondi
        fraction = +fraction.toFixed(1);

        // On fait ça car sinon le filtre ne fonctionne pas pour les grand nombre de points, par exemple 6000 à 4 courbes
        if (fractionOld > 0 && fractionOld < 1 && fraction == 1) {
          fraction = +fractionOld.toFixed(2);
        }

        if (fractionOld > 0 && fractionOld < 1 && fraction == 1) {
          fraction = +fractionOld.toFixed(3);
        }

        if (fraction < 1 && fraction > 0) {
          const len = fraction.toString().length - 2;

          const denominator = Math.pow(10, len);
          const numerator = fraction * denominator;

          const resPgcd = pgcd(numerator, denominator);

          // On supprime tous les nieme éléments
          const n = Math.floor(denominator / resPgcd);

          // Tant d'éléments à suppr
          const toRemove = Math.floor(numerator / resPgcd);

          toReturn = toRemove + " / " + n;

          let i = 1;
          while (i < parameters.length) {
            parameters.splice(i, toRemove);
            i = i + n - toRemove;
          }
        }
      }
    }
  }

  return toReturn;
}

/**
 *
 * @param a
 * @param b
 */
export function pgcd(a: number, b: number): number {
  if (b < 0.000001) return a; // Since there is a limited precision we need to limit the value.

  return pgcd(b, Math.floor(a % b));
}

/**
 *
 * @param lineChartOptions
 */
export function addBigDataConfiguration(lineChartOptions: any) {
  // On disable les annimations pour les gros graphs
  lineChartOptions.animation = {
    duration: 0, // general animation time
  };

  lineChartOptions.transitions = {
    active: {
      animation: {
        duration: 0, // duration of animations when hovering an item
      },
    },
    resize: {
      animation: {
        duration: 0, // animation duration after a resize
      },
    },
  };
}
