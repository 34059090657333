import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Directive,
} from "@angular/core";
import {
  ParameterWidget,
  SelectedParameter,
  Representation,
} from "../../model/synoptic";
import { ParameterWidgetService } from "../../parameter-widget.service";
import { SpacePacket, Parameter } from "app/model/spacepacket";
import { GetParametersVisitor } from "app/model/get-parameters-visitor";
import { Colors } from "../../model/colors";
declare let $: any;

@Component({
  selector: "app-create-widget",
  templateUrl: "./create-widget.component.html",
  styleUrls: ["./create-widget.component.css"],
})
export class CreateWidgetComponent implements OnInit {
  colors: Colors = new Colors();

  toModify: ParameterWidget;

  toCreate: ParameterWidget = new ParameterWidget();
  // si c'est une modification, on copie le widget à modifier dans la variable toCreate
  @Input() set widgetToModif(widget: ParameterWidget) {
    this.toCreate = Object.assign({}, widget);
    this.toModify = widget;
  }

  @Input() isModify = false;
  widgetToSend: ParameterWidget = new ParameterWidget();

  @Input()
  synopticId: number;

  @Input() index: number;

  tmPacketsList: SpacePacket[];
  // Champs pour l'ajout de paramètres TM
  @Input() set packetsList(List: SpacePacket[]) {
    this.tmPacketsList = List;
    this.displayList = this.tmPacketsList;
  }

  displayList: SpacePacket[];
  edited: boolean;
  showFixedValues = false;
  selectedPacketsForParams: SpacePacket[] = [];
  selectedParams: SelectedParameter[] = [];
  selectedParamsWithPackets: SelectedParameter[];

  packetSearch: string;

  @Output()
  newWidget: EventEmitter<ParameterWidget> = new EventEmitter();

  ngOnChanges() {
    // Si on modifie un widget, on charge les paramètres sélectionnés.
    if (this.isModify && this.tmPacketsList != undefined) {
      if (this.toModify.representation != Representation.Comment) {
        this.selectedParamsWithPackets = JSON.parse(this.toCreate.parameters);

        const selectedParamsWithPackets2: SelectedParameter[] =
          this.selectedParamsWithPackets;
        if (selectedParamsWithPackets2) {
          selectedParamsWithPackets2.forEach((selectedParam, index) => {
            const packet = this.findSpacePacket(
              selectedParam.selectedPacketName
            );
            this.addTm(packet);
            this.onSelectParam(
              packet,
              selectedParam.selectedParamName,
              index,
              selectedParam.selectedColor,
              selectedParam.selectedYAxis
            );
          });
        }
      }
    }
  }

  constructor(
    private parameterWidgetService: ParameterWidgetService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.displayList = this.tmPacketsList;
    if (!this.isModify) {
      this.toCreate.obsolescence = 0;
    }

    // On doit réinitialiser le modal à chaque fermeture car c'est le mm qui est utilisé pour tous les synoptiques

    $("#ajout-widget-modal").on("hidden.bs.modal", () => {
      // si c'est un modal de mofication on ne veut pas reinitialiser
      if (!this.isModify) {
        this.resetData();
      }
    });
  }

  ngOnDestroy() {
    //    console.log("detruit")
  }

  public resetData() {
    this.selectedPacketsForParams = [];
    this.selectedParams = [];
  }

  // ---------------------------- Création d'un widget --------------------------------

  addWidget() {
    if (!this.sameColor()) {
      this.toCreate.synopticId = this.synopticId;
      // si on a un trigger ou une jauge, il fuat qu'une valeur dans la requete.
      if (
        this.toCreate.representation == Representation.Gauge ||
        this.toCreate.representation == Representation.Trigger
      ) {
        this.toCreate.nbValues = 1;
      }

      if (!this.isModify) {
        this.widgetToSend = this.toCreate;
        this.parameterWidgetService
          .addWidget(this.toCreate)
          .subscribe((parameterWidget) =>
            this.getCreatedWidgetId(parameterWidget)
          );
        // Clean form
        this.toCreate = new ParameterWidget();
        $("#ajout-widget-modal").modal("hide");
      } else {
        this.parameterWidgetService
          .updateWidget(this.toCreate)
          .subscribe((parameterWidget) => this.updateWidget(this.toCreate));
      }
    }
  }

  updateWidget(newWidget: ParameterWidget) {
    $("#modif-widget-modal-" + this.index).modal("hide");
    this.toModify.parameterWidgetId = newWidget.parameterWidgetId;
    this.toModify.title = newWidget.title;
    this.toModify.tmType = newWidget.tmType;
    this.toModify.obsolescence = newWidget.obsolescence;
    this.toModify.commentaire = newWidget.commentaire;
    this.toModify.synopticId = newWidget.synopticId;
    this.toModify.showLastValue = newWidget.showLastValue;
    if (
      this.toModify.parameters != newWidget.parameters ||
      this.toModify.representation != newWidget.representation ||
      this.toModify.nbValues != newWidget.nbValues
    ) {
      this.toModify.representation = newWidget.representation;
      this.toModify.parameters = newWidget.parameters;
      this.toModify.nbValues = newWidget.nbValues;
      this.newWidget.emit(this.toModify);
    }
  }

  private getCreatedWidgetId(parameterWidget: ParameterWidget) {
    // On informe le composant synoptic qu'un nouveau widget a été crée pour mettre à jour le chart string
    // On met à jour l'id de ce widget

    this.widgetToSend.parameterWidgetId = parameterWidget.parameterWidgetId;

    this.newWidget.emit(this.widgetToSend);
    this.widgetToSend = new ParameterWidget();
  }

  // ---------------------------- Recherche Tm pour la création d'un widget --------------------------------
  //Met a jour la liste de TC a afficher
  onChangeSearchPacket() {
    this.displayList = [];
    let find = false;
    for (const packet of this.tmPacketsList) {
      if (
        packet.name.toUpperCase().indexOf(this.packetSearch.toUpperCase()) > -1
      ) {
        this.displayList.push(packet);
        find = true;
      }
      if (packet.description != null && !find) {
        if (
          packet.description
            .toUpperCase()
            .indexOf(this.packetSearch.toUpperCase()) > -1
        ) {
          this.displayList.push(packet);
          find = true;
        }
      }
      if (this.packetSearch.length > 0) {
        this.edited = true;
      } else {
        this.edited = false;
      }
    }
  }

  onSelectParam(
    selectedPacket: SpacePacket,
    value: string,
    index: number,
    idColor: number,
    idYAxis: number
  ) {
    // TODO push selected packet

    const selectedParameters: SelectedParameter = new SelectedParameter();
    selectedParameters.selectedPacketName = selectedPacket.name;
    selectedParameters.selectedParamName = value;

    // on vérifie que la couleur ne soit pas déjà choisie et sinon en trouve une non choisie
    selectedParameters.selectedColor = this.foundAvailableColor(idColor);
    selectedParameters.selectedYAxis = idYAxis;

    this.selectedParams[index] = selectedParameters;

    this.toCreate.parameters = JSON.stringify(this.selectedParams);
  }

  foundAvailableColor(index: number) {
    const idCOlorFound = false;
    let isTaken = false;
    const size = this.selectedParams.length;
    while (!idCOlorFound) {
      for (let i = 0; i < size; i++) {
        if (this.selectedParams[i].selectedColor == index) {
          isTaken = true;
        }
      }
      if (isTaken) {
        isTaken = false;
        index = (index + 1) % 10;
      } else {
        return index;
      }
    }
  }

  changeShowFixedValues() {
    this.showFixedValues = !this.showFixedValues;
  }

  addTm(tmPacket: SpacePacket) {
    this.selectedPacketsForParams.push(tmPacket);
  }

  findSpacePacket(name: string): SpacePacket {
    for (const packet of this.tmPacketsList) {
      if (packet.name === name) {
        return packet;
      }
    }
  }

  getParametersFromTm(index: number): Parameter[] {
    let currentValue = "";
    if (this.selectedParams[index] !== undefined) {
      currentValue = this.selectedParams[index].selectedParamName;
    }

    // Second arguments is for show only numerical values
    const tmPacket = this.selectedPacketsForParams[index];

    const getParametersVisitor: GetParametersVisitor = new GetParametersVisitor(
      this.showFixedValues,
      true
    );
    getParametersVisitor.visitPacketContainer(tmPacket.rootContainer);
    //        console.log( "on retourne : " )
    //        console.log( this.filterALreadyChoosen( getParametersVisitor.getParameters(), currentValue ) )
    return this.filterALreadyChoosen(
      getParametersVisitor.getParameters(),
      currentValue
    );
  }

  private filterALreadyChoosen(
    parameters: Parameter[],
    currentValue: string
  ): Parameter[] {
    const toReturn: Parameter[] = [];

    for (const param of parameters) {
      let toAdd = true;
      for (const choosedParam of this.selectedParams) {
        if (
          choosedParam != null &&
          param.name == choosedParam.selectedParamName
        ) {
          toAdd = false;
        }
      }

      // Si c'est la valeur de la balise déjà choisie on la garde dans tous les cas
      if (param.name == currentValue) {
        toAdd = true;
      }

      if (toAdd) {
        toReturn.push(param);
      }
    }
    return toReturn;
  }

  chooseToDelete(i: number) {
    this.changeDetectorRef.detach();

    this.selectedPacketsForParams.splice(i, 1);
    this.selectedParams.splice(i, 1);

    this.changeDetectorRef.reattach();
    this.toCreate.parameters = JSON.stringify(this.selectedParams);
  }

  getNumberColor(index: number) {
    return this.selectedParams[index].selectedColor;
  }

  setColor(index: number, idColor: number) {
    this.selectedParams[index].selectedColor = idColor;
    this.toCreate.parameters = JSON.stringify(this.selectedParams);
  }

  onChangeYAxis() {
    this.toCreate.parameters = JSON.stringify(this.selectedParams);
  }

  sameColor() {
    if (this.selectedParams != undefined) {
      const size = this.selectedParams.length;
      for (let i = 0; i < size; i++) {
        for (let j = i + 1; j < size; j++) {
          if (this.getNumberColor(i) == this.getNumberColor(j)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getColor(index: number) {
    return this.colors.lineChartColors[index].pointBackgroundColor;
  }

  clearSearch() {
    this.packetSearch = "";
  }
}
