import {
  SpacePacket,
  PacketContainer,
  PacketParameter,
  Parameter,
  isParameter,
  isPacketContainer,
  isPacketParameter,
  formatDate,
  AbstractContainer,
  updatePacketStatus,
} from "../model/spacepacket";
import { TmPacketListService } from "../tm-packet-list.service";
import { TcPacketListService } from "../tc-packet-list.service";
import { BdsVersionService } from "../bds-version.service";
import { Component, OnInit, NgModule, Directive } from "@angular/core";
import { Subscription } from "rxjs";
declare let $: any;

@Component({
  selector: "app-bds-packets",
  templateUrl: "./bds-packets.component.html",
  styleUrls: ["./bds-packets.component.css"],
})
export class BdsPacketsComponent implements OnInit {
  //tmList: SpacePacket[];
  //tcList: SpacePacket[];
  packetsList: SpacePacket[];
  displayList: SpacePacket[];
  selectedContainer: AbstractContainer;

  selectedPacket: SpacePacket;
  packetSearch: string;
  edited = false;

  showDateChoice = false;
  showNbPacketChoice = false;

  subscription: Subscription;
  subscription2: Subscription;
  bdsVersion: string;

  constructor(
    private tmPacketListService: TmPacketListService,
    private tcPacketListService: TcPacketListService,
    private bdsVersionService: BdsVersionService
  ) {}

  ngOnInit() {
    this.getPacketList();
    this.getBdsVersion();
  }

  ngOnDestroy() {
    this.subscription2.unsubscribe();
  }

  getPacketList() {
    this.tmPacketListService
      .getTmPacketList()
      .subscribe((tmList) => this.initTmPacketlist(tmList));
  }

  getBdsVersion() {
    this.subscription2 = this.bdsVersionService
      .bdsVersion()
      .subscribe((x) => this.initBdsVersion(x));
  }

  initTmPacketlist(list: SpacePacket[]) {
    this.packetsList = list;
    this.tcPacketListService
      .getTcPacketList()
      .subscribe((tcList) => this.initTcPacketlist(tcList));
  }

  initBdsVersion(version: string[]) {
    console.log(version[0]);
    this.bdsVersion = version[0];
  }

  initTcPacketlist(list: SpacePacket[]) {
    this.packetsList = this.packetsList.concat(list);
    this.displayList = this.packetsList;
  }

  alphabeticalSort(list: SpacePacket[]): SpacePacket[] {
    return list.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  onSelect(packet: SpacePacket): void {
    this.selectedPacket = packet;
    this.selectedContainer = packet.rootContainer;
  }

  selectedContainerChange(selectedContainer: AbstractContainer) {
    this.selectedContainer = selectedContainer;
  }

  onChangeSearchPacket() {
    this.displayList = [];
    let find = false;
    for (const packet of this.packetsList) {
      if (packet.name != null) {
        if (
          packet.name.toUpperCase().indexOf(this.packetSearch.toUpperCase()) >
          -1
        ) {
          this.displayList.push(packet);
          find = true;
        }
      }
      if (packet.description != null && !find) {
        if (
          packet.description
            .toUpperCase()
            .indexOf(this.packetSearch.toUpperCase()) > -1
        ) {
          this.displayList.push(packet);
          find = true;
        }
      }
      if (!find) {
        for (const subPacket of this.getParameters(packet.rootContainer)) {
          if (this.isParameter) {
            const parameter = <Parameter>subPacket;
            if (parameter.name != null) {
              if (
                parameter.name
                  .toUpperCase()
                  .indexOf(this.packetSearch.toUpperCase()) > -1 &&
                !find
              ) {
                find = true;
                this.displayList.push(packet);
              }
            }
            if (parameter.description != null) {
              if (
                parameter.description
                  .toUpperCase()
                  .indexOf(this.packetSearch.toUpperCase()) > -1 &&
                !find &&
                parameter.description != null
              ) {
                find = true;
                this.displayList.push(packet);
              }
            }
          }
        }
      }
      find = false;
    }
    if (this.packetSearch.length > 0) {
      this.edited = true;
    } else {
      this.edited = false;
    }
  }

  getParameters(selectedContainer: PacketContainer): Parameter[] {
    let parametersToReturn: Parameter[] = new Array<Parameter>();
    try {
      parametersToReturn = this.recursiveGetParameters(
        selectedContainer,
        parametersToReturn
      );
    } finally {
      return parametersToReturn;
    }
  }

  isParameter(abstractContainer: AbstractContainer): boolean {
    return isParameter(abstractContainer);
  }

  recursiveGetParameters(
    selectedContainer: AbstractContainer,
    parameterList: Parameter[]
  ): Parameter[] {
    const parametersToReturn = parameterList;
    if (isPacketContainer(selectedContainer)) {
      for (const packetContainer of (<PacketContainer>selectedContainer)
        .subContainers) {
        parametersToReturn.concat(
          this.recursiveGetParameters(packetContainer, parameterList)
        );
      }
    } else if (isParameter(selectedContainer)) {
      parametersToReturn.push(<Parameter>selectedContainer);
    } else if (isPacketParameter(selectedContainer)) {
      parametersToReturn.concat(
        this.recursiveGetParameters(
          (<PacketParameter>selectedContainer).spacePacket.rootContainer,
          parameterList
        )
      );
    }

    return parametersToReturn;
  }

  clearSearch() {
    this.packetSearch = "";
    this.onChangeSearchPacket();
  }
}
