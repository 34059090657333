import { Component, OnInit, Input, Output, Directive } from "@angular/core";
import { DatedParameter } from "app/model/spacepacket";
import { Colors } from "../../../model/colors";
declare let $: any;

@Component({
  selector: "app-alpha-num-widget",
  templateUrl: "./alpha-num-widget.component.html",
  styleUrls: ["./alpha-num-widget.component.css"],
})
export class AlphaNumWidgetComponent implements OnInit {
  colors: Colors = new Colors();

  @Input() datedParameters: DatedParameter[];
  @Input() index: number;
  @Input() nbValues: number;
  @Input() idColor: number;
  @Input() orderBy: string;
  @Input() obsolescence: number;
  // id of widget is used for the tooltips
  @Input() widgetId: number;

  ngOnInit() {}

  ngAfterViewChecked() {}

  getName() {
    return this.datedParameters[0].parameter.name;
  }

  getColorBorder() {
    return this.colors.lineChartColors[this.idColor].pointBackgroundColor;
  }

  isObsolete(datedParameter: DatedParameter): boolean {
    if (this.obsolescence) {
      if (this.orderBy == "onReceptionTime") {
        var pointDate = new Date(datedParameter.receptionTime);
      } else {
        var pointDate = new Date(datedParameter.onBoardTime);
      }
      const currentDate = new Date();

      const diffInSec = (currentDate.getTime() - pointDate.getTime()) / 1000;

      if (diffInSec > this.obsolescence) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  constructor() {}
}
