import { DatedParameter, Parameter } from "../../../model/spacepacket";
import { SelectedParameter } from "../../../model/synoptic";
import { Colors } from "../../../model/colors";

/**
 *
 * @param newParameters
 * @param lineChartOptions
 * @param idYAxis
 * @param selectedParamsWithPackets
 */
export function setUnitFromAxis(
  newParameters: Parameter[],
  lineChartOptions: any,
  idYAxis: string,
  selectedParamsWithPackets: SelectedParameter[]
): void {
  let toReturn = "";
  let color = "";
  const colors: Colors = new Colors();
  if (newParameters.length > 0) {
    newParameters.forEach((parameter, index) => {
      // On vérifie à chaque foi que le param a bien une valeur dans Unit sinon c'est null
      if (toReturn == "" && parameter.unit != null) {
        toReturn = parameter.unit.unit;
        color =
          colors.lineChartColors[selectedParamsWithPackets[index].selectedColor]
            .colorForAxisUnits;
      } else if (parameter.unit != null && parameter.unit.unit != toReturn) {
        toReturn = "Multiple Units";
        color = "";
      }
    });
  } else {
    lineChartOptions.scales[idYAxis].display = false;
  }

  lineChartOptions.scales[idYAxis].title.text = toReturn;
  lineChartOptions.scales[idYAxis].title.color = color;
}

/**
 *
 * @param newParameters
 * @param lineChartOptions
 */
export function setUnitFromParameters(
  newParameters: Parameter[],
  lineChartOptions: any
) {
  lineChartOptions.plugins.tooltip.callbacks.label = function (context) {
    let label = context.parsed.y;
    const index = context.datasetIndex;

    if (newParameters[index].unit != null) {
      label += " " + newParameters[index].unit.unit;
    }
    return label;
  };
}
