<div id="send-tc-parameter-list">
  <div *ngIf="parameter.ptc == 3" class="form-group">
    <ng-container *ngIf="isEnum"> <label for="{{parameterId}}" class="col-form-label">
        <h5>{{parameter.name}}</h5>
      </label>
      <p class="help-block text-primary description-parameter form-control-feedback">{{parameter.description}}</p>
      <div class="input-group" [formGroup]="form">
        <select [attr.disabled]="parameter.fixedValue ? 'true' : null" [formControlName]="parameterId"
          id="{{parameterId}}" class="form-control" [(ngModel)]="parameter.physicalValue.value" required>
          <option *ngFor="let enum of parameter.calibration.enumerationList" [ngValue]="enum.string">{{enum.string}}
          </option>
        </select>
      </div>
    </ng-container>


    <ng-container *ngIf="!isEnum"> <label for="{{parameterId}}" class="col-form-label">
        <h5>{{parameter.name}}</h5>
      </label>

      <p class="help-block text-primary description-parameter form-control-feedback">{{parameter.description}}</p>

      <div class="input-group" [formGroup]="form">
        <input *ngIf="!parameter.fixedValue" [formControlName]="parameterId" type="number" step="1" min="{{min}}"
          max="{{max}}" class="form-control" id="{{parameterId}}" [(ngModel)]="parameter.physicalValue.value"
          name="{{parameter.value}}" required aria-describedby="basic-addon2">

        <input *ngIf="parameter.fixedValue" [formControlName]="parameterId" type="number" step="1" min="{{min}}"
          max="{{max}}" class="form-control" id="{{parameterId}}" [(ngModel)]="parameter.physicalValue.value"
          name="{{parameter.value}}" required aria-describedby="basic-addon2" disabled> <span *ngIf="parameter.unit"
          class="input-group-addon" id="basic-addon2">{{parameter.unit.unit}}</span>
      </div>

      <div class='row'>
        <div class="form-control-feedback unit-parameter col-6">
          <span *ngIf="parameter.fixedValue"> <span class="nominal">Argument
              fixé</span>
          </span> <span *ngIf="isPolynomialCalibration(parameter.calibration) && !isRequired">
            <span *ngIf="isUnderLimit||isOverLimit||isInteger; else elseDontDisplay" class="severe">Valeur calibrée :
              {{valueAfterCalibration(parameter.physicalValue.value,
              parameter.calibration)}}</span>
            <ng-template #elseDontDisplay>
              <span class="nominal">Valeur calibrée :
                {{valueAfterCalibration(parameter.physicalValue.value,
                parameter.calibration)}}</span>
            </ng-template>
          </span>
          <div *ngIf="isRequired" class="severe">{{parameter.name}} est
            requis</div>
          <ng-container *ngIf="isPolynomialCalibration(parameter.calibration) ; else elseNotCalibratedValue">
            <div *ngIf="isInteger && !isRequired" class="severe">La valeur
              calibrée doit etre un entier</div>
            <div *ngIf="isUnderLimit && !isRequired" class="severe">La
              valeur calibrée doit être supérieure à {{min}}</div>
            <div *ngIf="isOverLimit && !isRequired" class="severe">La
              valeur calibrée doit être inférieure à {{max}}</div>
          </ng-container>
          <ng-template #elseNotCalibratedValue>
            <div *ngIf="isInteger && !isRequired" class="severe">{{parameter.name}}
              doit etre un entier</div>
            <div *ngIf="isUnderLimit && !isRequired" class="severe">{{parameter.name}}
              doit être supérieur à {{min}}</div>
            <div *ngIf="isOverLimit && !isRequired" class="severe">{{parameter.name}}
              doit être inférieur à {{max}}</div>
          </ng-template>
        </div>
        <div class="form-control-feedback unit-parameter col-6">
          <span class="float-right"> <span *ngIf="!isInteger || isRequired">Unsigned integer</span><span
              *ngIf="isInteger && !isRequired" class="severe">Unsigned
              integer</span>, <span *ngIf="!isUnderLimit || isRequired">Min :
              {{min}}</span><span *ngIf="isUnderLimit && !isRequired" class="severe">Min
              : {{min}}</span>, <span *ngIf="!isOverLimit || isRequired">Max :
              {{max}}</span><span *ngIf="isOverLimit && !isRequired" class="severe">Max
              : {{max}}</span>
          </span>
        </div>
      </div>
    </ng-container>
  </div>


  <div *ngIf="parameter.ptc == 4" class="form-group">
    <label for="{{parameterId}}" class="col-form-label">
      <h5>{{parameter.name}}</h5>
    </label>

    <p class="help-block text-primary description-parameter form-control-feedback">{{parameter.description}}</p>

    <div class="input-group" [formGroup]="form">
      <input *ngIf="!parameter.fixedValue" [formControlName]="parameterId" type="number" step="1" min="{{min}}"
        max="{{max}}" class="form-control" id="{{parameterId}}" [(ngModel)]="parameter.physicalValue.value"
        name="{{parameter.value}}" required aria-describedby="basic-addon2">

      <input *ngIf="parameter.fixedValue" [formControlName]="parameterId" type="number" step="1" min="{{min}}"
        max="{{max}}" class="form-control" id="{{parameterId}}" [(ngModel)]="parameter.physicalValue.value"
        name="{{parameter.value}}" required aria-describedby="basic-addon2" disabled>

      <ng-container *ngIf="parameter.unit"> <span class="input-group-addon"
          id="basic-addon2">{{parameter.unit.unit}}</span></ng-container>
    </div>

    <div class='row'>
      <div class="form-control-feedback unit-parameter col-6">
        <span *ngIf="isPolynomialCalibration(parameter.calibration) && !isRequired">
          <span *ngIf="isUnderLimit||isOverLimit||isInteger; else elseBlock" class="severe">Valeur calibrée :
            {{valueAfterCalibration(parameter.physicalValue.value,
            parameter.calibration)}}</span>
          <ng-template #elseBlock> <span class="nominal">Valeur calibrée :
              {{valueAfterCalibration(parameter.physicalValue.value,
              parameter.calibration)}}</span> </ng-template>

        </span>
        <div *ngIf="isRequired" class="severe">{{parameter.name}} est
          requis</div>
        <div *ngIf="isInteger && !isRequired" class="severe">{{parameter.name}}
          doit etre un entier</div>
        <div *ngIf="isUnderLimit && !isRequired" class="severe">{{parameter.name}}
          doit être supérieur à {{min}}</div>
        <div *ngIf="isOverLimit && !isRequired" class="severe">{{parameter.name}}
          doit être inférieur à {{max}}</div>
      </div>
      <div class="form-control-feedback unit-parameter col-6">
        <span class="float-right"> <span *ngIf="!isInteger || isRequired">Signed Integer</span><span
            *ngIf="isInteger && !isRequired" class="severe">Signed
            Integer</span>, <span *ngIf="!isUnderLimit || isRequired">Min :
            {{min}}</span><span *ngIf="isUnderLimit && !isRequired" class="severe">Min
            : {{min}}</span>, <span *ngIf="!isOverLimit || isRequired">Max :
            {{max}}</span><span *ngIf="isOverLimit && !isRequired" class="severe">Max
            : {{max}}</span>
        </span>
      </div>
    </div>

  </div>

  <div *ngIf="parameter.ptc == 5 && parameter.pfc == 1" class="form-group">
    <label for="{{parameterId}}" class="col-form-label">
      <h5>{{parameter.name}}</h5>
    </label>
    <p class="help-block text-primary description-parameter form-control-feedback">{{parameter.description}}</p>
    <div class="input-group" [formGroup]="form">
      <input *ngIf="!parameter.fixedValue" [formControlName]="parameterId" type="number" step="0.000000001"
        class="form-control" id="{{parameterId}}" [(ngModel)]="parameter.physicalValue.value" required
        aria-describedby="basic-addon2">
      <input *ngIf="parameter.fixedValue" [formControlName]="parameterId" type="number" step="0.000000001"
        class="form-control" id="{{parameterId}}" [(ngModel)]="parameter.physicalValue.value" required
        aria-describedby="basic-addon2" disabled>
      <ng-container *ngIf="parameter.unit"> <span class="input-group-addon"
          id="basic-addon2">{{parameter.unit.unit}}</span></ng-container>
    </div>
    <div class='row'>
      <div class="form-control-feedback unit-parameter col-6">
        <span *ngIf="isPolynomialCalibration(parameter.calibration) && !isRequired">
          <span *ngIf="isUnderLimit||isOverLimit||isFloat; else elseBlock" class="severe">Valeur calibrée :
            {{valueAfterCalibration(parameter.physicalValue.value,
            parameter.calibration)}}</span>
          <ng-template #elseBlock> <span class="nominal">Valeur calibrée :
              {{valueAfterCalibration(parameter.physicalValue.value,
              parameter.calibration)}}</span> </ng-template>
        </span>
        <div *ngIf="isRequired" class="severe">{{parameter.name}} est
          requis</div>
        <div *ngIf="isFloat && !isRequired" class="severe">{{parameter.name}}
          ne doit pas avoir plus de 9 décimales</div>
        <div *ngIf="isUnderLimit && !isRequired" class="severe">{{parameter.name}}
          doit être supérieur à {{min}}</div>
        <div *ngIf="isOverLimit && !isRequired" class="severe">{{parameter.name}}
          doit être inférieur à {{max}}</div>
      </div>
      <div class="form-control-feedback unit-parameter col-6">
        <span class="float-right"> <span *ngIf="!isFloat || isRequired">32bit Float</span><span
            *ngIf="isFloat && !isRequired" class="severe">32bit Float</span>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="parameter.ptc == 5 && parameter.pfc == 2" class="form-group">
    <label for="{{parameterId}}" class="col-form-label">
      <h5>{{parameter.name}}</h5>
    </label>
    <p class="help-block text-primary description-parameter form-control-feedback">{{parameter.description}}</p>
    <div class="input-group" [formGroup]="form">
      <input *ngIf="!parameter.fixedValue" [formControlName]="parameterId" type="number" class="form-control"
        id="{{parameterId}}" [(ngModel)]="parameter.physicalValue.value" required> <input *ngIf="parameter.fixedValue"
        [formControlName]="parameterId" type="number" class="form-control" id="{{parameterId}}"
        [(ngModel)]="parameter.physicalValue.value" required disabled>

      <ng-container *ngIf="parameter.unit"> <span class="input-group-addon"
          id="basic-addon2">{{parameter.unit.unit}}</span></ng-container>
    </div>
    <div class='row'>
      <div class="form-control-feedback unit-parameter col-6">
        <span *ngIf="isPolynomialCalibration(parameter.calibration) && !isRequired">
          <span *ngIf="isUnderLimit||isOverLimit||isFloat; else elseBlock" class="severe">Valeur calibrée :
            {{valueAfterCalibration(parameter.physicalValue.value,
            parameter.calibration)}}</span>
          <ng-template #elseBlock> <span class="nominal">Valeur calibrée :
              {{valueAfterCalibration(parameter.physicalValue.value,
              parameter.calibration)}}</span> </ng-template>
        </span>
        <div *ngIf="isRequired" class="severe">{{parameter.name}} est
          requis</div>
        <div *ngIf="isFloat && !isRequired" class="severe">{{parameter.name}}
          ne doit pas avoir plus de 9 décimales</div>
        <div *ngIf="isUnderLimit && !isRequired" class="severe">{{parameter.name}}
          doit être supérieur à {{min}}</div>
        <div *ngIf="isOverLimit && !isRequired" class="severe">{{parameter.name}}
          doit être inférieur à {{max}}</div>
      </div>
      <div class="form-control-feedback unit-parameter col-6">
        <span class="float-right"> <span *ngIf="!isFloat || isRequired">64bit Float</span><span
            *ngIf="isFloat && !isRequired" class="severe">64bit Float</span>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="parameter.ptc == 7" class="form-group">
    <label for="{{parameterId}}" class="col-form-label">
      <h5>{{parameter.name}}</h5>
    </label>
    <p class="help-block text-primary description-parameter form-control-feedback">{{parameter.description}}</p>
    <div class="input-group" [formGroup]="form">
      <input *ngIf="!parameter.fixedValue" [formControlName]="parameterId" type="text" class="form-control"
        id="{{parameterId}}" [(ngModel)]="hexBinary" (ngModelChange)="onChangeBinary()" required> <input
        *ngIf="parameter.fixedValue" [formControlName]="parameterId" type="text" class="form-control"
        id="{{parameterId}}" [(ngModel)]="parameter.physicalValue.value" required disabled>

      <ng-container> <span class="input-group-addon" id="basic-addon2">0x</span></ng-container>
    </div>
    <div class='row'>
      <div class="form-control-feedback unit-parameter col-6">
        <div *ngIf="isRequired" class="severe">{{parameter.name}} est
          requis</div>
        <div *ngIf="isOverLimitBinary && !isRequired" class="severe">{{parameter.name}}
          doit être inférieur à {{max}} (valeur en UInt)</div>
      </div>
      <div class="form-control-feedback unit-parameter col-6">
        <span class="float-right"> <span *ngIf="!isBinary || isRequired">Hex Binary</span><span
            *ngIf="isBinary && !isRequired" class="severe">Hex Binary</span>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="parameter.ptc == 9" class="form-group">
    <label>
      <h5>{{parameter.name}}</h5>
    </label>
    <p class="help-block text-primary description-parameter form-control-feedback">{{parameter.description}}</p>
    <div class="form-inline" id="TimeTagTc">
      <!-- 			Tricks for datepicker value : http://stackoverflow.com/questions/36087762/how-to-detect-bootstrap-datetimepicker-change-events-within-angular2-->

      <!-- 				<div class="row" id="TimeTagTc"> -->
      <!-- 					<div class="col-lg-4"> -->
      <div class="input-group" [formGroup]="form">
        <span class="input-group-addon" id="addonDate">Date</span> <input [formControlName]="parameterId+'Date'"
          id="TimeTagTcDate" type="text" data-date-format="dd/mm/yyyy" #datePicker data-date-today-highlight="true"
          [(ngModel)]="date" class="form-control" data-provide="datepicker" (blur)="date = datePicker.value;onChange()"
          required>
      </div>
      <div class="input-group" [formGroup]="form">
        <span class="input-group-addon" id="addonTime">Temps</span> <input [formControlName]="parameterId+'Temps'"
          id="TimeTagTcTime" class="form-control" type="time" step="1" id="TimeTagTcInput" [(ngModel)]="time"
          (ngModelChange)="onChange()" required>
      </div>
      <div class="input-group" [formGroup]="form">
        <span class="input-group-addon" id="addonNano">Nano</span> <input [formControlName]="parameterId+'Nano'"
          id="TimeTagTcNano" type="number" min="0" step="1" max="999999999" maxlength="9" class="form-control"
          id="NanoSeconds" [(ngModel)]="nanoseconds" (ngModelChange)="onChange()" required>
      </div>
    </div>
    <div class="form-control-feedback unit-parameter text-right">Type
      : AbsoluteTime, Scale : UTC±0
    </div>
  </div>
  <div *ngIf="parameter.ptc == 10" class="form-group">
    <label>
      <h5>{{parameter.name}}</h5>
    </label>
    <p class="help-block text-primary description-parameter form-control-feedback">{{parameter.description}}</p>
    <div class="form-inline" id="cucRelativeForm">
      <div class="input-group" [formGroup]="form">
        <span class="input-group-addon">Seconds</span> <input [formControlName]="parameterId+'Seconds'" type="number"
          min="-2147483647" step="1" max="2147483647" maxlength="11" class="form-control" [(ngModel)]="seconds"
          (ngModelChange)="onChangeCucRelative()" required>
      </div>
      <div class="input-group" [formGroup]="form">
        <span class="input-group-addon">Nano</span> <input [formControlName]="parameterId+'Nano'" type="number"
          min="-999999999" step="1" max="999999999" maxlength="9" class="form-control" [(ngModel)]="nanoseconds"
          (ngModelChange)="onChangeCucRelative()" required>
      </div>
    </div>
    <div class="form-control-feedback unit-parameter text-right">Type
      : RelativeTime, Scale : UTC±0, Accuracy: ~59,6ns
    </div>
  </div>