import { Criticality, DatedParameter } from "../../../model/spacepacket";
import { CriticityColors } from "../../../model/criticity-colors";
import { Colors } from "../../../model/colors";

/**
 *
 * @param dateParameter
 * @param colorNumber
 */
export function getPointBackgroundColor(
  dateParameter: DatedParameter,
  colorNumber: number
): string {
  const colors: Colors = new Colors();

  let colorToReturn;

  const status: Criticality = dateParameter.parameter.status;
  switch (status) {
    case Criticality.WATCH:
      colorToReturn = CriticityColors.watch;
      break;
    case Criticality.WARNING:
      colorToReturn = CriticityColors.warning;
      break;
    case Criticality.DISTRESS:
      colorToReturn = CriticityColors.distress;
      break;
    case Criticality.CRITICAL:
      colorToReturn = CriticityColors.critical;
      break;
    case Criticality.SEVERE:
      colorToReturn = CriticityColors.severe;
      break;
    default:
      colorToReturn = colors.lineChartColors[colorNumber].pointBackgroundColor;
  }

  return colorToReturn;
}

/**
 *
 * @param dateParameter
 * @param colorNumber
 */
export function getPointRadius(dateParameter: DatedParameter): string {
  let radiusToReturn;
  const status: Criticality = dateParameter.parameter.status;
  switch (status) {
    case Criticality.WATCH:
    case Criticality.WARNING:
    case Criticality.DISTRESS:
    case Criticality.CRITICAL:
    case Criticality.SEVERE:
      radiusToReturn = pointCriticalRadius();
      break;
    default:
      radiusToReturn = pointDefaultRadius();
      break;
  }

  return radiusToReturn;
}

/**
 *
 */
export function pointCriticalRadius(): any {
  return "4";
}

/**
 *
 */
export function pointDefaultRadius(): any {
  return "3";
}

/**
 *
 */
export function getPointBorderWidth(): string {
  return "1";
}

/**
 *
 */
export function getPointHoverRadius(): string {
  return "5";
}
