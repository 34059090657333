<div class="card-header" [ngClass]="this.worsCriticityHeaderClass">
  <strong class="semi-transparent">{{selectedWidget.title}}</strong>
  <span *ngIf="this.selectedWidget.representation + '' !== 'Comment'" class="widget-commentaire">Nb. points :
    <ng-container *ngFor="let dataparameter of datedParameters; let i = index">
    <ng-container *ngIf="i!=0"> | </ng-container>{{dataparameter.length}}
    </ng-container> {{selectedWidget.commentaire}}</span>
  <ng-container *ngIf="!inZoom">
    <span class="icon icon-cross right pointer-hover" title="Supprimer le widget" data-toggle="modal"
      [attr.data-target]="'#delete-widget-' + widgetIndex"></span>
    <span class="icon icon-cog right pointer-hover" title="Modifier le widget" data-toggle="modal"
      [attr.data-target]="'#modif-widget-modal-' + widgetIndex"></span>
    <span class="icon icon-resize-full-screen right pointer-hover" title="Afficher le widget en plein écran"
      data-toggle="modal" [attr.data-target]="'#zoom-widget-' + widgetIndex"></span>
  </ng-container>
  <span
    *ngIf="this.selectedWidget.representation + '' === 'Plot' || this.selectedWidget.representation + '' === 'Histogram'"
    class="icon icon-ccw right pointer-hover" title="Réinitaliser le zoom" (click)="resetZoom()"></span>
  <ng-container
    *ngIf="(this.selectedWidget.representation + '' === 'Plot' || this.selectedWidget.representation + '' === 'Histogram') && this.thresholdEnabled">
    <span class="icon icon-menu right pointer-hover" title="Afficher / masquer les seuils"
      (click)="setThresholds()"></span>
  </ng-container>
  <span *ngIf="filterBigDataUsed" class="icon icon-info right" title="Les données ce widget ont été modifiées pour améliorer la performance d'affichage.
  Lorsque le nombre de points contenus dans le widget (nombre total si multi-courbe) dépasse 250 alors les animations sont désactivées.
  Lorsque le nombre de points dépasse {{this.maxDataTotal}}, alors un filtre est mis en place pour limiter le nombre de valeurs à {{this.maxDataTotal}}. Ce filtre s'adapte automatiquement à la taille des données:
  le filtre choisi automatiquement la période minimale selon laquelle les points sont supprimés pour que le nombre de points total dans le widget soit inférieure à {{this.maxDataTotal}}. 
  La valeur la plus récente (plus à droite du graphique) n’est jamais filtrée. Si un besoin plus précis en visualisation est nécessaire il faut utiliser l’outil d’export 
  combiné à Matlab/Excel. Le but du filtre est de maintenir de bonnes performances pour l’affichage de tendances sur de longues périodes de temps.
  Il est tout de monde possible de désactiver le filtre sur chaque widget à l'aide de la checkbox sur la gauche.
  Annimations désactivées: OUI.
  {{filterValue == '0' ? 'Filtrage activé: NON' : 'Filtrage activé: OUI - Valeur du filtre: ' + filterValue }}"></span>
  <label *ngIf="filterValue != '0'" class="custom-control custom-checkbox right"
    title="Désactiver le filtre des données.">
    <input type="checkbox" class="custom-control-input" [checked]="filterCheckbox" (change)="onChangeFilterCheckbox()">
    <span class="custom-control-indicator"></span>
  </label>
</div>

<div class="card-block scrollbar-style"
  [ngClass]="this.worsCriticityBodyClass"
  [id]="'cardBlock-' + this.selectedWidget.parameterWidgetId">
  <!-- Widget pour Comment -->
  <p class="comment" *ngIf="selectedWidget.representation + '' === 'Comment'">
    {{selectedWidget.commentaire}}</p>

  <!-- Widget pour plot -->
  <div *ngIf="(selectedWidget.representation + '' === 'Plot') || (selectedWidget.representation + '' === 'Histogram')"
    class="widget-chart container">
    <div class="row align-items-center justify-content-md-center" style="height: inherit">
      <div class="{{getClassForLeftComponent()}} p-0" [id]="'leftChart-' + this.selectedWidget.parameterWidgetId" style="height: inherit">
        <canvas baseChart
          [attr.id]="!inZoom ? 'chart-' + selectedWidget.parameterWidgetId : 'zoom-chart-' + selectedWidget.parameterWidgetId"
          id="" ng-click="chartClicked($event)"></canvas>
      </div>
      <div *ngIf="selectedWidget.showLastValue" class="{{getClassForLastValues()}} p-0"
        [id]="'rightValue-' + this.selectedWidget.parameterWidgetId">
        <div class="last-values mx-3">
          <ng-container *ngFor="let dataparameter of datedParameters; let i = index">
            <div class="row last-value">
              <app-alpha-num-widget [datedParameters]="dataparameter" [index]="i" [nbValues]="1"
                [idColor]="getIdColor(i)" [orderBy]="this.synopticConfiguration.orderBy"
                [widgetId]="this.selectedWidget.parameterWidgetId"></app-alpha-num-widget>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- Widget pour alphanumérique -->
  <div *ngIf="selectedWidget.representation + '' === 'Alpha'" id="alphanum">
    <div class="container-fluid">
      <div class="row">
        <ng-container *ngFor="let dataparameter of datedParameters; let i = index">
          <div class="col text-truncate">
            <app-alpha-num-widget [datedParameters]="dataparameter" [index]="i" [nbValues]="selectedWidget.nbValues"
              [idColor]="getIdColor(i)" [orderBy]="this.synopticConfiguration.orderBy"
              [obsolescence]="this.selectedWidget.obsolescence" [widgetId]="this.selectedWidget.parameterWidgetId">
            </app-alpha-num-widget>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Widget pour jauge -->
  <div *ngIf="selectedWidget.representation + '' === 'Gauge'" id="jauge">
    <div class="container-fluid">
      <ng-container *ngFor="let dataparameter of datedParameters; let i = index">
        <div class="row align-items-center justify-content-md-center">
          <div class="{{getClassForLeftJaugeComponent()}}">
            <app-jauge-widget [parameters]="dataparameter" [index]="i" [idColor]="getIdColor(i)"
              [orderBy]="this.synopticConfiguration.orderBy" [obsolescence]="this.selectedWidget.obsolescence"
              [widgetId]="this.selectedWidget.parameterWidgetId">
            </app-jauge-widget>
          </div>
          <div *ngIf="selectedWidget.showLastValue"
            class="{{getClassForJaugeLastValues()}} text-truncate jaugeCurrentValue mb-2">
            <app-alpha-num-widget [datedParameters]="dataparameter" [index]="i" [nbValues]="1" [idColor]="getIdColor(i)"
              [orderBy]="this.synopticConfiguration.orderBy" [widgetId]="this.selectedWidget.parameterWidgetId">
            </app-alpha-num-widget>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Widget pour trigger -->

  <div *ngIf="selectedWidget.representation + '' === 'Trigger'" class="triggerWidget" id="trigger">
    <div class="row align-items-center justify-content-md-center m-0" id="triggers">
      <ng-container *ngFor="let dataparameter of datedParameters; let i = index">
        <div class="col m-2 container-fluid" id="leftTrigger">
          <div class="row align-items-center justify-content-md-center">
            <app-trigger-widget [datedParameters]="dataparameter" [index]="i" [idColor]="getIdColor(i)"
              [orderBy]="this.synopticConfiguration.orderBy" [obsolescence]="this.selectedWidget.obsolescence">
            </app-trigger-widget>
          </div>
          <div *ngIf="selectedWidget.showLastValue"
            class="row align-items-center justify-content-md-center text-truncate">
            <app-alpha-num-widget [datedParameters]="dataparameter" [index]="i" [nbValues]="1" [idColor]="getIdColor(i)"
              [widgetId]="this.selectedWidget.parameterWidgetId">
            </app-alpha-num-widget>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</div>
