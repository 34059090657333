import {
  shallowCopy,
  Parameter,
  AbstractContainer,
  AbstractContainerImpl,
  isPacketContainer,
  PacketContainer,
} from "../../../model/spacepacket";
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Directive,
} from "@angular/core";
import { ArgumentView } from "../../../model/argumentview";
import { Form, FormGroup } from "@angular/forms";

@Component({
  selector: "app-repeat-parameter",
  templateUrl: "./repeat-parameter.component.html",
  styleUrls: ["./repeat-parameter.component.css"],
})
export class RepeatParameterComponent implements OnInit {
  @Input()
  repeatParameter: AbstractContainerImpl;

  @Input()
  form: FormGroup;

  @Input()
  argumentView: ArgumentView;

  @Input()
  lenArgument: Parameter;

  @Input()
  dynamicRefValue: number;

  parameterId: string;

  repeatNb: number;

  packetContainerForm: FormGroup;

  constructor() {}

  ngOnInit() {
    // In case of packetContainer repetition create a FormGroup parent for
    // argument of repeated packetContainer
    if (this.isPacketContainer(this.repeatParameter)) {
      this.packetContainerForm = new FormGroup({});
      this.form.addControl(
        this.generateParameterId(),
        this.packetContainerForm
      );
      this.packetContainerForm.setParent(this.form);
    }
    this.repeatNb = 1;
    if (this.haveDynamicRepeatParameterRef(this.repeatParameter)) {
      this.repeatNb = this.form.get(
        this.repeatParameter.dynamicRepeatParameterRef
      ).value;
      if (this.repeatNb == null) {
        this.repeatNb = 1;
      }
    }
    this.parameterId = this.generateParameterId();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (
        propName == "dynamicRefValue" &&
        this.haveDynamicRepeatParameterRef(this.repeatParameter)
      ) {
        if (
          this.dynamicRefValue === undefined ||
          this.dynamicRefValue === null
        ) {
          while (0 < this.repeatNb) {
            this.removeParameter();
          }
          this.dynamicRefValue = 0;
        } else if (this.dynamicRefValue > -1) {
          while (this.dynamicRefValue > this.repeatNb) {
            this.addParameter();
          }
          while (this.dynamicRefValue < this.repeatNb) {
            this.removeParameter();
          }
        }
      }
    }
  }

  haveDynamicRepeatParameterRef(parameter: AbstractContainerImpl): boolean {
    return (
      parameter.dynamicRepeat === true &&
      parameter.dynamicRepeatParameterRef !== null &&
      parameter.dynamicRepeatParameterRef !== undefined
    );
  }

  addParameter() {
    const clone = shallowCopy(this.repeatParameter);
    const indexOfFirstRepeatedParam =
      this.argumentView.argumentParameters.findIndex((arg) => {
        return this.repeatParameter.name === (<AbstractContainerImpl>arg).name;
      });
    clone.fixedRepeatNumber = 0;
    clone.dynamicRepeatParameterRef = null;
    this.argumentView.argumentParameters.splice(
      indexOfFirstRepeatedParam + this.repeatNb,
      0,
      <AbstractContainer>clone
    );
    this.repeatNb += 1;
  }

  removeParameter() {
    const indexOfFirstRepeatedParam =
      this.argumentView.argumentParameters.findIndex((arg) => {
        return this.repeatParameter.name === (<AbstractContainerImpl>arg).name;
      });
    const lastRepeatsNb = indexOfFirstRepeatedParam + this.repeatNb - 1;
    this.argumentView.argumentParameters.splice(lastRepeatsNb, 1);
    this.form.removeControl(Object.keys(this.form.controls)[lastRepeatsNb]);
    this.repeatNb -= 1;
    return false; //Return false to avoid form submission
  }

  isPacketContainer(parameter: AbstractContainerImpl): boolean {
    return isPacketContainer(parameter);
  }

  generateParameterId(): string {
    return this.repeatParameter.name + new Date().getUTCMilliseconds();
  }
}
