import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { Synoptic } from "./model/synoptic";
declare let $: any;

@Injectable()
export class SynopticListService {
  private synopticListUrl = "api/synoptic-list";

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.synopticListUrl = "http://localhost:15000/" + this.synopticListUrl;
    }
    this.handleError = httpErrorHandler.createHandleError(
      "SynopticListService"
    );
  }

  getSynopticList(): Observable<Array<Synoptic>> {
    return this.http
      .get<Array<Synoptic>>(this.synopticListUrl)
      .pipe(catchError(this.handleError("getSynopticList", [])));
  }
}
