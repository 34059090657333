<form (ngSubmit)="addWidget()" ngNativeValidate>
	<!-- [formGroup]="form"  -->
	<div class="modal-body">
		<hr>
		<div class="form-group row">
			<label for="widget-type" class="col-sm-2 col-form-label  mr-auto">Type</label>
			<div class="col-sm-8">
				<select id="widget-type" name="widget-type" class="form-control" required
					[(ngModel)]="toCreate.representation">
					<option value="Comment">Commentaire</option>
					<option value="Plot" selected>Courbe</option>
					<option value="Histogram">Histogramme</option>
					<option value="Alpha">Valeur</option>
					<option value="Gauge">Jauge</option>
					<option value="Trigger">Trigger</option>
				</select>
			</div>
		</div>
		<div *ngIf="toCreate.representation === 'Comment'">
			<hr>
			<div class="form-group row">
				<label for="title" class="col-sm-2 col-form-label  mr-auto">Titre</label>
				<div class="col-sm-8">
					<input type="text" class="form-control" id="title" name="title" placeholder="" [(ngModel)]="toCreate.title"
						maxlength="64" required />
				</div>
			</div>
			<hr>
			<div class="form-group row">
				<label for="commentaire" class="col-sm-2 col-form-label  mr-auto">Commentaire</label>
				<div class="col-sm-8">
					<textarea class="form-control" id="commentaire" name="commentaire"
						[(ngModel)]="toCreate.commentaire" rows="3" cols="40" maxlength="400"></textarea>
				</div>
			</div>
		</div>
		<div *ngIf="(toCreate.representation === 'Plot') || (toCreate.representation === 'Alpha') || (toCreate.representation === 'Histogram') ||
		 	(toCreate.representation === 'Gauge') || (toCreate.representation === 'Trigger')">
			<div *ngIf="(toCreate.representation === 'Plot') || (toCreate.representation === 'Histogram') || (toCreate.representation === 'Gauge') || (toCreate.representation === 'Trigger')" class="form-group row">
				<label for="lastValue" class="col-sm-2 col-form-label  mr-auto">Afficher derniere valeur</label>
				<div class="col-sm-8 custom-control custom-checkbox">
					<label class="custom-control custom-checkbox">
						<input type="checkbox" class="form-control custom-control-input" id="showLastValue"
							name="showLastValue" [(ngModel)]="toCreate.showLastValue">
						<span class="custom-control-indicator"></span></label>
				</div>
			</div>
			<hr>
			<div class="form-group row">
				<label for="title" class="col-sm-2 col-form-label  mr-auto">Titre</label>
				<div class="col-sm-8">
					<input class="form-control" id="title" name="title" placeholder="" [(ngModel)]="toCreate.title"
						maxlength="64" required />
				</div>
			</div>
			<hr>
			<div class="form-group row">
				<label for="commentaire" class="col-sm-2 col-form-label  mr-auto">Commentaire</label>
				<div class="col-sm-8">
					<textarea class="form-control" id="commentaire" name="commentaire"
						[(ngModel)]="toCreate.commentaire" rows="3" cols="40" maxlength="64"></textarea>
				</div>
			</div>
			<hr>
			<div *ngIf="selectedPacketsForParams.length < 10" class="form-group row">
				<label for="searchbarTm" class="col-sm-2 col-form-label  mr-auto">Ajout
					param.</label>
				<div class="col-sm-8">
					<div class="input-group" id="searchbarTm">
						<input type="text" class="form-control" autocomplete="off" data-toggle="dropdown" [(ngModel)]="packetSearch"
							name="packetSearch" (ngModelChange)="onChangeSearchPacket()"
							attr.data-target=".show-dropdown-tm-{{this.index}}"
							placeholder="Ajouter un paramètre..." />
						<div class="input-group-btn">
							<div class="btn-group" role="group">
								<div class="dropdown dropdown-lg" id="dropdown-tc-list">
									<button id="searchTCButton" class="btn btn-default rounded-0 add-on-button"
										type="button" (click)="clearSearch()">
										<span [hidden]="!edited" class="icon icon-circle-with-cross noir"
											aria-hidden="true"></span>
										<span [hidden]="edited" class="icon icon-magnifying-glass noir"
											aria-hidden="true"></span>
									</button>

								</div>
							</div>
						</div>
						<span class="show-dropdown-tm-{{this.index}} show-dropdown-tm">

							<button id="dropdownTCButton" type="button"
								class="btn btn-default  add-on-button" data-toggle="dropdown"
								aria-expanded="false">
								<span class="icon icon-plus"></span>
							</button>

							<div class="dropdown-menu dropdown-menu-right scroll-y scrollbar-style"
								id="dropdown-list-tm" role="menu">
								<ng-container *ngFor="let tmPacket of displayList">
									<button class="dropdown-item" type='button' (click)="addTm(tmPacket)">
										{{tmPacket.name}}<br />
									</button>
									<div class="dropdown-divider"></div>
								</ng-container>
							</div>
						</span>
					</div>
				</div>
			</div>
			<div class="form-group row">
				<label for="showFixedValues" class="col-sm-2 col-form-label  mr-auto"
				title="Toutes les valeurs ne sont pas pertinentes car elles sont fixées par défaut dans la base de données système et leur valeur ne change jamais. 
				On propose d'afficher par défault uniquement les paramètres utiles du paquet. ">
				Afficher les val. fixes</label>
				<div class="col-sm-8 custom-control custom-checkbox">
					<label class="custom-control custom-checkbox"> <input type="checkbox"
							class="form-control custom-control-input" name="showFixedValues" [checked]="showFixedValues"
							(change)="changeShowFixedValues()"> <span class="custom-control-indicator"></span></label>
				</div>
			</div>
			<div *ngFor="let selectedPacket of selectedPacketsForParams; let i = index" class="form-group row">
				<label class="col-sm-2 col-form-label  mr-auto">{{selectedPacket.name.substring(0,14)}}...</label>
				<div class="col-sm-8 selected-parameter">
					<div class="form-group row">
						<select class="form-control"
							(change)="onSelectParam(selectedPacket,$event.target.value, i, i, 0)" name="selected-param"
							required>
							<option disabled selected value>-- selectionner un
								paramètre --</option>
							<option *ngFor="let parameter of getParametersFromTm(i)" value="{{parameter.name}}"
								[selected]="selectedParams[i] !== undefined && parameter.name === selectedParams[i].selectedParamName">
								{{parameter.name}}</option>
						</select><a class="delete pointer-hover" (click)="chooseToDelete(i)"><span
								class="icon icon-cross"></span></a>
					</div>

					<div *ngIf="this.selectedParams[i]" class="row justify-content-end">

						<div *ngIf="(toCreate.representation === 'Plot') || (toCreate.representation === 'Histogram')" class="col-sm-4 text-left" id="axis-selection">
							<div class="form-check">
								<input class="form-check-input pointer-hover" type="radio"
									[(ngModel)]="this.selectedParams[i].selectedYAxis" [name]="'selectedYAxis'+ i"
									(change)="onChangeYAxis()" id="exampleRadios1" [value]="0">
								<label class="form-check-label">
									Left Axis
								</label>
							</div>
							<div class="form-check">
								<input class="form-check-input pointer-hover" type="radio"
									[(ngModel)]="this.selectedParams[i].selectedYAxis" [name]="'selectedYAxis'+ i"
									(change)="onChangeYAxis()" id="exampleRadios2" [value]="1">
								<label class="form-check-label">
									Right Axis
								</label>
							</div>
						</div>

						<div class="col-sm-8 text-right" id="color-selection">
							<span class="icon-stack pointer-hover"><i
									class="icon icon-controller-record icon-stack-base" [style.color]="getColor(0)"
									(click)="setColor(i, 0)"></i>
								<i *ngIf="getNumberColor(i) == 0" class="icon icon-circle"></i></span>
							<span class="icon-stack pointer-hover"><i
									class="icon icon-controller-record icon-stack-base" [style.color]="getColor(1)"
									(click)="setColor(i, 1)"></i>
								<i *ngIf="getNumberColor(i) == 1" class="icon icon-circle"></i></span>
							<span class="icon-stack pointer-hover"><i
									class="icon icon-controller-record icon-stack-base" [style.color]="getColor(2)"
									(click)="setColor(i, 2)"></i>
								<i *ngIf="getNumberColor(i) == 2" class="icon icon-circle"></i></span>
							<span class="icon-stack pointer-hover"><i
									class="icon icon-controller-record icon-stack-base" [style.color]="getColor(3)"
									(click)="setColor(i, 3)"></i>
								<i *ngIf="getNumberColor(i) == 3" class="icon icon-circle"></i></span>
							<span class="icon-stack pointer-hover"><i
									class="icon icon-controller-record icon-stack-base" [style.color]="getColor(4)"
									(click)="setColor(i, 4)"></i>
								<i *ngIf="getNumberColor(i) == 4" class="icon icon-circle"></i></span><br>
							<span class="icon-stack pointer-hover"><i
									class="icon icon-controller-record icon-stack-base" [style.color]="getColor(5)"
									(click)="setColor(i, 5)"></i>
								<i *ngIf="getNumberColor(i) == 5" class="icon icon-circle"></i></span>
							<span class="icon-stack pointer-hover"><i
									class="icon icon-controller-record icon-stack-base" [style.color]="getColor(6)"
									(click)="setColor(i, 6)"></i>
								<i *ngIf="getNumberColor(i) == 6" class="icon icon-circle"></i></span>
							<span class="icon-stack pointer-hover"><i
									class="icon icon-controller-record icon-stack-base" [style.color]="getColor(7)"
									(click)="setColor(i, 7)"></i>
								<i *ngIf="getNumberColor(i) == 7" class="icon icon-circle"></i></span>
							<span class="icon-stack pointer-hover"><i
									class="icon icon-controller-record icon-stack-base" [style.color]="getColor(8)"
									(click)="setColor(i, 8)"></i>
								<i *ngIf="getNumberColor(i) == 8" class="icon icon-circle"></i></span>
							<span class="icon-stack pointer-hover"><i
									class="icon icon-controller-record icon-stack-base" [style.color]="getColor(9)"
									(click)="setColor(i, 9)"></i>
								<i *ngIf="getNumberColor(i) == 9" class="icon icon-circle"></i></span>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group row">
				<label for="obsolescence" class="col-sm-2 col-form-label  mr-auto">Obsolesence</label>
				<div class="input-group col-sm-8">
					<input class="form-control" id="obsolescence" name="obsolescence" type="number" step="1" min="0"
						placeholder="" [(ngModel)]="toCreate.obsolescence" /><span class="input-group-addon"
						id="basic-addon2">s</span>
				</div>
			</div>
			<ng-container *ngIf="toCreate.representation !== 'Gauge' && toCreate.representation !== 'Trigger'">
				<hr>
				<div class="form-group row">
					<label for="nbValues" class="col-sm-2 col-form-label  mr-auto">Nb.
						valeurs max</label>
					<div class="col-sm-8">
						<ng-container *ngIf="toCreate.representation === 'Alpha'; else noLimit">
							<input class="form-control" id="nbValues" name="nbValues" type="number" step="1" min="0" max="20"
								placeholder="" [(ngModel)]="toCreate.nbValues" required />
						</ng-container>
						<ng-template #noLimit>
							<input class="form-control" id="nbValues" name="nbValues" type="number" step="1" min="0"
								placeholder="" [(ngModel)]="toCreate.nbValues" required />
						</ng-template>
					</div>
				</div>
			</ng-container>
		</div>
		<hr>
		<div *ngIf="sameColor()" class="critical"><i class="icon icon-warning"></i> Des paramètres ont la même couleur </div>
	</div>
	<div class="modal-actions">
		<button type="button" class="btn btn-sm btn-pill btn-danger" data-dismiss="modal">Annuler</button>
		<button type="submit" class="btn btn-sm btn-pill btn-success">
			<strong>
				<ng-container *ngIf="isModify">Modifier</ng-container>
				<ng-container *ngIf="!isModify">Ajouter</ng-container>
			</strong>
		</button>
	</div>
</form>