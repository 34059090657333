// ------------------ The model

export class Synoptic {
  synopticId: number;

  title: string;

  description: string;

  chartString: string;
}

export class SelectedParameter {
  selectedPacketName: string;

  selectedParamName: string;

  selectedColor: number;

  selectedYAxis: number;
}

export class ParameterWidget {
  parameterWidgetId: number;

  title: string;

  /* Parameter */
  tmType: string;
  parameters: string;

  /* Representation */
  representation: Representation;
  showLastValue: boolean;

  obsolescence: number;
  nbValues: number;

  commentaire: string;

  /* Synoptic */
  synopticId: number;
}

export enum Representation {
  Plot = "Plot",
  Histogram = "Histogram",
  Alpha = "Alpha",
  Gauge = "Gauge",
  Trigger = "Trigger",
  Comment = "Comment",
}
