//import { PACKETS } from './mock-tc-packets';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { SpacePacket } from "./model/spacepacket";
declare let $: any;

@Injectable()
export class TcPacketListService {
  private tcPacketsUrl = "api/tc-packet-list";

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.tcPacketsUrl = "http://localhost:15000/" + this.tcPacketsUrl;
    }
    this.handleError = httpErrorHandler.createHandleError(
      "TcPacketListService"
    );
  }

  getTcPacketListString(): Observable<Array<string>> {
    //              return Promise.resolve(PACKETS);

    return this.http
      .get<Array<string>>(this.tcPacketsUrl)
      .pipe(catchError(this.handleError("getTcPacketListString", [])));
  }

  getTcPacketList(): Observable<Array<SpacePacket>> {
    //              return Promise.resolve(PACKETS);
    return this.http
      .get<Array<SpacePacket>>(this.tcPacketsUrl)
      .pipe(catchError(this.handleError("getTcPacketList", [])));
  }
}
