import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { Criticality, formatDate, TmPacket } from "./model/spacepacket";
import { TmPacketsConfiguration } from "./model/tm-packets-configuration";
declare let $: any;

@Injectable()
export class TmPacketsService {
  private tmPacketsUrl = "api/tm-packets";

  tmPacketsConfiguration: TmPacketsConfiguration = new TmPacketsConfiguration();

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.tmPacketsUrl = "http://localhost:15000/" + this.tmPacketsUrl;
    }
    this.handleError = httpErrorHandler.createHandleError("TmPacketsService");
  }

  getTmPackets(): Observable<Array<TmPacket>> {
    let filterDateURL = "";

    if (this.tmPacketsConfiguration.realTime) {
      filterDateURL = "?start=realTime";
    } else {
      filterDateURL = formatDate(
        this.tmPacketsConfiguration.dateStart,
        this.tmPacketsConfiguration.timeStart,
        this.tmPacketsConfiguration.dateEnd,
        this.tmPacketsConfiguration.timeEnd
      );
    }

    let filterPacketsURL = "";
    const nbPacketsURL =
      "&maxLimit=" + this.tmPacketsConfiguration.nbPaquetsMax;
    this.tmPacketsConfiguration.selectList.forEach(
      (value: boolean, key: string) => {
        if (value) {
          filterPacketsURL += "&filter=" + key;
        }
      }
    );
    const orderByURL = "&orderBy=" + this.tmPacketsConfiguration.orderBy;
    return this.http
      .get<Array<TmPacket>>(
        this.tmPacketsUrl +
          filterDateURL +
          filterPacketsURL +
          nbPacketsURL +
          orderByURL
      )
      .pipe(
        map((tmPackets) => {
          return tmPackets.map((tmPacket) => {
            return this.mapStatusToCriticality(tmPacket);
          });
        })
      )
      .pipe(catchError(this.handleError("getTmPackets", [])));
  }

  private mapStatusToCriticality(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if ("status" in obj) {
      const criticalityString = obj.status;
      const criticality = Criticality[criticalityString];
      if (criticality !== undefined) {
        obj.status = criticality;
      }
    }

    for (const key in obj) {
      obj[key] = this.mapStatusToCriticality(obj[key]);
    }

    return obj;
  }
}
