import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  Directive,
} from "@angular/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  constructor(private elementRef: ElementRef) {}

  ngOnInit() {}
}
