import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class PopoverTourService {
  private static _instance: PopoverTourService;
  private doneSequences: Array<number> = new Array<number>();

  private _sequenceIndex = 0;

  private constructor() {
    if (!environment.popOverTour) {
      //Disable popover tour on development mode
      this.doneSequences.push(0);
      this.doneSequences.push(100);
      this.doneSequences.push(200);
      this.doneSequences.push(300);
      this.sequenceIndex = -1;
    }
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public get SequenceIndex() {
    return this._sequenceIndex;
  }

  public set sequenceIndex(value: number) {
    if (this.doneSequences.findIndex((item) => item === value) !== -1) {
      this._sequenceIndex = -1;
    } else {
      this._sequenceIndex = value;
    }
  }

  public nextSequence() {
    this._sequenceIndex += 1;
  }

  public previousSequence() {
    this._sequenceIndex -= 1;
  }

  public quitSequence() {
    if (this._sequenceIndex >= 300 && this._sequenceIndex < 400) {
      //This interval is for TC view
      this.doneSequences.push(300);
    } else if (this._sequenceIndex >= 200 && this._sequenceIndex < 300) {
      //This interval is for TC view
      this.doneSequences.push(200);
    } else if (this._sequenceIndex >= 100 && this._sequenceIndex < 200) {
      //This interval is for TM view
      this.doneSequences.push(100);
    } else if (this._sequenceIndex >= 0 && this._sequenceIndex < 100) {
      //This interval is for App/Home view
      this.doneSequences.push(0);
    }

    this._sequenceIndex = -1;
  }
}
