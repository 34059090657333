<div
  id="tm-packets-view"
  class="main-container container-fluid with-top-navbar"
>
  <div class="row reduced-gutters">
    <div class="col-6 col-lg-7 col-xl-7">
      <div class="row reduced-gutters">
        <div class="col-lg-4 col-xl-4" id="sidebar-tm-packets">
          <app-tm-packets-sidebar
            [tmList]="tmList"
            [showDateChoice]="showDateChoice"
            [showNbPacketChoice]="showNbPacketChoice"
          ></app-tm-packets-sidebar>
        </div>
        <div class="col-lg-8 col-xl-8">
          <div id="paquet-list" class="card card-grey">
            <div
              class="card-header card-grey"
              id="help-packets-list"
              data-container="body"
              data-html="true"
              data-placement="bottom"
              data-trigger="manual"
              data-title="Liste des paquets"
              data-content="Affiche la liste des paquets de télémesure
					<br> Possibilité de trier par 
					<br><span class='icon icon-dot-single'>Date de génération à bord
					<br><span class='icon icon-dot-single'>Date de réception au sol
					<br> Les paquets reçus ont 6 états possibles : 
					<br><span class='icon icon-controller-record nominal'></span> Nominal
					<br><span class='icon icon-controller-record watch'></span> Watch
					<br><span class='icon icon-controller-record warning'></span> Warning
					<br><span class='icon icon-controller-record distress'></span> Distress
					<br><span class='icon icon-controller-record critical'></span> Critical
					<br><span class='icon icon-controller-record severe'></span> Severe"
            >
              <h6><strong>Liste des paquets</strong></h6>
              <span data-toggle="button">
                <button
                  *ngIf="nbPacketsSevere > 0 || displaySevere"
                  type="button"
                  class="btn btn-xs btn-pill btn-severe"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Severe"
                  data-delay='{"show":"500", "hide":"0"}'
                  data-trigger="hover"
                  aria-pressed="false"
                  autocomplete="off"
                  id="tooltip-severe"
                  (click)="toggleSevereStatus()"
                >
                  {{ nbPacketsSevere }}
                </button>
              </span>
              <span data-toggle="button">
                <button
                  *ngIf="nbPacketsCritical > 0 || displayCritical"
                  type="button"
                  class="btn btn-xs btn-pill btn-critical"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Critical"
                  data-delay='{"show":"500", "hide":"0"}'
                  data-trigger="hover"
                  aria-pressed="false"
                  autocomplete="off"
                  id="tooltip-critical"
                  (click)="toggleCriticalStatus()"
                >
                  {{ nbPacketsCritical }}
                </button>
              </span>
              <span data-toggle="button">
                <button
                  *ngIf="nbPacketsDistress > 0 || displayDistress"
                  type="button"
                  class="btn btn-xs btn-pill btn-distress"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Distress"
                  data-delay='{"show":"500", "hide":"0"}'
                  data-trigger="hover"
                  aria-pressed="false"
                  autocomplete="off"
                  id="tooltip-distress"
                  (click)="toggleDistressStatus()"
                >
                  {{ nbPacketsDistress }}
                </button>
              </span>
              <span data-toggle="button">
                <button
                  *ngIf="nbPacketsWarning > 0 || displayWarning"
                  type="button"
                  class="btn btn-xs btn-pill btn-warning"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Warning"
                  data-delay='{"show":"500", "hide":"0"}'
                  data-trigger="hover"
                  aria-pressed="false"
                  autocomplete="off"
                  id="tooltip-warning"
                  (click)="toggleWarningStatus()"
                >
                  {{ nbPacketsWarning }}
                </button>
              </span>
              <span data-toggle="button">
                <button
                  *ngIf="nbPacketsWatch > 0 || displayWatch"
                  type="button"
                  class="btn btn-xs btn-pill btn-watch"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Watch"
                  data-delay='{"show":"500", "hide":"0"}'
                  data-trigger="hover"
                  aria-pressed="false"
                  autocomplete="off"
                  id="tooltip-watch"
                  (click)="toggleWatchStatus()"
                >
                  {{ nbPacketsWatch }}
                </button>
              </span>
              <span data-toggle="button">
                <button
                  *ngIf="nbPacketsNominal > 0 || displayNominal"
                  type="button"
                  class="btn btn-xs btn-pill btn-nominal"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Nominal"
                  data-delay='{"show":"500", "hide":"0"}'
                  data-trigger="hover"
                  aria-pressed="false"
                  autocomplete="off"
                  id="tooltip-nominal"
                  (click)="toggleNominalStatus()"
                >
                  {{ nbPacketsNominal }}
                </button>
              </span>
            </div>
            <div
              class="card-block scroll-y scrollbar-style"
              id="scroll-packet-list"
            >
              <table
                #table
                class="table table-sm"
                data-sort="table"
                (change)="updateStatusCount()"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Id</th>
                    <th>Nom</th>
                    <th
                      class="headerSortable"
                      (click)="changeSorting('onBordTime')"
                      [ngClass]="{
                        headerSort:
                          tmPacketsConfiguration.orderBy === 'onBordTime'
                      }"
                    >
                      Date bord
                      <div
                        *ngIf="this.popoverTourService.SequenceIndex === 100"
                        class="popover bs-tether-element-attached-top help-tour-popover"
                        role="tooltip"
                      >
                        <div class="arrow"></div>
                        <h3 class="popover-title">
                          Liste des paquets TM
                          <button
                            (click)="this.popoverTourService.quitSequence()"
                            type="button"
                            class="close popover-close"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </h3>
                        <div class="popover-content">
                          Cliquer sur "Date bord" afin de trier les paquets TM
                          reçus par date bord.<br />
                        </div>
                        <div class="popover-footer">
                          <button
                            (click)="this.popoverTourService.quitSequence()"
                            type="button"
                            class="btn btn-default add-on-button mr-4"
                          >
                            Quitter
                          </button>
                          <button
                            (click)="this.popoverTourService.nextSequence()"
                            type="button"
                            class="btn btn-primary"
                          >
                            Suivant
                          </button>
                        </div>
                      </div>
                    </th>
                    <th
                      class="headerSortable"
                      (click)="changeSorting('onReceptionTime')"
                      [ngClass]="{
                        headerSort:
                          tmPacketsConfiguration.orderBy === 'onReceptionTime'
                      }"
                    >
                      Date de réception
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let packet of tmpackets; let indexOfelement = index"
                    [class.selected]="packet === selectedPacket"
                    class="pointer-hover"
                    (click)="onSelect(packet)"
                  >
                    <ng-container *ngIf="displayNominal === true">
                      <td *ngIf="packetStatusIsNominal(packet)" class="status">
                        <span
                          class="icon icon-controller-record nominal"
                        ></span>
                      </td>
                      <td *ngIf="packetStatusIsNominal(packet)">
                        {{ packet.id }}
                      </td>
                      <td *ngIf="packetStatusIsNominal(packet)">
                        {{ packet.packetType }}
                      </td>
                      <td *ngIf="packetStatusIsNominal(packet)">
                        {{ formatDate(packet.onBoardTime) }}
                      </td>
                      <td *ngIf="packetStatusIsNominal(packet)">
                        {{ formatDate(packet.receptionTime) }}
                      </td>
                    </ng-container>

                    <ng-container *ngIf="displayWatch === true">
                      <td *ngIf="packetStatusIsWatch(packet)" class="status">
                        <span class="icon icon-controller-record watch"></span>
                      </td>
                      <td *ngIf="packetStatusIsWatch(packet)">
                        {{ packet.id }}
                      </td>
                      <td *ngIf="packetStatusIsWatch(packet)">
                        {{ packet.packetType }}
                      </td>
                      <td *ngIf="packetStatusIsWatch(packet)">
                        {{ formatDate(packet.onBoardTime) }}
                      </td>
                      <td *ngIf="packetStatusIsWatch(packet)">
                        {{ formatDate(packet.receptionTime) }}
                      </td>
                    </ng-container>

                    <ng-container *ngIf="displayWarning === true">
                      <td *ngIf="packetStatusIsWarning(packet)" class="status">
                        <span
                          class="icon icon-controller-record warning"
                        ></span>
                      </td>
                      <td *ngIf="packetStatusIsWarning(packet)">
                        {{ packet.id }}
                      </td>
                      <td *ngIf="packetStatusIsWarning(packet)">
                        {{ packet.packetType }}
                      </td>
                      <td *ngIf="packetStatusIsWarning(packet)">
                        {{ formatDate(packet.onBoardTime) }}
                      </td>
                      <td *ngIf="packetStatusIsWarning(packet)">
                        {{ formatDate(packet.receptionTime) }}
                      </td>
                    </ng-container>

                    <ng-container *ngIf="displayDistress === true">
                      <td *ngIf="packetStatusIsDistress(packet)" class="status">
                        <span
                          class="icon icon-controller-record distress"
                        ></span>
                      </td>
                      <td *ngIf="packetStatusIsDistress(packet)">
                        {{ packet.id }}
                      </td>
                      <td *ngIf="packetStatusIsDistress(packet)">
                        {{ packet.packetType }}
                      </td>
                      <td *ngIf="packetStatusIsDistress(packet)">
                        {{ formatDate(packet.onBoardTime) }}
                      </td>
                      <td *ngIf="packetStatusIsDistress(packet)">
                        {{ formatDate(packet.receptionTime) }}
                      </td>
                    </ng-container>

                    <ng-container *ngIf="displayCritical === true">
                      <td *ngIf="packetStatusIsCritical(packet)" class="status">
                        <span
                          class="icon icon-controller-record critical"
                        ></span>
                      </td>
                      <td *ngIf="packetStatusIsCritical(packet)">
                        {{ packet.id }}
                      </td>
                      <td *ngIf="packetStatusIsCritical(packet)">
                        {{ packet.packetType }}
                      </td>
                      <td *ngIf="packetStatusIsCritical(packet)">
                        {{ formatDate(packet.onBoardTime) }}
                      </td>
                      <td *ngIf="packetStatusIsCritical(packet)">
                        {{ formatDate(packet.receptionTime) }}
                      </td>
                    </ng-container>

                    <ng-container *ngIf="displaySevere === true">
                      <td *ngIf="packetStatusIsSevere(packet)" class="status">
                        <span class="icon icon-controller-record severe"></span>
                      </td>
                      <td *ngIf="packetStatusIsSevere(packet)">
                        {{ packet.id }}
                      </td>
                      <td *ngIf="packetStatusIsSevere(packet)">
                        {{ packet.packetType }}
                      </td>
                      <td *ngIf="packetStatusIsSevere(packet)">
                        {{ formatDate(packet.onBoardTime) }}
                      </td>
                      <td *ngIf="packetStatusIsSevere(packet)">
                        {{ formatDate(packet.receptionTime) }}
                      </td>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        displayNominal === false &&
                        displayWatch === false &&
                        displayWarning === false &&
                        displayWarning === false &&
                        displayDistress === false &&
                        displayCritical === false &&
                        displaySevere === false
                      "
                    >
                      <td class="status">
                        <span
                          *ngIf="packetStatusIsSevere(packet)"
                          class="icon icon-controller-record severe"
                        ></span>
                        <span
                          *ngIf="packetStatusIsCritical(packet)"
                          class="icon icon-controller-record critical"
                        ></span>
                        <span
                          *ngIf="packetStatusIsDistress(packet)"
                          class="icon icon-controller-record distress"
                        ></span>
                        <span
                          *ngIf="packetStatusIsWarning(packet)"
                          class="icon icon-controller-record warning"
                        ></span>
                        <span
                          *ngIf="packetStatusIsWatch(packet)"
                          class="icon icon-controller-record watch"
                        ></span>
                        <span
                          *ngIf="packetStatusIsNominal(packet)"
                          class="icon icon-controller-record nominal"
                        ></span>
                      </td>
                      <td>
                        {{ packet.id }}
                        <div
                          *ngIf="
                            this.popoverTourService.SequenceIndex === 101 &&
                            indexOfelement === 1
                          "
                          class="popover bs-tether-element-attached-top help-tour-popover"
                          role="tooltip"
                        >
                          <div class="arrow"></div>
                          <h3 class="popover-title">
                            Liste des paquets TM
                            <button
                              (click)="this.popoverTourService.quitSequence()"
                              type="button"
                              class="close popover-close"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </h3>
                          <div class="popover-content">
                            Cliquer sur un paquet TM afin d'explorer son
                            contenu.<br />
                          </div>
                          <div class="popover-footer">
                            <button
                              (click)="
                                this.popoverTourService.previousSequence()
                              "
                              type="button"
                              class="btn btn-default add-on-button mr-4"
                            >
                              Précédent
                            </button>
                            <button
                              (click)="this.popoverTourService.nextSequence()"
                              type="button"
                              class="btn btn-primary"
                            >
                              Suivant
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>{{ packet.packetType }}</td>
                      <td>{{ formatDate(packet.onBoardTime) }}</td>
                      <td>{{ formatDate(packet.receptionTime) }}</td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-5 col-xl-5">
      <div id="packet-explorer-container" class="card card-grey">
        <div
          class="card-header card-grey"
          id="help-paquet-explorer"
          data-container="body"
          data-html="true"
          data-placement="bottom"
          data-trigger="manual"
          data-title="Explorateur de paquets"
          data-content="Permet de naviguer dans le paquet selectionné dans la <b>Liste des paquets</b>"
        >
          <h6><strong>Explorateur de paquets</strong></h6>
          <a
            data-toggle="modal"
            class="icon icon-download pointer-hover"
            data-target="#downloadTm"
          ></a>
        </div>
        <div class="card-block scroll-y scrollbar-style">
          <div *ngIf="selectedPacket" class="list-group list-group-root">
            <a
              (click)="onSelect(selectedPacket)"
              href="#{{ selectedPacket.spacePacket.rootContainer.name }}"
              class="list-group-item list-group-item-action root {{
                returnCriticalityClass(selectedPacket.status)
              }}"
              data-toggle="collapse"
              aria-expanded="true"
            >
              {{ selectedPacket.spacePacket.rootContainer.name }}
              <button
                type="button"
                class="btn btn-xs btn-secondary raw-packet-copie-button"
                id="copie-hex-tm"
                (click)="onCopieHexPacket(selectedPacket.rawPacket)"
                data-toggle="tooltip"
                data-placement="left"
                data-html="true"
                attr.data-original-title="Taille: {{
                  getLengthPacket(selectedPacket.rawPacket)
                }} octets<br>Valeur de length: {{
                  getLengthPacket(selectedPacket.rawPacket) - 7
                }} octets<br>{{ selectedPacket.rawPacket }}"
                data-delay='{"show":"300", "hide":"0"}'
                data-trigger="hover"
              >
                <span>Copier HEX</span>
              </button>
              <br /><span id="explorer-description-tm" class="text-muted">{{
                selectedPacket.spacePacket.description
              }}</span>
            </a>
            <div
              class="collapse show collapse-container"
              id="{{ selectedPacket.spacePacket.rootContainer.name }}"
            >
              <div class="list-group">
                <app-tm-packets-explorer
                  class="anchor-left-padding"
                  [packetContainer]="selectedPacket.spacePacket.rootContainer"
                  (selectedContainerChange)="selectedContainerChange($event)"
                ></app-tm-packets-explorer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="parameter-list">
        <app-parameters-details [(selectedContainer)]="selectedContainer">
        </app-parameters-details>
      </div>
    </div>
  </div>
</div>

<!-- Definition of modal for download of TM -->

<div
  class="modal fade"
  id="downloadTm"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="semi-transparent modal-title">Download Tm</h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <hr />
          <div *ngIf="this.tmPacketsConfiguration.realTime; else elseBlock">
            Temps réel.
          </div>
          <div>
            <ng-template #elseBlock>
              du {{ this.tmPacketsConfiguration.dateStart }}
              {{ this.tmPacketsConfiguration.timeStart }}
              <br />
              au {{ this.tmPacketsConfiguration.dateEnd }}
              {{ this.tmPacketsConfiguration.timeEnd }}.
            </ng-template>
          </div>
          <br />
          {{ getMessageNbPacket() }}
          <hr />
        </div>

        <form name="form" (ngSubmit)="getUrlForPacketExport()" ngNativeValidate>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label mr-auto" for="nameTms"
                >TM</label
              >
              <div class="col-sm-10">
                <input
                  list="tms"
                  class="form-control"
                  name="namePacketToDownload"
                  [(ngModel)]="this.namePacketToDownload"
                  required
                />
                <datalist class="modal-dialog" id="tms">
                  <div *ngFor="let packet of packetsList">
                    <option value="{{ packet.name }}"></option>
                  </div>
                </datalist>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 col-form-label mr-auto" for="maxLimit"
                >Nombre de paquets</label
              >
              <div class="col-sm-7">
                <input
                  type="number"
                  class="form-control"
                  placeholder=""
                  name="maxLimitDownload"
                  id="maxLimit"
                  min="-1"
                  [(ngModel)]="this.maxLimitDownload"
                  required
                />
                <span class="text-muted">-1 pour une infinité de valeurs</span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 col-form-label mr-auto" for="orderBy"
                >Critère de tri
              </label>
              <div class="col-sm-7">
                <select
                  class="form-control"
                  id="orderBy"
                  name="orderByDownload"
                  [(ngModel)]="this.orderByDownload"
                  required
                >
                  <option value="onBordTime">Date bord</option>
                  <option value="onReceptionTime">Date de réception</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 col-form-label mr-auto" for="format"
                >Format</label
              >
              <div class="col-sm-7">
                <select
                  class="form-control"
                  id="format"
                  name="format"
                  [(ngModel)]="this.format"
                  required
                >
                  <option value="csv">CSV</option>
                  <option value="tsv">TSV</option>
                  <option value="bin">BIN</option>
                </select>
              </div>
            </div>
            <hr />
          </div>
          <div class="modal-actions">
            <button
              type="button"
              class="btn btn-sm btn-pill btn-danger"
              data-dismiss="modal"
            >
              Annuler
            </button>
            <button
              type="submit"
              class="btn btn-sm btn-pill btn-success"
              value="Submit"
            >
              <strong>Charger</strong>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
