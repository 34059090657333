import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { Synoptic } from "./model/synoptic";
declare let $: any;

@Injectable()
export class SynopticService {
  private synopticUrl = "api/synoptic";

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.synopticUrl = "http://localhost:15000/" + this.synopticUrl;
    }
    this.handleError = httpErrorHandler.createHandleError("SendTcService");
  }

  getSynoptic(id: number): Synoptic {
    //        return this.synopticHandler.getSynoptic( id );
    return null;
  }

  addSynoptic(synoptic: Synoptic) {
    return this.http
      .put(this.synopticUrl, synoptic, this.httpOptions)
      .pipe(catchError(this.handleError("addSynoptic", [])));
  }

  updateSynoptic(synoptic: Synoptic) {
    return this.http
      .post(this.synopticUrl, synoptic, this.httpOptions)
      .pipe(catchError(this.handleError("updateSynoptic", [])));
  }

  deleteSynoptic(synopticId: number) {
    return this.http
      .delete(this.synopticUrl + "?id=" + synopticId, this.httpOptions)
      .pipe(catchError(this.handleError("deleteSynoptic", [])));
  }
}
