<div id="synoptic-view" class="main-container container-fluid with-top-navbar">
  <div class="row reduced-gutters">
    <div class="col-lg-3 col-xl-2" id="sidebar-tm-packets">
      <app-synoptic-sidebar [synopticList]="synopticList" (selectedSynopticChange)="selectedSynopticChange($event)">
      </app-synoptic-sidebar>
    </div>
    <div *ngIf="selected" class="col-lg-9 col-xl-10">
      <i class="icon icon-cog pointer-hover" style="font-size:25px;" title="Modifier le synoptic" data-toggle="modal"
        [attr.data-target]="'#modif-synoptic-' + selected.synopticId"></i>
      <h2 class="synoptic-title" id="help-synoptic" data-container="body" data-html="true" data-placement="bottom"
      data-trigger="manual" data-title="Synoptique" data-content="Liste des commandes des widgets :
      <br><span class='icon icon-ccw'></span> Reinitialiser le zoom
      <br><span class='icon icon-menu'></span> Afficher/masquer les seuils
      <br><span class='icon icon-resize-full-screen'></span> Afficher le widget en plein écran
      <br><span class='icon icon-cog'></span> Modifier le widget
      <br><span class='icon icon-cross'></span> Supprimer le widget
      <br><br> Afficher/masquer une paramètre sur une courbe ou un histogramme : cliquer sur le nom du paramètre.
      <br><br> Zoom molette dans un synoptique : Ctrl + molette souris. Fonctionne sur les axes pour ne zoomer que dans une seule direction.
      <br><br> Drag-and-zoom dans un synoptique : Alt + maintenir clic gauche et tracer la zone sur laquelle zoomer.
      "> {{selected.title}}</h2>
      <p *ngIf="selected.description" class="synoptic-description">
        {{selected.description}}</p>
      <button class="btn btn-pill btn-outline-primary pointer-hover" id="add-widget" type="submit" data-toggle="modal"
        data-target="#ajout-widget-modal">
        Ajout Widget
      </button>

      <div *ngIf="this.popoverTourService.SequenceIndex == 301" class="popover bs-tether-element-attached-top help-tour-popover popover-add-widget" role="tooltip">
        <h3 class="popover-title">
          Ajout Widget
          <button (click)="this.popoverTourService.quitSequence()" type="button" class="close popover-close" aria-label="Close">
          <span aria-hidden="true" >&times;</span>
          </button>
        </h3>
        <div class="popover-content">
          Cliquer sur "Ajout Widget" afin d'ajouter un widget dans votre vue synoptique.
        </div>
        <div class="popover-footer">
          <button (click)="this.popoverTourService.previousSequence()" type="button" class="btn btn-default add-on-button mr-4">Précédent</button>
          <button	(click)="this.popoverTourService.nextSequence()" type="button" class="btn btn-primary">Suivant</button>
        </div>
      </div>

      <hr class="title-line">
      <div class="grid-stack" data-gs-animate="yes" data-gs-height="8" data-gs-width="12">
        <ng-container *ngFor="let widget of synopticWidgets; let i = index">
          <div class="grid-stack-item">
            <div app-display-widget id="widget-{{i}}" class="grid-stack-item-content card card-synoptic-item"
              [selectedWidget]="widget" [widgetIndex]="i" [inZoom]="false"></div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="this.popoverTourService.SequenceIndex == 302" class="popover bs-tether-element-attached-top help-tour-popover" role="tooltip">
        <h3 class="popover-title">
          Widget
          <button (click)="this.popoverTourService.quitSequence()" type="button" class="close popover-close" aria-label="Close">
          <span aria-hidden="true" >&times;</span>
          </button>
        </h3>
        <div class="popover-content">
          Le widget est ajouté dans la vue, déplacer et redimenssioner votre widget en le sélectionnant.
        </div>
        <div class="popover-footer">
          <button (click)="this.popoverTourService.previousSequence()" type="button" class="btn btn-default add-on-button mr-4">Précédent</button>
          <button	(click)="this.popoverTourService.nextSequence()" type="button" class="btn btn-primary">Suivant</button>
        </div>
      </div>

    </div>


    <!-- 		Modal for synoptic delete confirmation -->
    <ng-container *ngFor="let widget of synopticWidgets; let i = index">
      <div class="modal fade" id="delete-widget-{{i}}" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="semi-transparent modal-title">Supprimer un Widget
              </h6>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form (ngSubmit)="deleteWidget(widget,i)">
              <div class="modal-body">
                <p>Vous confirmez la suppression du widget suivant :</p>
                <p>Titre : {{widget.title}}</p>
                <p>Description : {{widget.commentaire}}</p>
                <hr class="mb-0">
              </div>
              <div class="modal-actions">
                <button type="button" class="btn btn-sm btn-pill btn-danger" data-dismiss="modal">Annuler</button>
                <button type="submit" class="btn btn-sm btn-pill btn-success" id="deleteWidget">
                  <strong>Confirmer</strong>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- 		Modal for widget zoom -->
    <ng-container *ngFor="let widget of synopticWidgets; let i = index">
      <div class="modal fade" id="zoom-widget-{{i}}" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
              <div app-display-widget *ngIf="isShownModal(i)" class="grid-stack-item-content card card-synoptic-item"
                [selectedWidget]="widget" [widgetIndex]="i" [inZoom]="true"></div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- 		Modal pour ajout widget -->

    <div *ngIf="selected" class="modal fade" id="ajout-widget-modal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="semi-transparent modal-title">Ajouter un Widget</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <app-create-widget [synopticId]="selected.synopticId" [packetsList]="packetsList"
            (newWidget)="newWidget($event)"></app-create-widget>
        </div>
      </div>
    </div>

    <!-- 		Modal pour modification widget -->

    <ng-container *ngFor="let widget of synopticWidgets; let i = index">
      <div id="modif-widget-modal">
        <div *ngIf="selected" class="modal fade modif-widget" id="modif-widget-modal-{{i}}" tabindex="-1" role="dialog"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h6 class="semi-transparent modal-title">Modifier un Widget</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <app-create-widget [synopticId]="selected.synopticId" [isModify]="true" [packetsList]="packetsList"
                [widgetToModif]="widget" (newWidget)="updatedWidget($event, i)" [index]=i></app-create-widget>
            </div>
          </div>
        </div>
      </div>
    </ng-container>


    <!-- 		Modal for synoptic modification -->
    <ng-container *ngFor="let synoptic of synopticList">
      <div class="modal fade" [attr.id]="'modif-synoptic-' + synoptic.synopticId" tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="semi-transparent modal-title">Créer un Synoptique</h6>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <app-create-synoptic [isModify]="true" [synopticToModify]="synoptic"
              (synopticToSend)="modifySynoptic($event)"></app-create-synoptic>
          </div>
        </div>
      </div>
    </ng-container>

  </div>

</div>
