import {
  Parameter,
  ValueType,
  isEnum,
  isPolynomialCalibration,
  Calibration,
  PolynomialCalibration,
  addZerosDigitstoTime,
  addZeros9Digit,
  addZerosDigitToHex,
} from "../../../model/spacepacket";
import { Component, OnInit, Input, Directive } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { validateMinFactory } from "../../../validators/min-validator.directive";
import { validateMaxFactory } from "../../../validators/max-validator.directive";
import { validateIsIntegerFactory } from "../../../validators/integer-validator.directive";
import { validateIsFloatFactory } from "../../../validators/float-validator.directive";
import { validateNanoLengthFactory } from "../../../validators/nano-validator.directive";
import { validateIsHexBinary } from "../../../validators/binary-validator.directive";
import { validateMaxHexFactory } from "../../../validators/max-hex-validator.directive";
import { validateSecondLengthFactory } from "../../../validators/second-validator.directive";

declare let $: any;

@Component({
  selector: "app-parameter",
  templateUrl: "./parameter.component.html",
  styleUrls: ["./parameter.component.css"],
})
export class ParameterComponent implements OnInit {
  @Input()
  parameter: Parameter;

  @Input()
  form: FormGroup;

  @Input()
  parameterId: string;

  max: number;

  min: number;

  isEnum = false;

  date: string;

  time: string;

  hexBinary: string;

  nanoseconds: string;

  seconds: string;

  constructor() {}

  ngOnInit() {
    // Init min max value type and formControl
    if (this.parameter.ptc == 3) {
      if (isEnum(this.parameter.calibration)) {
        this.isEnum = true;
        this.parameter.physicalValue.valueType = ValueType.Enum;
        this.form.addControl(this.parameterId, new FormControl());
      } else {
        this.min = 0;
        this.max = 2 ** this.parameter.pfc - 1;
        this.parameter.physicalValue.valueType = ValueType.Integer;
        this.form.addControl(
          this.parameterId,
          new FormControl("", [
            validateMinFactory(this.parameter.calibration, this.min),
            validateMaxFactory(this.parameter.calibration, this.max),
            validateIsIntegerFactory(this.parameter.calibration),
          ])
        );
      }
    } else if (this.parameter.ptc == 4) {
      // Pour les entier signés il faut ajouter 4 au pfc pour avoir le nombre de bits
      var sizeInBits: number;
      if (4 <= this.parameter.pfc && this.parameter.pfc <= 12) {
        sizeInBits = this.parameter.pfc + 4;
      } else if (this.parameter.pfc == 13) {
        sizeInBits = 24;
      } else if (this.parameter.pfc == 14) {
        sizeInBits = 32;
      } else if (this.parameter.pfc == 15) {
        sizeInBits = 48;
      } else if (this.parameter.pfc == 16) {
        sizeInBits = 64;
      }
      this.max = 2 ** (sizeInBits - 1) - 1;
      this.min = -1 * 2 ** (sizeInBits - 1);
      this.parameter.physicalValue.valueType = ValueType.Integer;
      this.form.addControl(
        this.parameterId,
        new FormControl("", [
          validateMinFactory(this.parameter.calibration, this.min),
          validateMaxFactory(this.parameter.calibration, this.max),
          validateIsIntegerFactory(this.parameter.calibration),
        ])
      );
    } else if (this.parameter.ptc == 5) {
      this.parameter.physicalValue.valueType = ValueType.Double;
      if (this.parameter.pfc == 1) {
        this.form.addControl(
          this.parameterId,
          new FormControl("", [
            validateIsFloatFactory(this.parameter.calibration),
          ])
        );
      } else if (this.parameter.pfc == 2) {
        this.form.addControl(
          this.parameterId,
          new FormControl("", [
            validateIsFloatFactory(this.parameter.calibration),
          ])
        );
      }
    } else if (this.parameter.ptc == 7) {
      this.parameter.physicalValue.valueType = ValueType.Binary;
      var sizeInBits: number = this.parameter.pfc * 8;

      this.min = 0;
      this.max = 2 ** sizeInBits - 1;
      this.form.addControl(
        this.parameterId,
        new FormControl("", [
          validateMaxHexFactory(this.parameter.calibration, this.max),
          validateIsHexBinary(this.parameter.calibration),
        ])
      );
    } else if (this.parameter.ptc == 9) {
      this.parameter.physicalValue.valueType = ValueType.AbsoluteTime;
      this.form.addControl(this.parameterId + "Date", new FormControl());
      this.form.addControl(this.parameterId + "Temps", new FormControl());
      this.form.addControl(
        this.parameterId + "Nano",
        new FormControl("", [validateNanoLengthFactory()])
      );
      this.setDate();
    } else if (this.parameter.ptc == 10) {
      this.parameter.physicalValue.valueType = ValueType.RelativeTime;
      this.form.addControl(
        this.parameterId + "Seconds",
        new FormControl("", [validateSecondLengthFactory()])
      );
      this.form.addControl(
        this.parameterId + "Nano",
        new FormControl("", [validateNanoLengthFactory()])
      );
      this.setDate();
    }
    if (this.parameter.fixedValue) {
      //            this.form.setControl( this.parameterId, new FormControl({value: '', disabled: true}));
    }
  }

  onChange() {
    const splitedDate: string[] = this.date.split("/");
    this.parameter.physicalValue.value =
      splitedDate[2] +
      "-" +
      splitedDate[1] +
      "-" +
      splitedDate[0] +
      "T" +
      addZerosDigitstoTime(this.time) +
      "." +
      addZeros9Digit(this.nanoseconds) +
      "Z";
  }

  onChangeBinary() {
    this.parameter.physicalValue.value = addZerosDigitToHex(
      this.hexBinary,
      this.parameter.pfc
    );
  }

  onChangeCucRelative() {
    this.parameter.physicalValue.value =
      Math.abs(Number(this.seconds)) +
      Math.abs(Number(this.nanoseconds)) / Number(1000000000);
    if (Number(this.nanoseconds) < 0 || Number(this.seconds) < 0) {
      this.parameter.physicalValue.value *= -1;
    }
  }

  setDate() {
    const d = new Date();
    const dateExec =
      ("0" + d.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (d.getUTCMonth() + 1)).slice(-2) +
      "/" +
      d.getUTCFullYear();

    const timeExec =
      ("0" + d.getUTCHours()).slice(-2) +
      ":" +
      ("0" + d.getUTCMinutes()).slice(-2) +
      ":" +
      ("0" + d.getUTCSeconds()).slice(-2);
    this.date = dateExec;
    this.time = timeExec;
    this.nanoseconds = "0";
    this.seconds = "0";
  }

  isPolynomialCalibration(calibration: Calibration) {
    return isPolynomialCalibration(calibration);
  }

  get isRequired() {
    return this.form.controls[this.parameterId].hasError("required");
  }

  get isOverLimit() {
    return this.form.controls[this.parameterId].hasError("validateMax");
  }

  get isOverLimitBinary() {
    return this.form.controls[this.parameterId].hasError("validateMaxHex");
  }

  get isUnderLimit() {
    return this.form.controls[this.parameterId].hasError("validateMin");
  }

  get isInteger() {
    return this.form.controls[this.parameterId].hasError("validateIsInteger");
  }

  get isFloat() {
    return this.form.controls[this.parameterId].hasError("validateIsFloat");
  }

  get isBinary() {
    return this.form.controls[this.parameterId].hasError("validateIsHexBinary");
  }

  valueAfterCalibration(value: number, calibration: Calibration): number {
    let valueAfterCalibration = 0;
    if (isPolynomialCalibration(calibration)) {
      for (const coefficient of (<PolynomialCalibration>calibration)
        .coefficients) {
        valueAfterCalibration += Math.pow(
          coefficient.factor * value,
          coefficient.exponent
        );
      }
    } else {
      valueAfterCalibration = value;
    }
    return valueAfterCalibration;
  }

  buttonState() {
    return true;
  }
}
