import {
  Parameter,
  PacketContainer,
  PacketParameter,
  AbstractParameter,
  isPacketContainer,
  AbstractContainer,
  isParameter,
  isPacketParameter,
  iNumericValueParameter,
  ValueType,
  isEnum,
} from "app/model/spacepacket";
import { PacketVisitor } from "app/model/PacketVisitor";

export class GetParametersVisitor implements PacketVisitor {
  private showFixedValues: boolean;
  private showOnlyNumeric: boolean;

  public constructor(showFixedValues: boolean, showOnlyNumeric: boolean) {
    this.showFixedValues = showFixedValues;
    this.showOnlyNumeric = showOnlyNumeric;
  }

  private parameters: Parameter[] = [];

  /**
   * @returns the parameters
   */
  public getParameters(): Parameter[] {
    return this.parameters;
  }

  visitParameter(parameter: Parameter) {
    let showFixForThisParameter = false;
    let showOnlyNumericForThisParameter = false;
    if (!this.showFixedValues) {
      if (!parameter.fixedValue) {
        showFixForThisParameter = true;
      }
    } else {
      showFixForThisParameter = true;
    }

    // En fait on affiche les param numériques et les enums mais on tracera la valeur raw
    if (this.showOnlyNumeric) {
      if (iNumericValueParameter(parameter) || isEnum(parameter.calibration)) {
        showOnlyNumericForThisParameter = true;
      }
    } else {
      showOnlyNumericForThisParameter = true;
    }

    if (showFixForThisParameter && showOnlyNumericForThisParameter) {
      this.parameters.push(parameter);
    }
  }

  visitPacketContainer(packetContainer: PacketContainer) {
    for (const courant of packetContainer.subContainers) {
      this.redirectVisitor(courant);
    }
  }

  visitPacketParameter(packetParameter: PacketParameter) {
    if (packetParameter.spacePacket != null) {
      this.redirectVisitor(packetParameter.spacePacket.rootContainer);
    }
  }

  private redirectVisitor(container: AbstractContainer) {
    if (isPacketContainer(container)) {
      this.visitPacketContainer(<PacketContainer>container);
    } else if (isParameter(container)) {
      this.visitParameter(<Parameter>container);
    } else if (isPacketParameter(container)) {
      this.visitPacketParameter(<PacketParameter>container);
    }
  }
}
