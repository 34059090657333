import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { ArgumentView } from "./model/argumentview";
import { TcPacketsConfiguration } from "./model/tc-packets-configuration";
import { TcPacketId } from "./model/spacepacket";
declare let $: any;

@Injectable()
export class SendTcService {
  private sendTcUrl = "api/send-tc";

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private handleError: HandleError;

  tcPacketsConfiguration: TcPacketsConfiguration = new TcPacketsConfiguration();

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.sendTcUrl = "http://localhost:15000/" + this.sendTcUrl;
    }
    this.handleError = httpErrorHandler.createHandleError("SendTcService");
  }

  getTcArguments(): Observable<Array<ArgumentView>> {
    return this.http
      .get<Array<ArgumentView>>(this.sendTcUrl)
      .pipe(catchError(this.handleError("getTcArguments", [])));
  }

  sendTc(argumentView: ArgumentView): Observable<Array<TcPacketId>> {
    return this.http
      .put<Array<TcPacketId>>(this.sendTcUrl, argumentView, this.httpOptions)
      .pipe(catchError(this.handleError("sendTc", [])));
  }
}
