import { CriticityColors } from "../../../model/criticity-colors";
import {
  Parameter,
  NumericMonitoring,
  NumericMonitoringRange,
} from "../../../model/spacepacket";

/**
 *
 * @param parameters
 */
export function checkThresholdsAreEqual(parameters: Parameter[]): boolean {
  if (<NumericMonitoring>parameters[0].monitoring != null) {
    const init: NumericMonitoring = <NumericMonitoring>parameters[0].monitoring;

    for (const parameter of parameters) {
      if (
        <NumericMonitoring>parameter.monitoring != null &&
        !areThresholdsEqual(init, <NumericMonitoring>parameter.monitoring)
      ) {
        return false;
      } else if (<NumericMonitoring>parameter.monitoring == null) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

/**
 *
 * @param monitoring1
 * @param monitoring2
 */
function areThresholdsEqual(
  monitoring1: NumericMonitoring,
  monitoring2: NumericMonitoring
) {
  if (!areRangesEqual(monitoring1.criticalRange, monitoring2.criticalRange)) {
    return false;
  }
  if (!areRangesEqual(monitoring1.distressRange, monitoring2.distressRange)) {
    return false;
  }
  if (!areRangesEqual(monitoring1.severeRange, monitoring2.severeRange)) {
    return false;
  }
  if (!areRangesEqual(monitoring1.warningRange, monitoring2.warningRange)) {
    return false;
  }
  if (!areRangesEqual(monitoring1.watchRange, monitoring2.watchRange)) {
    return false;
  }

  return true;
}

/**
 *
 * @param range1
 * @param range2
 */
function areRangesEqual(
  range1: NumericMonitoringRange,
  range2: NumericMonitoringRange
): boolean {
  if (range1 == null && range2 == null) {
    return true;
  } else if (range1 != null && range2 != null) {
    if (!(range1.maxExclusive == range2.maxExclusive)) {
      return false;
    }
    if (!(range1.maxInclusive == range2.maxInclusive)) {
      return false;
    }
    if (!(range1.minExclusive == range2.minExclusive)) {
      return false;
    }
    if (!(range1.minInclusive == range2.minInclusive)) {
      return false;
    }
  } else {
    return false;
  }

  return true;
}

/**
 *
 * @param parameter
 * @param idYaxis
 */
export function getThresholdsAnnotations(
  parameter: Parameter,
  idYaxis: number
): any[] {
  const annotations = [];

  const thresholds: NumericMonitoring = <NumericMonitoring>parameter.monitoring;

  // Severe
  if (
    thresholds.severeRange != undefined &&
    getMin(thresholds.severeRange) != null
  ) {
    annotations.push(
      getAnnotationLineMin(
        thresholds.severeRange,
        CriticityColors.severe,
        idYaxis
      )
    );
  }

  if (
    thresholds.severeRange != undefined &&
    getMax(thresholds.severeRange) != null
  ) {
    annotations.push(
      getAnnotationLineMax(
        thresholds.severeRange,
        CriticityColors.severe,
        idYaxis
      )
    );
  }

  // Critical
  if (
    thresholds.criticalRange != undefined &&
    getMin(thresholds.criticalRange) != null
  ) {
    annotations.push(
      getAnnotationLineMin(
        thresholds.criticalRange,
        CriticityColors.critical,
        idYaxis
      )
    );
  }

  if (
    thresholds.criticalRange != undefined &&
    getMax(thresholds.criticalRange) != null
  ) {
    annotations.push(
      getAnnotationLineMax(
        thresholds.criticalRange,
        CriticityColors.critical,
        idYaxis
      )
    );
  }

  // Distress
  if (
    thresholds.distressRange != undefined &&
    getMin(thresholds.distressRange) != null
  ) {
    annotations.push(
      getAnnotationLineMin(
        thresholds.distressRange,
        CriticityColors.distress,
        idYaxis
      )
    );
  }

  if (
    thresholds.distressRange != undefined &&
    getMax(thresholds.distressRange) != null
  ) {
    annotations.push(
      getAnnotationLineMax(
        thresholds.distressRange,
        CriticityColors.distress,
        idYaxis
      )
    );
  }

  // Warning
  if (
    thresholds.warningRange != undefined &&
    getMin(thresholds.warningRange) != null
  ) {
    annotations.push(
      getAnnotationLineMin(
        thresholds.warningRange,
        CriticityColors.warning,
        idYaxis
      )
    );
  }

  if (
    thresholds.warningRange != undefined &&
    getMax(thresholds.warningRange) != null
  ) {
    annotations.push(
      getAnnotationLineMax(
        thresholds.warningRange,
        CriticityColors.warning,
        idYaxis
      )
    );
  }

  //Watch
  if (
    thresholds.watchRange != undefined &&
    getMin(thresholds.watchRange) != null
  ) {
    annotations.push(
      getAnnotationLineMin(
        thresholds.watchRange,
        CriticityColors.watch,
        idYaxis
      )
    );
  }

  if (
    thresholds.watchRange != undefined &&
    getMax(thresholds.watchRange) != null
  ) {
    annotations.push(
      getAnnotationLineMax(
        thresholds.watchRange,
        CriticityColors.watch,
        idYaxis
      )
    );
  }

  return annotations;
}

/**
 *
 * @param range
 * @param color
 * @param idYaxis
 */
function getAnnotationLineMax(range: any, color: string, idYaxis: number) {
  const lineAnnotation: any = {
    type: "line",
    scaleID: "y" + idYaxis,
    value: getMax(range),
    borderColor: color,
    borderWidth: 2,
    label: getLabel(range, color, getMax(range)),
  };
  return lineAnnotation;
}

/**
 *
 * @param range
 * @param color
 * @param idYaxis
 */
function getAnnotationLineMin(range: any, color: string, idYaxis: number) {
  const lineAnnotation: any = {
    type: "line",
    scaleID: "y" + idYaxis,
    value: getMin(range),
    borderColor: color,
    borderWidth: 2,
    label: getLabel(range, color, getMin(range)),
  };
  return lineAnnotation;
}

/**
 *
 * @param range
 * @param color
 * @param value
 */
function getLabel(range: any, color: string, value: any): any {
  const toReturn = {
    display: true,

    // Background color of label, default below
    // On converti en rgba pour rajouter de la transparence
    backgroundColor: hexToRgba(color),

    font: {
      size: 12, // Font size of text, inherits from global
      color: "#fff", // Font color of text, default below
    },

    padding: {
      x: 4, // Padding of label to add left/right, default below
      y: 4, // Padding of label to add top/bottom, default below
    },

    // Radius of label rectangle, default below
    borderRadius: 6,

    // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
    position: "center",

    // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
    // For horizontal lines positioned left or right, negative values move
    // the label toward the edge, and positive values toward the center.
    xAdjust: -10,

    // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
    // For vertical lines positioned top or bottom, negative values move
    // the label toward the edge, and positive values toward the center.
    yAdjust: 0,

    // Text to display in label - default is null
    content: value,
  };

  return toReturn;
}

/**
 *
 * @param hex
 */
function hexToRgba(hex: string): string {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.7)"
    );
  }
  throw new Error("Bad Hex");
}

/**
 *
 * @param range
 */
export function getMin(range: any) {
  if (range != undefined) {
    if (range.minExclusive != null) {
      return range.minExclusive;
    } else if (range.minInclusive != null) {
      return range.minInclusive;
    }
  }
  return null;
}

/**
 *
 * @param range
 */
export function getMax(range: any) {
  if (range != undefined) {
    if (range.maxExclusive != null) {
      return range.maxExclusive;
    } else if (range.maxInclusive != null) {
      return range.maxInclusive;
    }
  }
  return null;
}
