export class ProcedureArgument {
  procedureName: string;
  procedureDescription: string;
}

export enum ProcedureAck {
  CANCELED,
  FAILURE,
  SUCCESS,
}

export class DatedProcedureAck {
  time: string;
  ack: string;
}

export class Procedure {
  id: number;
  name: string;
  description: string;
  generationTime: string;
  started: DatedProcedureAck;
  completed: DatedProcedureAck;
}

export class ProcedureConfiguration {
  selectList: Map<string, boolean> = new Map<string, boolean>();
  dateStart: string;
  timeStart: string;
  dateEnd: string;
  timeEnd: string;
  realTime: boolean;
  nbProcsMax: number;
}

export class ProcedureToCancel {
  id: number;
  name: string;
  generationTime: string;
}
