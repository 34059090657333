import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { ProcedureArgument, ProcedureConfiguration } from "./model/procedures";

declare let $: any;

@Injectable({
  providedIn: "root",
})
export class ProceduresService {
  private procedureUrl = "api/procedure";

  procedureConfig: ProcedureConfiguration = new ProcedureConfiguration();

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.procedureUrl = "http://localhost:15000/" + this.procedureUrl;
    }
    this.handleError = httpErrorHandler.createHandleError("proceduresService");
  }

  getProcedures(): Observable<Array<ProcedureArgument>> {
    return this.http
      .get<Array<ProcedureArgument>>(this.procedureUrl)
      .pipe(catchError(this.handleError("getProcedures", [])));
  }

  sendProcedure(procedure: ProcedureArgument) {
    const ret = this.http
      .put(this.procedureUrl, procedure, this.httpOptions)
      .pipe(catchError(this.handleError("sendProcedure", [])));
    return ret;
  }
}
