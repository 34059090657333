<ng-container *ngIf="datedParameters.length > 0" class="container">
    <div class="pt-3">
        <div class="statcard p-2 text-xs-center border-severe rounded text-truncate" style="border: 2px solid"
            [style.border-color]="getColorBorder()">
            <h2 class="statcard-number pb-2">
                <app-single-value [datedParameters]="this.datedParameters" [index]="0" [obsolescence]="this.obsolescence" [orderBy]="this.orderBy" [widgetId]="this.widgetId">
                </app-single-value>
                <span *ngIf="this.isObsolete(this.datedParameters[0])"
                class="obsolescenceInfo" id="obsolescence-mainAlpha"> ! </span>
            </h2>
            <span class=" parameterName">
                <span class="legendName"> {{getName()}} </span>
            </span>
        </div>
    </div>
    <ng-container *ngIf="this.nbValues>1">
        <div style="text-align: center">
            <table style="display: inline-block;">
                <tbody>
                    <ng-container *ngFor="let datedParameter of datedParameters; let i=index">

                        <tr>
                            <td class="py-1">
                                <h5 *ngIf="i>0 && i<this.nbValues">
                                    <app-single-value [datedParameters]="this.datedParameters" [index]="i" [obsolescence]="this.obsolescence" [orderBy]="this.orderBy" [widgetId]="this.widgetId">
                                    </app-single-value>
                                </h5>
                            </td>

                            <td class="py-1">
                                <span *ngIf="this.isObsolete(datedParameter) && i>0 && i<this.nbValues"
                                    class="obsolescenceInfo"
                                    id="obsolescence-alpha"> ! </span>
                            </td>

                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </ng-container>
</ng-container>