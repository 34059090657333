import { Directive, forwardRef, Input } from "@angular/core";
import {
  NG_VALIDATORS,
  FormControl,
  ValidatorFn,
  AbstractControl,
  Validators,
} from "@angular/forms";
import {
  Calibration,
  PolynomialCalibration,
  isPolynomialCalibration,
} from "../model/spacepacket";

/**
 *
 * @param calibration
 * @param min
 */
export function validateMinFactory(
  calibration: Calibration,
  min: number
): ValidatorFn {
  return (c: FormControl) => {
    let valueToCompare = 0;
    if (isPolynomialCalibration(calibration)) {
      for (const coefficient of (<PolynomialCalibration>calibration)
        .coefficients) {
        valueToCompare += Math.pow(
          coefficient.factor * c.value,
          coefficient.exponent
        );
      }
    } else {
      valueToCompare = c.value;
    }
    return valueToCompare >= min
      ? null
      : {
          validateMin: {
            valid: false,
          },
        };
  };
}

@Directive({
  selector: "[validateMin]",
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true },
  ],
})
export class MinValidatorDirective {
  private valFn = Validators.nullValidator;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.valFn(control);
  }
}
