<div id="bds-explorer" class="main-container container-fluid with-top-navbar">
  <div class="row reduced-gutters">
    <div class="col-6 col-lg-6 col-xl-6">
      <div class="row reduced-gutters">
       
        <div class="col-lg col-xl">
          <div id="paquet-list" class="card card-grey ">
            <div class="card-header card-grey" id="help-packets-list" data-container="body" data-html="true"
              data-placement="bottom" data-trigger="manual" data-title="Liste des paquets" data-content="Affiche la liste des paquets de la bds">

              <!-- 							<a href='#' class='scrollToTop'> Scroll To Top</a>  -->
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <h6><strong>Liste des paquets</strong></h6>
                    </div>
                    <div class="col">
                      <h6>v{{this.bdsVersion}}</h6>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="input-group input-group-sm searchbarTm" data-toggle="tooltip" id="searchbarTm" data-placement="top"
                      title="Rechercher par nom de paquet, description du paquet ou par nom d'un paramètre" data-delay='{"show":"800", "hide":"0"}'
                      data-trigger="hover" aria-pressed="false" autocomplete="off">
                      <input type="text" class="form-control" placeholder="Rechercher..." name="packetSearch"
                        [(ngModel)]="packetSearch" (ngModelChange)="onChangeSearchPacket()">
                      <button id="searchButton" class="btn btn-default add-on-button btn-card-header" type="button" (click)="clearSearch()">
                        <span [hidden]="!edited" class="icon icon-circle-with-cross noir" aria-hidden="true"></span>
                        <span [hidden]="edited" class="icon icon-magnifying-glass noir" aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="card-block scroll-y scrollbar-style" id="scroll-packet-list">
              <table #table class="table table-sm" data-sort="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Nom</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let packet of displayList" [class.selected]="packet === selectedPacket" class="pointer-hover"
                    (click)="onSelect(packet)">
                    <!-- 										<ng-container *ngIf="((displayNominal = false && displayWarning = false &&  displaySevere=false) || (displayNominal = true && displayWarning = true &&  true))"> -->
                    <ng-container>
                      <td></td>
                      <td>{{packet.name}}</td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-6 col-xl-6">
      <div id="packet-explorer-container" class="card card-grey">

        <div class="card-header card-grey" id="help-paquet-explorer" class="card-header card-grey" data-container="body"
          data-html="true" data-placement="bottom" data-trigger="manual" data-title="Explorateur de paquets"
          data-content="Permet de naviguer dans le paquet selectionné dans la <b>Liste des paquets</b>">
          <h6><strong>Explorateur de paquets</strong></h6>
        </div>
        <div class="card-block scroll-y scrollbar-style">
          <div *ngIf="selectedPacket" class="list-group list-group-root">
            <a (click)="onSelect(selectedPacket)" href="#{{selectedPacket.rootContainer.name}}" class="list-group-item list-group-item-action root"
              data-toggle="collapse" aria-expanded="true">
              {{selectedPacket.rootContainer.name}}
              <br><span id="explorer-description-tm" class="text-muted">{{selectedPacket.description}}</span>
            </a>
            <div class="collapse  show collapse-container" id="{{selectedPacket.rootContainer.name}}">
              <div class="list-group">
                <app-tm-packets-explorer class="anchor-left-padding" [packetContainer]="selectedPacket.rootContainer"
                  (selectedContainerChange)="selectedContainerChange($event)"></app-tm-packets-explorer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="parameter-list">
        <app-parameters-details [(selectedContainer)]="selectedContainer">
        </app-parameters-details>
      </div>
    </div>
  </div>
</div>
