<div class="" *ngIf="argumentView">
  <div class="page-header">

    <h3>
      <strong>{{argumentView.packetName}}</strong>
    </h3>

  </div>
  <div>
    <!-- 		<h6>Description</h6> -->
    <p class="text-muted">{{argumentView.packetDescription}}</p>
  </div>
  <ng-container *ngFor="let argument of argumentView.argumentParameters">
    <ng-container *ngIf="argument.fixedRepeat || argument.dynamicRepeat">
      <app-repeat-parameter [repeatParameter]="argument"
                            [dynamicRefValue]="getRepeatedDynamicRefValue(argument)" [argumentView]="argumentView"
                            [form]="form"></app-repeat-parameter>
    </ng-container>
    <ng-container *ngIf="isParameter(argument) && !argument.dynamicRepeat && !argument.fixedRepeat">
      <!-- On injecte le composant  -->
      <!-- 		 On ajout un class hide seulement si argument fixé et case caché coché, il ne faut pas supprimer la balise
    car ça cause des problèmes au niveau de la génération des TC -->
      <app-parameter [ngClass]="{'hidden' : (hideFixedArgument && argument.fixedValue)}" [parameter]="argument"
                     [parameterId]="argument.name"
                     [form]="form"></app-parameter>
      <div *ngIf="isParamDynamicRef(argument)" class="text-right">
        <button (click)="dynamicRefIncrease(argument.name)" class="btn btn-default add-on-button m-1">+</button>
        <button (click)="dynamicRefDecrease(argument.name)" class="btn btn-default add-on-button">-</button>
      </div>
    </ng-container> <!-- Si ce n'est pas un paramètre on regarde si c'est un packetparameter -->
    <ng-container *ngIf="isPacketParameter(argument)">
      <app-packet-parameter [packetParameter]="argument" [selectedFirst]="argumentView"
                            [tcArguments]="tcArguments" [form]="form"></app-packet-parameter>
    </ng-container>
  </ng-container>
</div>

<!-- TEST VALIDATION FORM -->
<!-- <div [formGroup]="form"> -->
<!-- 	<input formControlName="name" type="text" id="name" -->
<!-- 		class="form-control" required minlength="4" maxlength="24" -->
<!-- 		forbiddenName="bob" name="name" [(ngModel)]="name"> -->
<!-- </div> -->
