<div class="card card-outline-primary card-block" id="card-time-tag">
	<div class="input-group searchbarTc" id="searchbarTc-time-tag">
		<input type="text" class="form-control" data-toggle="dropdown"
			[(ngModel)]="packetSearch" data-target=".show-dropdown-tc-time-tag"
			(ngModelChange)="onChangeSearchPacket()"
			placeholder="Rechercher une télécommande..." />
		<div class="input-group-btn">
			<div class="btn-group" role="group">
				<div class="dropdown dropdown-lg" id="dropdown-tc-list">
					<button id="searchTCButton"
						class="btn btn-default rounded-0 add-on-button" type="button"
						(click)="clearSearch()">
						<span [hidden]="!edited" class="icon icon-circle-with-cross noir"
							aria-hidden="true"></span> <span [hidden]="edited"
							class="icon icon-magnifying-glass noir" aria-hidden="true"></span>
					</button>
					<span class="show-dropdown-tc-time-tag">
						<button id="dropdownTCButton" type="button"
							class="btn btn-default dropdown-toggle add-on-button"
							data-toggle="dropdown" aria-expanded="false">
							<span class="caret"></span>
						</button>
						<div class="dropdown-menu dropdown-menu-right scrollable-menu scroll-y scrollbar-style" role="menu"
							id="dropdown-list-tc-time-tag">
							<ng-container *ngFor="let argumentView of displayList">
								<button class="dropdown-item" type='button' (click)="setTC(argumentView)">
									{{argumentView.packetName}}<br /> <span class="description-tc">
											{{argumentView.packetDescription}}</span>
								</button>
							<div class="dropdown-divider"></div>
							</ng-container>
						</div>
					</span>
				</div>

			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-12">
			<app-argumentview-detail [argumentView]="selectedFirst.embeddedTc"
				[tcArguments]="tcArguments" [form]="formImbricated"></app-argumentview-detail>
		</div>
	</div>
</div>