import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Directive,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SynopticConfiguration } from "app/model/synoptic-configuration";
import { ParametersService } from "app/parameters.service";
import { interval, Subscription } from "rxjs";
import { Synoptic } from "../../model/synoptic";
import { properWayForIntervalSubscription } from "../../model/spacepacket";
import { SynopticListService } from "../../synoptic-list.service";
import { SynopticService } from "../../synoptic.service";
import { PopoverTourService } from "app/popover-tour.service";
declare let $: any;

@Component({
  selector: "app-synoptic-sidebar",
  templateUrl: "./synoptic-sidebar.component.html",
  styleUrls: ["./synoptic-sidebar.component.css"],
})
export class SynopticSidebarComponent implements OnInit {
  selectLastSynoptic = false;
  selectFirstSynoptic = true;

  LimitPacketsMax: number;

  synopticConfiguration: SynopticConfiguration;

  selectedSynoptic: string;
  form: FormGroup = new FormGroup({});
  @Input()
  synopticList: Synoptic[] = new Array<Synoptic>();
  subscription: Subscription;
  toDelete: Synoptic = new Synoptic();
  selected: Synoptic;

  previousCallRunning = false;

  timeReference = "onBoardTime";

  popoverTourService: PopoverTourService = PopoverTourService.Instance;

  @Output()
  selectedSynopticChange: EventEmitter<Synoptic> = new EventEmitter();

  @Output()
  resetData: EventEmitter<Synoptic> = new EventEmitter();

  constructor(
    private synopticListService: SynopticListService,
    private synopticService: SynopticService,
    private parametersService: ParametersService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.synopticConfiguration = this.parametersService.synopticConfiguration;
    this.setDateInterval();

    this.synopticConfiguration.realTime = true;
  }

  ngOnInit() {
    this.getSynopticList();
    this.subscription = properWayForIntervalSubscription(
      1000,
      "getSynopticList",
      this
    );
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
    this.subscription.unsubscribe();
  }

  getSynopticList() {
    this.previousCallRunning = true;
    this.synopticListService.getSynopticList().subscribe((x) => {
      this.merge(x);
      this.updateSelectedSynoptic();
    });
  }

  onSelect(synoptic: Synoptic) {
    this.selected = synoptic;
    this.selectedSynopticChange.emit(synoptic);
  }

  addSynoptic(newSynoptic: Synoptic) {
    const copySynoptic: Synoptic = new Synoptic();
    copySynoptic.title = newSynoptic.title;
    copySynoptic.description = newSynoptic.description;

    this.synopticService
      .addSynoptic(newSynoptic)
      .subscribe((x) => this.setSelectLastSynopticTrue());

    $("#creer-synoptique").modal("hide");
  }

  chooseToDelete(synoptic: Synoptic) {
    this.toDelete = synoptic;
    this.onSelect(null);
  }

  deleteSynoptic() {
    this.synopticService.deleteSynoptic(this.toDelete.synopticId).subscribe();
    $("#delete-synoptic").modal("hide");
    // Dans ce cas on supprime le synoptic séléctionné
    if (this.selected !== undefined && this.selected != null) {
      if (this.selected.synopticId == this.toDelete.synopticId) {
        this.selected = null;
      }
    }
  }

  //Ajoute à la liste de paquets les nouveaux paquets reçus
  merge(newSynoptics: Synoptic[]): boolean {
    let size: number = this.synopticList.length;
    newSynoptics.forEach((newSynoptic, i) => {
      if (i < size) {
        const oldSynoptic: Synoptic = this.synopticList[i];
        if (newSynoptic.synopticId != oldSynoptic.synopticId) {
          this.synopticList.splice(i, 0, newSynoptic);
          size++;
        }
      } else {
        this.synopticList.splice(i, 0, newSynoptic);
        size++;
      }
    });
    this.synopticList.splice(newSynoptics.length, size - newSynoptics.length);
    7;

    return true;
  }

  setDateInterval() {
    const dEnd = new Date();
    const dateEnd =
      ("0" + dEnd.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (dEnd.getUTCMonth() + 1)).slice(-2) +
      "/" +
      dEnd.getUTCFullYear();
    const timeEnd =
      ("0" + dEnd.getUTCHours()).slice(-2) +
      ":" +
      ("0" + dEnd.getUTCMinutes()).slice(-2) +
      ":" +
      ("0" + dEnd.getUTCSeconds()).slice(-2);
    const dStart = new Date(dEnd);
    dStart.setDate(dStart.getUTCDate() - 10);
    const dateStart =
      ("0" + dStart.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (dStart.getUTCMonth() + 1)).slice(-2) +
      "/" +
      dStart.getUTCFullYear();
    const timeStart = timeEnd;
    this.synopticConfiguration.dateStart = dateStart;
    this.synopticConfiguration.timeStart = timeStart;
    this.synopticConfiguration.dateEnd = dateEnd;
    this.synopticConfiguration.timeEnd = timeEnd;
  }

  onChange() {
    console.log("------------ Change ------------");
    console.log("DateStart : " + this.synopticConfiguration.dateStart);
    console.log("TimeStart : " + this.synopticConfiguration.timeStart);
    console.log("DateEnd : " + this.synopticConfiguration.dateEnd);
    console.log("TimeEnd : " + this.synopticConfiguration.timeEnd);
    console.log("NbPaquetMax : " + this.LimitPacketsMax);
  }

  onChangeRealTime() {
    // Désactive la checkbox pendant l'animation
    $("#checkbox-temps-reelSynoptic input").prop("disabled", true);
    setTimeout(function () {
      $("#checkbox-temps-reelSynoptic input").prop("disabled", false);
    }, 400);
    if (!this.synopticConfiguration.realTime) {
      this.setDateInterval();
      $("#collapseRealTimeSynoptic").collapse("show");
    } else {
      $("#collapseRealTimeSynoptic").collapse("hide");
    }

    // On doit reset le synoptic
    if (this.selected != null) {
      this.resetData.emit();
    }
  }

  // Fonction gérer le changement temps bord temps sol

  changeReferenceTime(value: string) {
    if (value == "onReceptionTime") {
      this.synopticConfiguration.orderBy = "onReceptionTime";
    } else {
      this.synopticConfiguration.orderBy = "onBoardTime";
    }
    // On doit reset le synoptic
    if (this.selected != null) {
      this.onSelect(this.selected);
    }
  }

  // --------------------------------- Private functions for update selected synoptic when creating new

  private updateSelectedSynoptic() {
    if (this.selectFirstSynoptic && this.synopticList.length > 0) {
      this.onSelect(this.synopticList[0]);
      this.selectFirstSynoptic = false;
    }
    if (this.selectLastSynoptic) {
      this.onSelect(this.synopticList[this.synopticList.length - 1]);
      this.selectLastSynoptic = false;
    }
    // On fait ça car on termine le subscribe et il faut permettre de refaire un getSynopticList
    this.previousCallRunning = false;
  }

  private setSelectLastSynopticTrue() {
    this.selectLastSynoptic = true;
  }
}
