// ------------------ The model for colors

// Il ne faut pas que les variables soient statiques car il faut une instance de classe color par widget
export class CriticityColors {
  public static severe = "#e64759";

  public static critical = "#e6704d";

  public static distress = "#e6a63c";

  public static warning = "#e4d836";

  public static watch = "#95d856";
}
