import { Component, OnInit, Directive } from "@angular/core";

@Component({
  selector: "app-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.css"],
})
export class LineChartComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
