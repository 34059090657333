<div class="card card-grey">
  <div
    class="card-header card-grey"
    id="help-parameter-list"
    data-container="body"
    data-html="true"
    data-placement="bottom"
    data-trigger="manual"
    data-title="Paramètres"
    data-content="Affiche les paramètres présents dans le paquet selectionné dans l'<b>Explorateur de paquets</b>	 
					<br> Possibilité d'ajouter ou d'enlever des colonnes en cliquant sur l'icone <span class='icon icon-dots-three-vertical'>
					"
  >
    <div class="row">
      <div class="col">
        <h6 id="header-help-parameters">
          <strong>Paramètres</strong>
        </h6>
      </div>
      <div class="col">
        <div class="row">
          <div class="input-group searchbarTm input-group-sm" id="searchbarTm">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="parameterSearch"
              (ngModelChange)="onChangeSearchParameter()"
              placeholder="Rechercher un paramètre..."
            />
            <button
              id="searchButton"
              class="btn btn-default add-on-button btn-card-header"
              type="button"
              (click)="clearSearch()"
            >
              <span
                [hidden]="!edited"
                class="icon icon-circle-with-cross noir"
                aria-hidden="true"
              ></span>
              <span
                [hidden]="edited"
                class="icon icon-magnifying-glass noir"
                aria-hidden="true"
              ></span>
            </button>
          </div>
          <a
            class="dropdown-colonnes"
            id="dropdownMenuParametre"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            auto-close="disabled"
          ></a>
          <div
            class="dropdown-menu dropdown-menu-right scroll-y scrollbar-style"
            id="column-list"
            aria-labelledby="dropdownMenuButton"
          >
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="name"
                />
                <span class="custom-control-indicator"></span> Name
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="physicalValue"
                />
                <span class="custom-control-indicator"></span> Physical Value
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="rawValue"
                />
                <span class="custom-control-indicator"></span> Raw Value
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="rawData"
                />
                <span class="custom-control-indicator"></span> Binary Data
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="length"
                />
                <span class="custom-control-indicator"></span> Length
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="offset"
                />
                <span class="custom-control-indicator"></span> Offset
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="ptc"
                />
                <span class="custom-control-indicator"></span> Ptc
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="pfc"
                />
                <span class="custom-control-indicator"></span> Pfc
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="fixedLength"
                />
                <span class="custom-control-indicator"></span> Fixed Length
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="fixedValue"
                />
                <span class="custom-control-indicator"></span> Fixed Value
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="dynamicLengthParamRef"
                />
                <span class="custom-control-indicator"></span> Dynamic Length
                ParamRef <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="dynamicRepeat"
                />
                <span class="custom-control-indicator"></span> Dynamic repeat
                <span class="custom-control-description"></span>
              </label>
            </p>
            <p class="dropdown-item">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="fixedRepeat"
                />
                <span class="custom-control-indicator"></span> Fixed repeat
                <span class="custom-control-description"></span>
              </label>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-block scroll-y scrollbar-style">
    <ng-container *ngIf="selectedContainer">
      <table class="table table-sm">
        <thead>
          <tr>
            <th></th>
            <th *ngIf="name">Name</th>
            <th *ngIf="physicalValue">Physical Value</th>
            <th *ngIf="rawValue">Raw Value</th>
            <th *ngIf="rawData">Binary Data</th>
            <th *ngIf="length">Length</th>
            <th *ngIf="offset">Offset</th>
            <th *ngIf="ptc">Ptc</th>
            <th *ngIf="pfc">Pfc</th>
            <th *ngIf="fixedLength">Fixed Length</th>
            <th *ngIf="fixedValue">Fixed Value</th>
            <th *ngIf="dynamicLengthParamRef">DynamicLengthParamRef</th>
            <th *ngIf="dynamicRepeat">DynamicRepeat</th>
            <th *ngIf="fixedRepeat">FixedRepeat</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let parameter of parametersToDisplay;
              let indexOfelement = index
            "
            data-toggle="tooltip"
            data-placement="left"
            data-html="true"
            data-delay='{"show":"500", "hide":"0"}'
            title="{{ parameter.description }}{{
              getCriticalityList(parameter)
            }}"
          >
            <td class="status">
              <span
                *ngIf="parameterStatusIsSevere(parameter)"
                class="icon icon-controller-record severe"
              ></span>
              <span
                *ngIf="parameterStatusIsCritical(parameter)"
                class="icon icon-controller-record critical"
              ></span>
              <span
                *ngIf="parameterStatusIsDistress(parameter)"
                class="icon icon-controller-record distress"
              ></span>
              <span
                *ngIf="parameterStatusIsWarning(parameter)"
                class="icon icon-controller-record warning"
              ></span>
              <span
                *ngIf="parameterStatusIsWatch(parameter)"
                class="icon icon-controller-record watch"
              ></span>
              <span
                *ngIf="parameterStatusIsNominal(parameter)"
                class="icon icon-controller-record nominal"
              ></span>
            </td>
            <td *ngIf="name">
              {{ parameter.name }}
              <div
                *ngIf="
                  this.popoverTourService.SequenceIndex === 102 &&
                  indexOfelement === 1
                "
                class="popover bs-tether-element-attached-top help-tour-popover"
                role="tooltip"
              >
                <h3 class="popover-title">
                  Inspecteur de paramètre
                  <button
                    (click)="this.popoverTourService.quitSequence()"
                    type="button"
                    class="close popover-close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </h3>
                <div class="popover-content">
                  Inspecter le contenu de chaques paramètres pour le paquet TM
                  sélectionné :<br />
                  - En cas d'anomalie détecté par les surveillances sol, le
                  paramètre apparait en rouge.<br />
                  - La valeur physique est obtenu après application de la
                  fonction de calibration (définie dans la BDS) sur la valeur
                  brute.<br />
                </div>
                <div class="popover-footer">
                  <button
                    (click)="this.popoverTourService.previousSequence()"
                    type="button"
                    class="btn btn-default add-on-button mr-4"
                  >
                    Précédent
                  </button>
                  <button
                    (click)="this.popoverTourService.nextSequence()"
                    type="button"
                    class="btn btn-primary"
                  >
                    Suivant
                  </button>
                </div>
              </div>
            </td>
            <td *ngIf="physicalValue">
              <ng-container
                *ngIf="
                  parameter.physicalValue.valueType === 'AbsoluteTime';
                  then thenBlock;
                  else elseBlock
                "
              ></ng-container>
              <ng-template #thenBlock>
                <span class="font-weight-bold">
                  {{ formatDate(parameter.physicalValue.value) }}
                </span>
              </ng-template>
              <ng-template #elseBlock>
                <span class="font-weight-bold">{{
                  parameter.physicalValue.value
                }}</span></ng-template
              >
              <ng-container *ngIf="parameter.unit">
                <span class="font-weight-bold">{{
                  parameter.unit.unit
                }}</span></ng-container
              >
            </td>
            <td *ngIf="rawValue">
              <ng-container
                *ngIf="
                  parameter.rawValue.valueType === 'AbsoluteTime';
                  then thenBlock;
                  else elseBlock
                "
              ></ng-container>
              <ng-template #thenBlock>
                {{ formatDate(parameter.rawValue.value) }}
              </ng-template>
              <ng-template #elseBlock>
                {{ parameter.rawValue.value }}</ng-template
              >
            </td>
            <td *ngIf="rawData">0x{{ parameter.rawData }}</td>
            <td *ngIf="length">{{ parameter.length }}</td>
            <td *ngIf="offset">{{ parameter.offset }}</td>
            <td *ngIf="ptc">{{ parameter.ptc }}</td>
            <td *ngIf="pfc">{{ parameter.pfc }}</td>
            <td *ngIf="fixedLength">{{ parameter.FixedLength }}</td>
            <td *ngIf="fixedValue">{{ parameter.fixedValue }}</td>
            <td *ngIf="dynamicLengthParamRef">
              {{ parameter.dynamicLengthParamRef }}
            </td>
            <td *ngIf="dynamicRepeat">{{ parameter.dynamicRepeat }}</td>
            <td *ngIf="fixedRepeat">{{ parameter.fixedRepeat }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>
