import { Component, OnInit, Directive } from "@angular/core";
import { TmPacketsService } from "./tm-packets.service";
import { SendTcService } from "./send-tc.service";
import { TmPacketListService } from "./tm-packet-list.service";
import { SynopticListService } from "./synoptic-list.service";
import { SynopticService } from "./synoptic.service";
import { ParameterWidgetService } from "./parameter-widget.service";
import { TcPacketsService } from "./tc-packets.service";
import { Router } from "@angular/router";
import { ParametersService } from "app/parameters.service";
import { TcPacketListService } from "./tc-packet-list.service";
import { BdsVersionService } from "./bds-version.service";
import { PopoverTourService } from "./popover-tour.service";
import { ProceduresService } from "./procedures.service";
import { ProcedureHistoryService } from "./procedure-history.service";

declare let $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [
    TmPacketsService,
    SendTcService,
    TmPacketListService,
    SynopticListService,
    SynopticService,
    ParameterWidgetService,
    ParametersService,
    TcPacketsService,
    TcPacketListService,
    BdsVersionService,
    ProceduresService,
    ProcedureHistoryService,
  ],
})
export class AppComponent {
  popoverTourService: PopoverTourService = PopoverTourService.Instance;

  ngOnInit() {
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();
  }
  clickedHelp() {
    $("#help-packets-list").popover("toggle");
    $("#help-paquet-explorer").popover("toggle");
    $("#help-parameter-list").popover("toggle");
    $("#help-send-tc").popover("toggle");
    $("#help-tc-paquet-historic").popover("toggle");
    $("#help-synoptic").popover("toggle");
    $("#help-procedure-historic").popover("toggle");

    //        $( '#tm-paquet-sidebar' ).popover( 'toggle' );
  }

  clickedFullScreen() {
    document.body.requestFullscreen();
  }

  closeHelp() {
    if ($("#help-button").hasClass("active")) {
      $("#help-button").button("toggle");
      $("#help-packets-list").popover("hide");
      $("#help-paquet-explorer").popover("hide");
      $("#help-parameter-list").popover("hide");
      $("#help-send-tc").popover("hide");
      $("#help-tc-paquet-historic").popover("hide");
      $("#help-synoptic").popover("hide");
      $("#help-procedure-historic").popover("hide");
      //            $( '#tm-paquet-sidebar' ).popover( 'hide' );
    }
  }
}
