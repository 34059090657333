import { Component, OnInit, Input, Output, Directive } from "@angular/core";
import {
  DatedParameter,
  SpacePacket,
  Parameter,
  isEnum,
  NumericMonitoring,
  NumericMonitoringRange,
} from "app/model/spacepacket";
import { returnCriticalityList } from "app/model/criticalityList";
import { getPointBackgroundColor } from "../utils/color-getter";
import { Colors } from "../../../model/colors";
import { CriticityColors } from "../../../model/criticity-colors";
import { getMin, getMax } from "../utils/threshold-setter";
declare let $: any;

@Component({
  selector: "app-jauge-widget",
  templateUrl: "./jauge-widget.component.html",
  styleUrls: ["./jauge-widget.component.css"],
})
export class JaugeWidgetComponent implements OnInit {
  colors: Colors = new Colors();
  datedParameters: DatedParameter[];
  thresholds: NumericMonitoring;

  @Input() set parameters(value: DatedParameter[]) {
    this.datedParameters = value;
    if (this.datedParameters[0]) {
      this.thresholds = <NumericMonitoring>(
        this.datedParameters[0].parameter.monitoring
      );
    }
  }

  @Input() index: number;
  @Input() idColor: number;
  @Input() obsolescence: number;
  @Input() orderBy: string;
  @Input() widgetId: number;

  constructor() {}

  ngOnInit() {}

  getColor() {
    return this.colors.lineChartColors[this.idColor].pointBackgroundColor;
  }

  getBackgoundLegendColor() {
    return this.colors.lineChartColors[this.idColor].backgroundColor;
  }

  getName() {
    return this.datedParameters[0].parameter.name;
  }

  getLastValue() {
    if (this.datedParameters[0]) {
      const value = this.datedParameters[0].parameter.physicalValue.value;
      return value + "%";
    }
  }

  getValue() {
    if (this.datedParameters[0]) {
      return this.datedParameters[0].parameter.physicalValue.value + "%";
    }
  }

  isObsolete(): boolean {
    if (this.obsolescence && this.datedParameters[0]) {
      if (this.orderBy == "onReceptionTime") {
        var pointDate = new Date(this.datedParameters[0].receptionTime);
      } else {
        var pointDate = new Date(this.datedParameters[0].onBoardTime);
      }
      const currentDate = new Date();

      const diffInSec = (currentDate.getTime() - pointDate.getTime()) / 1000;

      if (diffInSec > this.obsolescence) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getValueMin(range: any): number {
    return getMin(range);
  }

  getValueMax(range: any): number {
    return getMax(range);
  }

  getPlaceMin(range: any): string {
    return getMin(range) + "%";
  }

  getPlaceMax(range: any): string {
    return getMax(range) + "%";
  }

  getDistressColor(): string {
    return CriticityColors.distress;
  }

  getWarningColor(): string {
    return CriticityColors.warning;
  }

  getCriticalColor(): string {
    return CriticityColors.critical;
  }

  getWatchColor(): string {
    return CriticityColors.watch;
  }

  getSevereColor(): string {
    return CriticityColors.severe;
  }
}
