import { AfterViewChecked, Component, Input } from "@angular/core";
import { returnCriticalityList } from "app/model/criticalityList";
import {
  DatedParameter,
  Parameter,
  iNumericValueParameter,
  Criticality,
} from "app/model/spacepacket";
declare let $: any;

@Component({
  selector: "app-single-value",
  templateUrl: "./single-value.component.html",
  styleUrls: ["./single-value.component.css"],
})
export class SingleValueComponent implements AfterViewChecked {
  @Input() datedParameters: DatedParameter[];
  @Input() index: number;
  @Input() orderBy: string;
  @Input() obsolescence: number;
  @Input() widgetId: number;
  // Pour le widget de type jauge, l'unité de la valeur est toujours pourcentage
  @Input() isPercentage = false;

  ngAfterViewChecked() {
    $(
      "#InfoBulle-" +
        this.widgetId +
        "-" +
        this.getNameForTooltip(this.datedParameters) +
        "-" +
        this.index
    )
      .tooltip()
      .attr("data-original-title", this.getMessageBulle());
  }

  getMessageBulle(): string {
    let toReturn = "";
    toReturn += this.getDate(this.datedParameters[this.index]);
    toReturn += this.getObsoleteMessage(this.datedParameters[this.index]);
    toReturn += "<br>";
    toReturn += this.getValueFromParam(this.datedParameters[this.index]);
    toReturn += "<br>";
    toReturn += "valeur brute : ";
    toReturn += this.getRawValue(this.datedParameters[this.index]);
    toReturn += "<br>";
    toReturn += "valeur hexa : ";
    toReturn += this.getRawData(this.datedParameters[this.index]);
    toReturn += this.getCriticalityList(
      this.datedParameters[this.index].parameter
    );
    return toReturn;
  }

  getDate(dataParameter: DatedParameter): string {
    if (this.orderBy == "onReceptionTime") {
      return new Date(dataParameter.receptionTime).toLocaleString();
    } else {
      return new Date(dataParameter.onBoardTime).toLocaleString();
    }
  }

  getNameForTooltip(datedParameters: DatedParameter[]) {
    if (datedParameters != undefined) {
      return datedParameters[0].parameter.name;
    } else {
      return "";
    }
  }

  isObsolete(datedParameter: DatedParameter): boolean {
    if (this.obsolescence) {
      let pointDate: Date;
      if (this.orderBy == "onReceptionTime") {
        pointDate = new Date(datedParameter.receptionTime);
      } else {
        pointDate = new Date(datedParameter.onBoardTime);
      }
      const currentDate = new Date();

      const diffInSec = (currentDate.getTime() - pointDate.getTime()) / 1000;

      if (diffInSec > this.obsolescence) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getObsoleteMessage(datedParameter: DatedParameter): string {
    if (this.isObsolete(datedParameter)) {
      return "<br><b>La valeur est obsolète</b>";
    } else {
      return "";
    }
  }

  getValueFromParam(param: DatedParameter) {
    const value = param.parameter.physicalValue.value;
    return value + "" + this.getUnit(param);
  }

  getCriticalityList(parameter: Parameter): string {
    return returnCriticalityList(parameter);
  }

  private getUnit(param: DatedParameter): string {
    if (this.isPercentage) {
      return "%";
    } else {
      try {
        return param.parameter.unit.unit;
      } catch (e) {
        return "";
      }
    }
  }

  getColorNumber(i: number): string {
    if (this.getStatus(i) == Criticality.NOMINAL) {
      return "white";
    } else if (this.getStatus(i) == Criticality.WATCH) {
      return "value-watch watch";
    } else if (this.getStatus(i) == Criticality.WARNING) {
      return "value-watch warning";
    } else if (this.getStatus(i) == Criticality.DISTRESS) {
      return "value-watch distress";
    } else if (this.getStatus(i) == Criticality.CRITICAL) {
      return "value-watch critical";
    } else if (this.getStatus(i) == Criticality.SEVERE) {
      return "value-watch severe";
    }
  }

  getStatus(i: number): Criticality {
    return this.datedParameters[i].parameter.status;
  }

  getShortValueFromParam(param: DatedParameter) {
    let value = param.parameter.physicalValue.value;
    if ((value + "").length > 6) {
      if (iNumericValueParameter(param.parameter)) {
        value = value.toExponential(2);
      }
    }
    return value + " " + this.getUnit(param);
  }

  getRawValue(param: DatedParameter) {
    return param.parameter.rawValue.value;
  }

  getRawData(param: DatedParameter) {
    return param.parameter.rawData;
  }
}
