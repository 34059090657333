import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { Parameter } from "./model/spacepacket";
declare let $: any;

@Injectable()
export class TmParamFromXtceService {
  private parametersUrl = "api/tm-param-from-xtce";

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.parametersUrl = "http://localhost:15000/" + this.parametersUrl;
    }
    this.handleError = httpErrorHandler.createHandleError(
      "TmParamFromXtceService"
    );
  }

  getXtceParameters(
    packets: string[],
    params: string[]
  ): Observable<Parameter[]> {
    const baseURL = "?";

    let filterPacketsURL = "";
    // On ajoute tous les packets même si ils sont en doublon car le back-end en a besoin
    packets.forEach((value: string) => {
      filterPacketsURL += "&filter=" + value;
    });

    let requestedParameters = "";
    for (const param of params) {
      requestedParameters += "&requestedParameters=" + param;
    }

    // console.log("url : " + this.parametersUrl + baseURL + filterPacketsURL + requestedParameters)

    return this.http
      .get<Parameter[]>(
        this.parametersUrl + baseURL + filterPacketsURL + requestedParameters
      )
      .pipe(catchError(this.handleError("getXtceParameters", [])));
  }
}
