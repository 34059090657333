// ------------------ The model for colors

// Il ne faut pas que les variables soient statiques car il faut une instance de classe color par widget
export class Colors {
  public lineChartColors: Array<any> = [
    {
      // Ca rempli le dessous de la courbe
      fill: false,
      cubicInterpolationMode: "monotone",
      data: [],
      label: "",
      backgroundColor: "rgba(28,168,221,0.5)",
      borderColor: "rgba(28,168,221,1)",
      fillColor: "#3C342D",
      pointBackgroundColor: "rgba(28,168,221,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "rgba(28,168,221,1)",
      pointHoverBorderColor: "rgba(28,168,221,0.8)",
      colorForAxisUnits: "rgba(28,168,221,1)",
    },
    {
      fill: false,
      cubicInterpolationMode: "monotone",
      data: [],
      label: "",
      backgroundColor: "rgba(122,54,179,0.5)",
      borderColor: "rgba(122,54,179,1)",
      pointBackgroundColor: "rgba(122,54,179,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "rgba(122,54,179,0.8)",
      pointHoverBorderColor: "rgba(122,54,179,0.8)",
      colorForAxisUnits: "rgba(143,75,195,1)",
    },
    {
      fill: false,
      cubicInterpolationMode: "monotone",
      data: [],
      label: "",
      backgroundColor: "rgba(214, 255, 219,0.5)",
      borderColor: "rgba(214, 255, 219,1)",
      pointBackgroundColor: "rgba(214, 255, 219,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "rgba(214, 255, 219,0.8)",
      pointHoverBorderColor: "rgba(214, 255, 219,0.8)",
      colorForAxisUnits: "rgba(214, 255, 219,1)",
    },
    {
      fill: false,
      cubicInterpolationMode: "monotone",
      data: [],
      label: "",
      backgroundColor: "rgba(29,73,254,0.5)",
      borderColor: "rgba(29,73,254,1)",
      pointBackgroundColor: "rgba(29,73,254,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "rgba(29,73,254,1)",
      pointHoverBorderColor: "rgba(29,73,254,0.8)",
      colorForAxisUnits: "rgba(84,118,255,1)",
    },
    {
      fill: false,
      cubicInterpolationMode: "monotone",
      data: [],
      label: "",
      backgroundColor: "rgba(202,245,16,0.5)",
      borderColor: "rgba(202,245,16,1)",
      pointBackgroundColor: "rgba(202,245,16,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "rgba(202,245,16,1)",
      pointHoverBorderColor: "rgba(202,245,16,0.8)",
      colorForAxisUnits: "rgba(202,245,16,1)",
    },
    {
      fill: false,
      cubicInterpolationMode: "monotone",
      data: [],
      label: "",
      backgroundColor: "rgba(204,101,254,0.5)",
      borderColor: "rgba(204,101,254,1)",
      pointBackgroundColor: "rgba(204,101,254,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "rgba(204,101,254,1)",
      pointHoverBorderColor: "rgba(204,101,254,0.8)",
      colorForAxisUnits: "rgba(204,101,254,1)",
    },
    {
      fill: false,
      cubicInterpolationMode: "monotone",
      data: [],
      label: "",
      backgroundColor: "rgba(51,102,102,0.5)",
      borderColor: "rgba(51,102,102,1)",
      pointBackgroundColor: "rgba(51,102,102,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "rgba(51,102,102,1)",
      pointHoverBorderColor: "rgba(51,102,102,0.8)",
      colorForAxisUnits: "rgba(83,166,166,1)",
    },
    {
      fill: false,
      cubicInterpolationMode: "monotone",
      data: [],
      label: "",
      backgroundColor: "rgba(255,174,174,0.5)",
      borderColor: "rgba(255,174,174,1)",
      pointBackgroundColor: "rgba(255,174,174,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "rgba(255,174,174,1)",
      pointHoverBorderColor: "rgba(255,174,174,0.8)",
      colorForAxisUnits: "rgba(255,174,174,1)",
    },
    {
      fill: false,
      cubicInterpolationMode: "monotone",
      data: [],
      label: "",
      backgroundColor: "rgba(182,119,33,0.5)",
      borderColor: "rgba(182,119,33,1)",
      pointBackgroundColor: "rgba(182,119,33,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "rgba(182,119,33,1)",
      pointHoverBorderColor: "rgba(182,119,33,0.8)",
      colorForAxisUnits: "rgba(182,119,33,1)",
    },
    {
      fill: false,
      cubicInterpolationMode: "monotone",
      data: [],
      label: "",
      backgroundColor: "rgba(134,25,19,0.5)",
      borderColor: "rgba(134,25,19,1)",
      pointBackgroundColor: "rgba(134,25,19,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "rgba(134,25,19,1)",
      pointHoverBorderColor: "rgba(134,25,19,0.8)",
      colorForAxisUnits: "rgba(236,70,58,1)",
    },
  ];
}
