import { DatedParameter, Parameter } from "../../../model/spacepacket";

/**
 *
 * @param lineChartOptions
 */
export function addZoomConfiguration(lineChartOptions: any) {
  lineChartOptions.plugins.zoom = {
    zoom: {
      mode: "xy", // Allows zoom for x and y directions
      scaleMode: "xy", // Allows zoom for x and y axis

      wheel: {
        enabled: true, // Enables mouse wheel zoom
        modifierKey: "ctrl", // Disables mouse wheel zoom if Ctrl key is not pressed
      },

      drag: {
        enabled: true, // Enables drag-and-zoom
        modifierKey: "alt", // Disables drag-and-zoom if Alt key is not pressed
      },
    },

    pan: {
      enabled: true,
      mode: "xy",
    },
  };
}
