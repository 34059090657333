import { Component, Input, OnInit, NgZone } from "@angular/core";
import { Colors } from "app/model/colors";
import {
  DatedParameter,
  isEnum,
  Parameter,
  properWayForIntervalSubscription,
} from "app/model/spacepacket";
import { SynopticConfiguration } from "app/model/synoptic-configuration";
import { ParametersService } from "app/parameters.service";
import { Chart, ChartType, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-adapter-moment";
import "hammerjs";
import * as moment from "moment";
import { Subscription } from "rxjs";
import {
  ParameterWidget,
  Representation,
  SelectedParameter,
} from "../../model/synoptic";
import { TmParamFromXtceService } from "../../tm-param-from-xtce.service";
import {
  getPointBackgroundColor,
  getPointBorderWidth,
  getPointHoverRadius,
  getPointRadius,
  pointDefaultRadius,
} from "./utils/color-getter";
import { CriticityGetter } from "./utils/criticity-getter";
import { addBigDataConfiguration, filterData } from "./utils/filter-big-data";
import { addZoomConfiguration } from "./utils/get-zoom-plugin-conf";
import {
  checkThresholdsAreEqual,
  getThresholdsAnnotations,
} from "./utils/threshold-setter";
import { setUnitFromAxis, setUnitFromParameters } from "./utils/unit-setter";
declare let $: any;

Chart.register(...registerables);
Chart.register(zoomPlugin);
Chart.register(annotationPlugin);

/* -------

    On utilise ce composant selon un pattern MVC.
    Ce composant joue le rôle controlleur. Space-packet joue le rôle du modèle.
    Et la vue est dans la partie widget-view.

 * 
 */
@Component({
  selector: "div[app-display-widget]",
  templateUrl: "./display-widget.component.html",
  styleUrls: ["./display-widget.component.css"],
})
export class DisplayWidgetComponent implements OnInit {
  colors: Colors = new Colors();

  chartNeedUpdate = false;

  subscription: Subscription;

  synopticConfiguration: SynopticConfiguration;

  lastCheckRealTime: boolean;

  @Input()
  selectedWidget: ParameterWidget;

  @Input()
  widgetIndex: number;

  @Input()
  widgetsToUpdate: number[];

  // Ce param permet de changer quelques éléments d'affichage pour le zoom
  @Input()
  inZoom = false;

  // Permet de savoir qu'on a bien passé le premier ngAfterViewCHecked et qu'on peut draw le chart
  initView = false;

  // Permet de savoir que le chart a bien été draw et qu'on ne doit pas le draw à nouveau
  initChart = false;

  widgetChart;

  datedParameters: DatedParameter[][] = new Array<DatedParameter[]>();

  selectedParamsWithPackets: SelectedParameter[];

  selectedParams: string[] = [];
  selectedPackets: string[] = [];

  criticityGetter: CriticityGetter = new CriticityGetter();

  filterBigDataUsed = false;

  filterValue = "0";

  filterCheckbox = true;

  thresholdEnabled = false;

  thresholdsAnnotations: any[];

  private maxDataTotal = 300;

  // In ms
  private getParametersPeriode = 1500;

  private ngZone: NgZone;

  worsCriticityHeaderClass = "header-synoptic-item";
  worsCriticityBodyClass = "body-synoptic-item";

  previousCallRunning = false;

  constructor(
    private parametersService: ParametersService,
    private tmParamFromXtceService: TmParamFromXtceService,
    ngZone: NgZone
  ) {
    this.synopticConfiguration = this.parametersService.synopticConfiguration;
    this.lastCheckRealTime = true;
    this.ngZone = ngZone;
  }

  ngOnInit() {
    // Il n'y a que pour le commentaire qu'on ne veut pas get les paramètres
    if (this.selectedWidget.representation != Representation.Comment) {
      // On lance cette méthode en dans un thread séparé pour améliorer les performances
      this.ngZone.runOutsideAngular(() => {
        this.subscription = properWayForIntervalSubscription(
          this.getParametersPeriode,
          "getParameters",
          this
        );
      });
    }
    if (this.selectedWidget.parameters) {
      this.selectedParamsWithPackets = JSON.parse(
        this.selectedWidget.parameters
      );

      // On init la view ici avec les données de la BDS
      // On récupère les paramètres stockés en BDD dans
      // l'objet Widget puis on les met dans des tableaux pour faire la requette sur le parameters service
      const selectedParamsWithPackets: SelectedParameter[] =
        this.selectedParamsWithPackets;

      for (const selectedParam of selectedParamsWithPackets) {
        this.selectedParams.push(selectedParam.selectedParamName);
        this.selectedPackets.push(selectedParam.selectedPacketName);
      }
      this.tmParamFromXtceService
        .getXtceParameters(this.selectedPackets, this.selectedParams)
        .subscribe((x) => this.getXtceParameters(x));
    }
  }

  ngOnDestroy() {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    if (
      !this.initView &&
      (this.selectedWidget.representation == Representation.Plot ||
        this.selectedWidget.representation == Representation.Histogram)
    ) {
      this.initView = true;
    }
  }

  // ---------------------  Fonction pour charger initialement les valeurs à partir de la BDS comme les unités et les seuils

  private getXtceParameters(parameters: Parameter[]) {
    // Il faut remettre les parameters dans le même ordre que this.selectedParams
    const sortedParameters: Parameter[] = [];

    this.selectedParams.forEach((sortedParamName, sortedIndex) => {
      parameters.forEach((param, index) => {
        if (param.name == sortedParamName) {
          sortedParameters.push(param);
        }
      });
    });

    parameters = sortedParameters;

    // Init Unit
    // On va faire deux listes, une pour chaques axes et init Unit pour chaque axe.
    const leftParams: Parameter[] = [];
    const selectedParamsWithPacketLeft: SelectedParameter[] = [];
    const rightParams: Parameter[] = [];
    const selectedParamsWithPacketRight: SelectedParameter[] = [];
    parameters.forEach((param, index) => {
      if (this.selectedParamsWithPackets[index].selectedYAxis == 0) {
        leftParams.push(param);
        selectedParamsWithPacketLeft.push(
          this.selectedParamsWithPackets[index]
        );
      } else {
        rightParams.push(param);
        selectedParamsWithPacketRight.push(
          this.selectedParamsWithPackets[index]
        );
      }
    });

    setUnitFromAxis(
      leftParams,
      this.lineChartOptions,
      "y0",
      selectedParamsWithPacketLeft
    );
    setUnitFromAxis(
      rightParams,
      this.lineChartOptions,
      "y1",
      selectedParamsWithPacketRight
    );

    setUnitFromParameters(parameters, this.lineChartOptions);
    // Init les seuils
    if (
      parameters.length >= 1 &&
      checkThresholdsAreEqual(parameters) &&
      (leftParams.length == 0 || rightParams.length == 0)
    ) {
      this.thresholdEnabled = true;
      this.thresholdsAnnotations = getThresholdsAnnotations(
        parameters[0],
        this.selectedParamsWithPackets[0].selectedYAxis
      );
      this.lineChartOptions.plugins.annotation.annotations =
        this.thresholdsAnnotations;
    }
  }

  // ---------------------- Fonction privées pour la gestion des paramètres dans le widget -

  private getParameters() {
    this.previousCallRunning = true;
    this.parametersService
      .getParameters(
        this.selectedPackets,
        this.selectedParams,
        this.selectedWidget.nbValues
      )
      .subscribe((tmpackets) =>
        this.ngZone.runOutsideAngular(() => {
          this.setParameters(tmpackets);
          this.previousCallRunning = false;
        })
      );
  }

  private setParameters(newDatedParameters: DatedParameter[][]) {
    // On le fait quel que soit le type d'affichage
    // Add big data  conf
    if (
      this.selectedPackets.length * this.selectedWidget.nbValues >
      this.maxDataTotal / 2
    ) {
      addBigDataConfiguration(this.lineChartOptions);
      this.filterBigDataUsed = true;
    } else {
      this.filterBigDataUsed = false;
    }

    // On filtre d'abord les données pour la performance d'affichagem seulement si la checksbox est
    if (this.filterCheckbox) {
      this.filterValue = filterData(newDatedParameters, this.maxDataTotal);
    }

    // Attention on ne veut merge datedParameters et calcer sur ce merge le merge des datasets,
    // puisqu'en fait le merge des datasets ne peut pas se faire directement vu qu'il n'y a pas d'id
    if (
      this.initView &&
      (this.selectedWidget.representation == Representation.Plot ||
        this.selectedWidget.representation == Representation.Histogram)
    ) {
      if (!this.initChart) {
        this.drawChart();
        this.initChart = true;
      }

      this.merge(newDatedParameters);
      // On met à jour tout de suite les couleurs avant de faire le chart update donc toutes les secondes

      this.updateObsoleteColorsWithoutCharUpdate();

      // Cette condition permet de ne pas MAJ si rien n'a changé dans le back-end et de ne pas fermer
      // les tooltip dans ce cas
      if (this.chartNeedUpdate) {
        this.updateChart();
        this.chartNeedUpdate = false;
      }
    }
    // si on affiche un tableau numérique, on a pas besoin d'init un graphe
    if (
      this.selectedWidget.representation === Representation.Alpha ||
      this.selectedWidget.representation === Representation.Gauge ||
      this.selectedWidget.representation === Representation.Trigger
    ) {
      this.merge(newDatedParameters);
    }

    // On met à jour la class criticity class du header et du body
    this.worsCriticityHeaderClass =
      this.criticityGetter.getWorstCriticityHeaderClass(this.datedParameters);
    this.worsCriticityBodyClass =
      this.criticityGetter.getWorstCriticityBodyClass(this.datedParameters);
  }

  onChangeFilterCheckbox() {
    this.filterCheckbox = !this.filterCheckbox;
  }

  // ---------------------------------- Fonction pour la gestion du temps

  private getReferenceTime(parameter: DatedParameter): string {
    if (this.synopticConfiguration.orderBy == "onReceptionTime") {
      return parameter.receptionTime;
    } else {
      return parameter.onBoardTime;
    }
  }

  // ---------------------------------- Functions to merge  ------------------------------------

  merge(newDatedParametersArray: DatedParameter[][]) {
    // On parcours les tableaux car on peut avoir plusieurs paramètres
    newDatedParametersArray.forEach((newDatedParameters, j) => {
      // Un tableau correspond à une suite de paramètres qu'on merge
      if (this.datedParameters.length < j + 1) {
        // revient à ajouter un dataset entier, on le crée pour la première fois

        this.datedParameters[j] = newDatedParameters;
        // On donne j pour récupérer la bonne couleur

        if (
          this.selectedWidget.representation === Representation.Plot ||
          this.selectedWidget.representation === Representation.Histogram
        ) {
          this.createDataset(
            newDatedParameters,
            j,
            this.selectedParamsWithPackets[j].selectedParamName,
            this.selectedParamsWithPackets[j].selectedColor,
            this.selectedParamsWithPackets[j].selectedYAxis
          );
        }
        // A chaque splice on indique qu'il faut MAJ le chart avec un update,
        // ça permet de ne pas faire d'update si l n'y a rien à mettre à jour,
        // et de ne pas fermer automatiquement les tooltips dans ce cas
        this.chartNeedUpdate = true;
      } else {
        let size: number = this.datedParameters[j].length;
        newDatedParameters.forEach((newDatedParameter, i) => {
          // On fait un insert , on vérifie si deja la
          if (i < size) {
            const oldDatedParameter: DatedParameter =
              this.datedParameters[j][i];
            if (newDatedParameter.packetId != oldDatedParameter.packetId) {
              // Insertion de données à faire aussi dans le dataset si on a un graphe

              this.datedParameters[j].splice(i, 0, newDatedParameter);

              if (
                this.selectedWidget.representation === Representation.Plot ||
                this.selectedWidget.representation === Representation.Histogram
              ) {
                this.widgetChart.data.datasets[j].data.splice(
                  size - i,
                  0,
                  this.createPoint(newDatedParameter)
                );
                // A chaque splice on indique qu'il faut MAJ le chart avec un update,
                // ça permet de ne pas faire d'update si l n'y a rien à mettre à jour,
                // et de ne pas fermer automatiquement les tooltips dans ce cas
                this.chartNeedUpdate = true;
              }
              size++;
            }
          } else {
            // On ajoute à la fin

            // Insertion de données à faire aussi dans le dataset
            this.datedParameters[j].splice(i, 0, newDatedParameter);

            if (
              this.selectedWidget.representation === Representation.Plot ||
              this.selectedWidget.representation == Representation.Histogram
            ) {
              this.widgetChart.data.datasets[j].data.splice(
                0,
                0,
                this.createPoint(newDatedParameter)
              );
              // A chaque splice on indique qu'il faut MAJ le chart avec un update,
              // ça permet de ne pas faire d'update si l n'y a rien à mettre à jour,
              // et de ne pas fermer automatiquement les tooltips dans ce cas
              this.chartNeedUpdate = true;
            }
            size++;
          }
        });
        // On retire les éléments en trop
        if (size - newDatedParameters.length !== 0) {
          this.datedParameters[j].splice(
            newDatedParameters.length,
            size - newDatedParameters.length
          );
          if (
            this.selectedWidget.representation === Representation.Plot ||
            this.selectedWidget.representation == Representation.Histogram
          ) {
            this.widgetChart.data.datasets[j].data.splice(
              0,
              size - newDatedParameters.length
            );
            // A chaque splice on indique qu'il faut MAJ le chart avec un update,
            // ça permet de ne pas faire d'update si l n'y a rien à mettre à jour,
            // et de ne pas fermer automatiquement les tooltips dans ce cas
            this.chartNeedUpdate = true;
          }
        }
      }
    });
  }

  // ---------------------------------- Functions to update obsolete grey values & Set Cricity Point Colors  ------------------------------------

  // Cette fonction est appelée toutes les seconde si on a de l'obsolesence et aussi à chaque initialisation,
  // donc on suppose que toute la gestion des couleurs des points se fait ici
  private updateObsoleteColorsWithoutCharUpdate() {
    if (this.synopticConfiguration.realTime) {
      this.widgetChart.data.datasets.forEach((dataset, i) => {
        const idColor = this.selectedParamsWithPackets[i].selectedColor;
        const validPointBorderColor =
          this.colors.lineChartColors[idColor].pointBorderColor;
        const obsoletePointBackGroundColor = "#939393";
        const obsoletePointBorderColor = "#939393";
        for (let j = 0; j < dataset.data.length; j++) {
          if (this.isObsoletePoint(dataset.data[j])) {
            // On set les couleurs via la méthodes factorisée
            this.setColorsForPointInDataset(
              this.widgetChart.data,
              i,
              j,
              obsoletePointBackGroundColor,
              pointDefaultRadius(),
              obsoletePointBorderColor
            );
          } else {
            // On set les couleurs via la méthodes factorisée
            this.setColorsForPointInDataset(
              this.widgetChart.data,
              i,
              j,
              getPointBackgroundColor(
                this.datedParameters[i][dataset.data.length - j - 1],
                idColor
              ),
              getPointRadius(
                this.datedParameters[i][dataset.data.length - j - 1]
              ),
              validPointBorderColor
            );
          }
        }
      });
      this.chartNeedUpdate = true;
    } else if (
      this.lastCheckRealTime !=
        this.parametersService.synopticConfiguration.realTime ||
      (this.widgetChart.data.datasets.length > 0 &&
        this.oneOfDatasetsHasObsoleteColor(this.widgetChart.data.dataset))
    ) {
      // Dans ce cas on remet la couleur par défault àchaque foi pour écraser les anciennes couleurs
      // C'est le cas ou on passe de real time à deferred time
      this.widgetChart.data.datasets.forEach((dataset, i) => {
        const idColor = this.selectedParamsWithPackets[i].selectedColor;
        const validPointBorderColor =
          this.colors.lineChartColors[idColor].pointBorderColor;
        for (let j = 0; j < dataset.data.length; j++) {
          // On set les couleurs via la méthodes factorisée
          this.setColorsForPointInDataset(
            this.widgetChart.data,
            i,
            j,
            getPointBackgroundColor(
              this.datedParameters[i][dataset.data.length - j - 1],
              idColor
            ),
            getPointRadius(
              this.datedParameters[i][dataset.data.length - j - 1]
            ),
            validPointBorderColor
          );
        }
      });
      this.chartNeedUpdate = true;
    }
    this.lastCheckRealTime =
      this.parametersService.synopticConfiguration.realTime;
  }

  private oneOfDatasetsHasObsoleteColor(datasets: any[]): boolean {
    if (datasets != undefined) {
      datasets.forEach((dataset) => {
        if (
          dataset != undefined &&
          dataset.length > 0 &&
          dataset.pointBackgroundColor[0] == "#939393"
        ) {
          return true;
        }
      });
    }
    return false;
  }

  // Cette méthode permet de factoriser le set de la couleur d'un point sur un dataset i à la position j.
  private setColorsForPointInDataset(
    data: any,
    i: number,
    j: number,
    pointBackgroundColor: string,
    pointRadius: string,
    pointBorderColor: string
  ) {
    data.datasets[i].pointBackgroundColor[j] = pointBackgroundColor;
    data.datasets[i].pointRadius[j] = pointRadius;
    data.datasets[i].pointBorderColor[j] = pointBorderColor;
  }

  private isObsoletePoint(point): boolean {
    if (this.selectedWidget.obsolescence) {
      const pointDate = new Date(point.x);
      const currentDate = new Date();

      const diffInSec = (currentDate.getTime() - pointDate.getTime()) / 1000;

      if (diffInSec > this.selectedWidget.obsolescence) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // ---------------------------------- Functions to draw charts  ------------------------------------

  private drawChart() {
    let canvas;
    if (!this.inZoom) {
      canvas = <HTMLCanvasElement>(
        document.getElementById(
          "chart-" + this.selectedWidget.parameterWidgetId
        )
      );
    } else {
      canvas = <HTMLCanvasElement>(
        document.getElementById(
          "zoom-chart-" + this.selectedWidget.parameterWidgetId
        )
      );
    }

    // Add zoom plugin conf
    addZoomConfiguration(this.lineChartOptions);

    // Options are different depending of plot or histogram
    if (this.selectedWidget.representation == Representation.Plot) {
      var lineChartType: ChartType = "line";
      this.lineChartOptions.scales.x.bounds = "data";
      this.lineChartOptions.scales.x.ticks.source = "auto";
    } else if (this.selectedWidget.representation == Representation.Histogram) {
      var lineChartType: ChartType = "bar";
      this.lineChartOptions.scales.x.type = "timeseries";
      this.lineChartOptions.scales.x.bounds = "data";
      this.lineChartOptions.scales.x.offset = true; // Permet d'avoir toutes les valeurs dans le graphe.
      this.lineChartOptions.scales.x.ticks.source = "data";
      this.lineChartOptions.scales.y0.suggestedMin = 0;
      this.lineChartOptions.scales.y1.suggestedMin = 0;
    }

    const ctx = canvas.getContext("2d");
    this.widgetChart = new Chart(ctx, {
      type: lineChartType,
      data: {
        datasets: [],
      },
      options: this.lineChartOptions,
    });
  }

  // ------------------------------------- Functions for Zoom -----------------------------------------------------

  public resetZoom() {
    this.widgetChart.resetZoom();
  }

  // ---------------------------------- Functions to build chart data and labels ------------------------------------

  private createDataset(
    datedParameterList: DatedParameter[],
    j: number,
    selectedParamName: string,
    idColor: number,
    idYAxis: number
  ) {
    // Le j ne sert qu'a récupérer une couleur différente pour chaque nouvelle courbe
    // On a besoin de ça pour faire un clone de l'objet sinon on va travailler sur le même objet et modifier ses propriétés

    const dataset = Object.assign({}, this.colors.lineChartColors[idColor]);
    dataset.pointBackgroundColor = [];
    dataset.pointBorderColor = [];
    dataset.pointRadius = [];
    // Il doit y avoir au moins un paramètre dans la liste

    // TODO ici on doit récupérer le nom même sans la liste
    dataset.label = selectedParamName;
    if (idYAxis == 0) {
      dataset.label += " - LFT AX";
    } else if (idYAxis == 1) {
      dataset.label += " - RGT AX";
    }
    dataset.yAxisID = "y" + idYAxis;
    if (datedParameterList[0]) {
      datedParameterList.forEach((datedParameter, i) => {
        // On fait l'insert mais à l'envers avec unshift au lieu de push
        dataset.data.unshift(this.createPoint(datedParameter));
      });
    } else {
      dataset.data = [];
    }
    this.widgetChart.data.datasets[j] = dataset;
    this.widgetChart.data.datasets[j].pointBorderWidth = getPointBorderWidth();
    this.widgetChart.data.datasets[j].pointHoverRadius = getPointHoverRadius();
  }

  // Méthode pour créer un point à partir d'un dated parameter
  private createPoint(datedParameter: DatedParameter): any {
    let value: any;
    // Pour les enum on ne trace que les raws values pour les courbes
    if (isEnum(datedParameter.parameter.calibration)) {
      value = datedParameter.parameter.rawValue.value;
    } else {
      value = datedParameter.parameter.physicalValue.value;
    }

    const point = {
      x: this.getReferenceTime(datedParameter),
      y: value,
    };
    return point;
  }

  private updateChart() {
    // On lance cette méthode en dans un thread séparé pour améliorer les performances
    this.ngZone.runOutsideAngular(() => {
      this.widgetChart.update();
    });
    // this.widgetChart.update();
  }

  getIdColor(id: number): number {
    //reçois la couleur de la courbe selon l'index du widget
    return this.selectedParamsWithPackets[id].selectedColor;
  }

  // les deux fonctions servent à faire passer les lasts values en dessous du chart quandl a widget est petit
  getClassForLeftComponent(): string {
    if (this.selectedWidget.showLastValue) {
      if (
        $("#cardBlock-" + this.selectedWidget.parameterWidgetId).width() >= 600
      ) {
        $("#rightValue-" + this.selectedWidget.parameterWidgetId).width(
          "170px"
        );
        $("#leftChart-" + this.selectedWidget.parameterWidgetId).width(
          $("#cardBlock-" + this.selectedWidget.parameterWidgetId).width() -
            180 +
            "px"
        );
        return "";
      }
      if (
        $("#cardBlock-" + this.selectedWidget.parameterWidgetId).width() < 600
      ) {
        $("#rightValue-" + this.selectedWidget.parameterWidgetId).removeAttr(
          "width"
        );
        $("#leftChart-" + this.selectedWidget.parameterWidgetId).removeAttr(
          "width"
        );
        return "col-12";
      }
    }
    return "col-12";
  }

  getClassForLastValues(): string {
    if (
      $("#cardBlock-" + this.selectedWidget.parameterWidgetId).width() >= 600
    ) {
      return "";
    }
    if (
      $("#cardBlock-" + this.selectedWidget.parameterWidgetId).width() < 600
    ) {
      return "col-12";
    }
    return "col-12";
  }

  getClassForLeftJaugeComponent(): string {
    if (this.selectedWidget.showLastValue) {
      if (
        $("#cardBlock-" + this.selectedWidget.parameterWidgetId).width() >= 600
      ) {
        return "col-9";
      }
      if (
        $("#cardBlock-" + this.selectedWidget.parameterWidgetId).width() < 600
      ) {
        return "col-12";
      }
    }
    return "col-12";
  }

  getClassForJaugeLastValues(): string {
    if (
      $("#cardBlock-" + this.selectedWidget.parameterWidgetId).width() >= 600
    ) {
      return "col-3";
    }
    if (
      $("#cardBlock-" + this.selectedWidget.parameterWidgetId).width() < 600
    ) {
      return "col-12";
    }
    return "col-12";
  }

  setThresholds() {
    if (this.widgetChart.options.plugins.annotation.annotations.length == 0) {
      this.widgetChart.options.plugins.annotation.annotations =
        this.thresholdsAnnotations;
    } else {
      this.widgetChart.options.plugins.annotation.annotations = [];
    }
  }

  public lineChartOptions: any = {
    transitions: {
      active: {
        animation: {
          duration: 0, // duration of animations when hovering an item
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: "nearest",
        intersect: true,
        callbacks: {
          title: function (context) {
            const rawDate = context[0].raw.x;
            return moment.utc(rawDate).format("llll");
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#cfd2da",
        },
      },
      annotation: {
        annotations: [],
      },
    },
    scales: {
      y0: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          color: "#cfd2da",
        },
        ticks: {
          color: "#cfd2da",
        },
        grid: {
          color: "#434857",
        },
      },
      y1: {
        type: "linear",
        position: "right",
        title: {
          display: true,
          color: "#cfd2da",
        },
        ticks: {
          color: "#cfd2da",
        },
        grid: {
          color: "#434857",
        },
      },
      x: {
        type: "time",
        time: {
          displayFormats: {
            millisecond: "hh:mm:ss",
            second: "hh:mm:ss",
            minute: "hh:mm:ss",
            hour: "hh:mm:ss",
          },
        },
        display: true,
        title: {
          display: false,
          text: "Date",
        },
        ticks: {
          color: "#cfd2da",
          callback: function (value, index, ticks) {
            if (!ticks[index]) {
              return;
            }
            return moment.utc(value).format("LT");
          },
        },
        grid: {
          color: "#434857",
        },
      },
    },
  };
}
