// ------------------ The model

export class SynopticConfiguration {
  dateStart: string;
  timeStart: string;
  dateEnd: string;
  timeEnd: string;
  realTime: boolean;
  orderBy: string;
}
