import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import {
  Procedure,
  ProcedureConfiguration,
  ProcedureToCancel,
} from "./model/procedures";
import { formatDate } from "./model/spacepacket";

declare let $: any;

@Injectable()
export class ProcedureHistoryService {
  private procedureHistoryUrl = "api/procedure-history";
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  private handleError: HandleError;

  procedureConfiguration: ProcedureConfiguration = new ProcedureConfiguration();

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    if ($(location).attr("href").substring(0, 21) == "http://localhost:4200") {
      this.procedureHistoryUrl =
        "http://localhost:15000/" + this.procedureHistoryUrl;
    }
    this.handleError = httpErrorHandler.createHandleError(
      "ProcedureHistoryService"
    );
  }

  getProcedureHistory(): Observable<Array<Procedure>> {
    let filterDateUrl = "";
    if (this.procedureConfiguration.realTime) {
      filterDateUrl = "?start=realTime";
    } else {
      filterDateUrl = formatDate(
        this.procedureConfiguration.dateStart,
        this.procedureConfiguration.timeStart,
        this.procedureConfiguration.dateEnd,
        this.procedureConfiguration.timeEnd
      );
    }

    let filterProcsUrl = "";
    const nbMax = "&maxLimit=" + this.procedureConfiguration.nbProcsMax;
    this.procedureConfiguration.selectList.forEach(
      (value: boolean, key: string) => {
        if (value) {
          filterProcsUrl += "&filters=" + key;
        }
      }
    );
    return this.http
      .get<Array<Procedure>>(
        this.procedureHistoryUrl + filterDateUrl + filterProcsUrl + nbMax,
        this.httpOptions
      )
      .pipe(catchError(this.handleError("getProcedureHistory", [])));
  }

  putProcedureCancel(procedureToCancel: ProcedureToCancel) {
    return this.http
      .put(this.procedureHistoryUrl, procedureToCancel, this.httpOptions)
      .pipe(catchError(this.handleError("cancelProcedure", [])));
  }

  toggleProcessing(state: string) {
    return this.http
      .post(this.procedureHistoryUrl, state, this.httpOptions)
      .pipe(catchError(this.handleError("toggleProcessing", [])));
  }
}
