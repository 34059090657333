import {
  PacketContainer,
  isPacketContainer,
  isParameter,
  AbstractContainer,
  Criticality,
} from "../../model/spacepacket";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { PopoverTourService } from "app/popover-tour.service";

@Component({
  selector: "app-tm-packets-explorer",
  templateUrl: "./tm-packets-explorer.component.html",
  styleUrls: ["./tm-packets-explorer.component.css"],
})
export class TmPacketsExplorerComponent {
  @Input()
  packetContainer: PacketContainer;

  @Input()
  selectedContainer: AbstractContainer;

  @Output()
  selectedContainerChange: EventEmitter<AbstractContainer> = new EventEmitter();

  popoverTourService: PopoverTourService = PopoverTourService.Instance;

  selectedContainerCall(selectedContainer: AbstractContainer) {
    this.selectedContainer = selectedContainer;
    this.selectedContainerChange.emit(selectedContainer);
  }

  onSelect(container: AbstractContainer): void {
    this.selectedContainer = container;
    this.selectedContainerChange.emit(container);
  }

  isPacketContainer(abstractContainer: AbstractContainer): boolean {
    return isPacketContainer(abstractContainer);
  }

  isParameter(abstractContainer: AbstractContainer): boolean {
    return isParameter(abstractContainer);
  }

  returnCriticalityClass(status: Criticality): string {
    let statusToReturn;
    switch (status) {
      case Criticality.NOMINAL:
        statusToReturn = "nominal-grey";
        break;
      case Criticality.WATCH:
        statusToReturn = "watch";
        break;
      case Criticality.WARNING:
        statusToReturn = "warning";
        break;
      case Criticality.DISTRESS:
        statusToReturn = "distress";
        break;
      case Criticality.CRITICAL:
        statusToReturn = "critical";
        break;
      case Criticality.SEVERE:
        statusToReturn = "severe";
        break;
      default:
        statusToReturn = "";
    }
    return statusToReturn;
  }
}
