import { Criticality, DatedParameter } from "../../../model/spacepacket";

export class CriticityGetter {
  public getWorstCriticityHeaderClass(
    datedParameters: DatedParameter[][]
  ): string {
    const status = this.getWorstPointCriticality(datedParameters);
    let statusToReturn;
    switch (status) {
      case Criticality.NOMINAL:
        statusToReturn = "header-synoptic-item";
        break;
      case Criticality.WATCH:
        statusToReturn = "header-synoptic-item-watch";
        break;
      case Criticality.WARNING:
        statusToReturn = "header-synoptic-item-warning";
        break;
      case Criticality.DISTRESS:
        statusToReturn = "header-synoptic-item-distress";
        break;
      case Criticality.CRITICAL:
        statusToReturn = "header-synoptic-item-critical";
        break;
      case Criticality.SEVERE:
        statusToReturn = "header-synoptic-item-severe";
        break;
      default:
        statusToReturn = "header-synoptic-item";
        break;
    }
    return statusToReturn;
  }

  public getWorstCriticityBodyClass(
    datedParameters: DatedParameter[][]
  ): string {
    const status = this.getWorstPointCriticality(datedParameters);
    let statusToReturn;
    switch (status) {
      case Criticality.NOMINAL:
        statusToReturn = "body-synoptic-item";
        break;
      case Criticality.WATCH:
        statusToReturn = "body-synoptic-item-watch";
        break;
      case Criticality.WARNING:
        statusToReturn = "body-synoptic-item-warning";
        break;
      case Criticality.DISTRESS:
        statusToReturn = "body-synoptic-item-distress";
        break;
      case Criticality.CRITICAL:
        statusToReturn = "body-synoptic-item-critical";
        break;
      case Criticality.SEVERE:
        statusToReturn = "body-synoptic-item-severe";
        break;
      default:
        statusToReturn = "body-synoptic-item";
    }
    return statusToReturn;
  }

  public getWorstPointCriticality(
    datedParameters: DatedParameter[][]
  ): Criticality {
    // TODO prendre en compte ou pas l'obscolence en temps réel
    let toReturn = Criticality.NOMINAL;
    datedParameters.forEach((oneArrayDatedParam) => {
      oneArrayDatedParam.forEach((datedParam) => {
        if (datedParam.parameter.status > toReturn) {
          toReturn = datedParam.parameter.status;
        }
      });
    });
    return toReturn;
  }
}
