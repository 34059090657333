import {
  PacketContainer,
  Parameter,
  isPacketContainer,
  isParameter,
  AbstractContainer,
  isNumericMonitoring,
  isEnumMonitoring,
  Monitoring,
  Criticality,
} from "../../model/spacepacket";
import { returnCriticalityList } from "../../model/criticalityList";
import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { PopoverTourService } from "app/popover-tour.service";
declare let $: any;

@Component({
  selector: "app-parameters-details",
  templateUrl: "./parameters-details.component.html",
  styleUrls: ["./parameters-details.component.css"],
})
export class ParametersDetailsComponent implements OnInit, OnChanges {
  @Input()
  selectedContainer: AbstractContainer;

  parameterList: Parameter[];
  name: boolean;
  physicalValue: boolean;
  rawValue: boolean;
  rawData: boolean;
  BinaryData: boolean;
  length: boolean;
  offset: boolean;
  ptc: boolean;
  pfc: boolean;
  fixedLength: boolean;
  fixedValue: boolean;
  fixedRepeat: boolean;
  dynamicLengthParamRef: boolean;
  dynamicRepeat: boolean;

  parameterSearch: string;
  edited: boolean;
  parametersToDisplay: Parameter[];

  popoverTourService: PopoverTourService = PopoverTourService.Instance;

  ngOnInit() {
    this.setVisibleColumn();

    $(".dropdown-menu p").on("click", function (event) {
      event.stopPropagation();
    });

    $("#parameter-list").tooltip({
      selector: '[data-toggle="tooltip"]',
    });

    $('[data-toggle="tooltip"]').tooltip();

    this.edited = false;
  }

  ngOnChanges() {
    this.parameterList = new Array<Parameter>();
    this.parametersToDisplay = Array<Parameter>();
    // On doit mettre à plat les paramètres avant de les afficher
    if (isPacketContainer(this.selectedContainer)) {
      this.recursiveGetParameters(this.selectedContainer);
    } else if (isParameter(this.selectedContainer)) {
      this.parameterList.push(<Parameter>this.selectedContainer);
      this.parametersToDisplay.push(<Parameter>this.selectedContainer);
    }
  }

  recursiveGetParameters(selectedContainer: AbstractContainer) {
    if ((<PacketContainer>selectedContainer).subContainers != null) {
      for (const packetContainer of (<PacketContainer>selectedContainer)
        .subContainers) {
        if (isPacketContainer(packetContainer)) {
          this.recursiveGetParameters(packetContainer);
        } else if (isParameter(packetContainer)) {
          this.parameterList.push(<Parameter>packetContainer);
          this.parametersToDisplay.push(<Parameter>packetContainer);
        }
      }
    }
  }

  isPacketContainer(abstractContainer: AbstractContainer): boolean {
    return isPacketContainer(abstractContainer);
  }

  isParameter(abstractContainer: AbstractContainer): boolean {
    return isParameter(abstractContainer);
  }

  //Initialise les colonnes qui doivent être visible dans la fenêtre "Paramètres"
  setVisibleColumn() {
    this.name = true;
    this.physicalValue = true;
    this.rawValue = true;
    this.BinaryData = false;
    this.length = true;
    this.offset = true;
    this.ptc = true;
    this.pfc = true;
    this.fixedLength = false;
    this.fixedValue = false;
    this.dynamicLengthParamRef = false;
  }

  //Formate la date au format dd/mm/aaaa hh:mm:ss.nnn (Format en entré aaaa-mm-ddThh:mm:ss.nnnZ ou aaaa-mm-ddThh:mmZ si nano = 000)
  formatDate(date: string): string {
    if (date) {
      const year = date.slice(0, 4);
      const month = date.slice(5, 7);
      const day = date.slice(8, 10);
      let time = date.slice(11, 23).replace("Z", "");
      if (time.length == 8) {
        time += ".000";
      }
      return day + "/" + month + "/" + year + " " + time;
    } else {
      return "";
    }
  }

  //Permet de retourner la classe CSS en fonction du "status" en entré
  returnCriticalityClass(status: Criticality): string {
    let statusToReturn;
    switch (status) {
      case Criticality.NOMINAL:
        statusToReturn = "nominal";
        break;
      case Criticality.WATCH:
        statusToReturn = "watch";
        break;
      case Criticality.WARNING:
        statusToReturn = "warning";
        break;
      case Criticality.DISTRESS:
        statusToReturn = "distress";
        break;
      case Criticality.CRITICAL:
        statusToReturn = "critical";
        break;
      case Criticality.SEVERE:
        statusToReturn = "severe";
        break;
      default:
        statusToReturn = "";
    }
    return statusToReturn;
  }

  isNumericMonitoring(monitoring: Monitoring): boolean {
    return isNumericMonitoring(monitoring);
  }

  isEnumMonitoring(monitoring: Monitoring): boolean {
    return isEnumMonitoring(monitoring);
  }

  //Click sur le bouton "clear"
  clearSearch() {
    this.parameterSearch = "";
    this.onChangeSearchParameter();
  }

  getCriticalityList(parameter: Parameter): string {
    return returnCriticalityList(parameter);
  }

  //Met a jour la liste de paramètres TM à afficher

  onChangeSearchParameter() {
    this.parametersToDisplay = [];
    for (const parameter of this.parameterList) {
      if (
        parameter.name
          .toUpperCase()
          .indexOf(this.parameterSearch.toUpperCase()) > -1
      ) {
        this.parametersToDisplay.push(parameter);
      }
    }

    if (this.parameterSearch.length > 0) {
      this.edited = true;
    } else {
      this.edited = false;
    }
  }

  parameterStatusIsNominal(parameter: Parameter): boolean {
    return parameter.status == Criticality.NOMINAL;
  }

  parameterStatusIsWatch(parameter: Parameter): boolean {
    return parameter.status == Criticality.WATCH;
  }

  parameterStatusIsWarning(parameter: Parameter): boolean {
    return parameter.status == Criticality.WARNING;
  }

  parameterStatusIsDistress(parameter: Parameter): boolean {
    return parameter.status == Criticality.DISTRESS;
  }

  parameterStatusIsCritical(parameter: Parameter): boolean {
    return parameter.status == Criticality.CRITICAL;
  }

  parameterStatusIsSevere(parameter: Parameter): boolean {
    return parameter.status == Criticality.SEVERE;
  }
}
