import { Directive, forwardRef, Input } from "@angular/core";
import {
  NG_VALIDATORS,
  FormControl,
  ValidatorFn,
  AbstractControl,
  Validators,
} from "@angular/forms";
import {
  Calibration,
  PolynomialCalibration,
  isPolynomialCalibration,
} from "../model/spacepacket";

/**
 *
 * @param calibration
 */
export function validateIsFloatFactory(calibration: Calibration): ValidatorFn {
  return (c: FormControl) => {
    let valueToCompare = 0;
    if (isPolynomialCalibration(calibration)) {
      for (const coefficient of (<PolynomialCalibration>calibration)
        .coefficients) {
        valueToCompare += Math.pow(
          coefficient.factor * c.value,
          coefficient.exponent
        );
      }
    } else {
      valueToCompare = c.value;
    }
    return Number.isInteger(valueToCompare * 1000000000)
      ? null
      : {
          validateIsFloat: {
            valid: false,
          },
        };
  };
}
@Directive({
  selector: "[validateIsFloat]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: FloatValidatorDirective,
      multi: true,
    },
  ],
})
export class FloatValidatorDirective {
  private valFn = Validators.nullValidator;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.valFn(control);
  }
}
